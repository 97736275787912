import PlayerDetailsTypes from "./PlayerDetails.Types"; 
import axios from 'axios'

const { REACT_APP_NEW_BASE_URL , REACT_APP_NEW_GET_PLAYERS } = process.env


const getPlayerDetailsStart = () => ({
    type:PlayerDetailsTypes.GET_PLAYER_DETAILS_START
})

const getPlayerDetailsSuccess = (info) => ({
    type:PlayerDetailsTypes.GET_PLAYER_DETAILS_SUCCESS,
    payload:info
})

const setCurrentTeam = (info)=> ({
  type:PlayerDetailsTypes.SET_CURRENT_PLAYER_TEAM,
  payload:info
})

const setCurrentTournament = (info) =>({
  type:PlayerDetailsTypes.SET_CURRENT_PLAYER_TOURNAMENT,
  payload:info
})

const getPlayerDetailsFailed = (info) => ({
    type:PlayerDetailsTypes.GET_PLAYER_DETAILS_FAILED,
    payload:info
})

const getPlayerDetails = (player_id,lang) => (
    dispatch => {
        dispatch(getPlayerDetailsStart())
        axios({
              method: "get",
              url: `${REACT_APP_NEW_BASE_URL}${REACT_APP_NEW_GET_PLAYERS}/${player_id}`,
              headers: {
                "Content-Type": "application/json",
                "Accept-Language":`${lang}`
              },
            }).then(resp => {   
              if (resp.data.status===200)
              { 
                dispatch(getPlayerDetailsSuccess(resp.data.data))
              }
              else {
                dispatch(getPlayerDetailsFailed(resp.data.message))
              }}
              )
              .catch(error => dispatch(getPlayerDetailsFailed(error)))
     
      }
)

export { getPlayerDetails , setCurrentTeam , setCurrentTournament }
