import MatchActionTypes from "./Types.Match";
import axios from "axios";

const {
  REACT_APP_NEW_BASE_URL,
  REACT_APP_GET_MATCH_BY_ID,
  REACT_APP_API_KEY,
  REACT_APP_GET_MATCH_LINEUP,
  REACT_APP_GET_MATCH_EVENTS,
  REACT_APP_GET_MATCH_STATISTICS,
} = process.env;

const getMatchInfoStart = () => ({
  type: MatchActionTypes.GET_MATCH_INFO_START,
});

const getMatchInfoSuccess = (info) => ({
  type: MatchActionTypes.GET_MATCH_INFO_SUCCESS,
  payload: info,
});

const getMatchInfoFailure = (error) => ({
  type: MatchActionTypes.GET_MATCH_INFO_FAILURE,
  payload: error,
});

const getMatchEventsStart = () => ({
  type: MatchActionTypes.GET_MATCH_EVENTS_START,
});

const getMatchEventsSuccess = (info) => ({
  type: MatchActionTypes.GET_MATCH_EVENTS_SUCCESS,
  payload: info,
});

const getMatchEventsFailure = (error) => ({
  type: MatchActionTypes.GET_MATCH_EVENTS_FAILURE,
  payload: error,
});

const getMatchLineUpStart = () => ({
  type: MatchActionTypes.GET_MATCH_LINEUP_START,
});

const getMatchLineUpSuccess = (info) => ({
  type: MatchActionTypes.GET_MATCH_LINEUP_SUCCESS,
  payload: info,
});

const getMatchLineUpFailure = (error) => ({
  type: MatchActionTypes.GET_MATCH_LINEUP_FAILURE,
  payload: error,
});

const getMatchLiveStartEventSuccess = () => ({
  type: MatchActionTypes.GET_MATCH_LIVE_START_EVENT_SUCCESS,
});

const getMatchLiveRoundEventSuccess = (info) => ({
  type: MatchActionTypes.GET_MATCH_LIVE_ROUND_EVENT_SUCCESS,
  payload: info,
});

const getMatchLiveGoalEventSuccess = (info) => ({
  type: MatchActionTypes.GET_MATCH_LIVE_GOAL_EVENT_SUCCESS,
  payload: info,
});

const getMatchOtherEventSuccess = (info) => ({
  type: MatchActionTypes.GET_MATCH_LIVE_OTHER_EVENT_SUCCESS,
  payload: info,
});

const DeleteMatchLiveEventSuccess = (info) => ({
  type: MatchActionTypes.DELETE_MATCH_LIVE_EVENT_SUCCESS,
  payload: info,
});

const getMatchStatisticsStart = () => ({
  type: MatchActionTypes.GET_MATCH_STATISTICS_START,
});

const getMatchStatisticsSuccess = (info) => ({
  type: MatchActionTypes.GET_MATCH_STATISTICS_SUCCESS,
  payload: info,
});

const getMatchStatisticsFailure = (error) => ({
  type: MatchActionTypes.GET_MATCH_STATISTICS_FAILURE,
  payload: error,
});

const getMatchInfo = (match_id) => (dispatch) => {
  dispatch(getMatchInfoStart());
  axios({
    method: "get",
    url: `${REACT_APP_NEW_BASE_URL}${REACT_APP_GET_MATCH_BY_ID}?id=${match_id}`,
    // params: { match_id:42312 },
    headers: {
      "Content-Type": "application/json",
      "Accept-Language": "ar",
    },
  })
    .then((resp) => {
      if (resp.data.status === 200) {
        dispatch(getMatchInfoSuccess(resp.data.data));
      } else {
        dispatch(getMatchInfoFailure(resp.data.message));
      }
    })
    .catch((error) => dispatch(getMatchInfoFailure(error)));
};

const getMatchEvents = (match_id) => (dispatch) => {
  dispatch(getMatchEventsStart());
  axios({
    method: "get",
    url: `${REACT_APP_NEW_BASE_URL}${REACT_APP_GET_MATCH_EVENTS}`,
    params: { match_id },
    headers: {
      "Content-Type": "application/json",
      "Accept-Language": "ar",
    },
  })
    .then((resp) => {
      if (resp.data.status === 200) {
        dispatch(getMatchEventsSuccess(resp.data.data));
        console.log(resp.data.data);
      } else {
        dispatch(getMatchEventsFailure(resp.data.message));
        console.log(resp.data.message);
      }
    })
    .catch((error) => dispatch(getMatchEventsFailure(error)));
};

const getMatchLineUp = (match_id) => (dispatch) => {
  dispatch(getMatchLineUpStart());
  axios({
    method: "get",
    url: `${REACT_APP_NEW_BASE_URL}${REACT_APP_GET_MATCH_LINEUP}${match_id}`,
    // params: { match_id:42312 },
    headers: {
      "Content-Type": "application/json",
      "Accept-Language": "ar",
    },
  })
    .then((resp) => {
      if (resp.data.status === 200) {
        dispatch(getMatchLineUpSuccess(resp.data.data));
        console.log(resp.data.results);
      } else {
        dispatch(getMatchLineUpFailure(resp.data.message));
        console.log(resp.data.message);
      }
    })
    .catch((error) => dispatch(getMatchLineUpFailure(error)));
};

const getMatchStatistics = (id) => (dispatch) => {
  dispatch(getMatchStatisticsStart());
  axios({
    method: "get",
    url: `${REACT_APP_NEW_BASE_URL}${REACT_APP_GET_MATCH_STATISTICS}`,
    params: { id },
    headers: {
      "Content-Type": "application/json",
      "Accept-Language": "ar",
    },
  })
    .then((resp) => {
      if (resp.data.status === 200) {
        dispatch(getMatchStatisticsSuccess(resp.data.data));
        console.log(resp.data.results);
      } else {
        dispatch(getMatchStatisticsFailure(resp.data.message));
        console.log(resp.data.message);
      }
    })
    .catch((error) => dispatch(getMatchStatisticsFailure(error)));
};

export {
  getMatchInfo,
  getMatchEvents,
  getMatchLineUp,
  getMatchLiveRoundEventSuccess,
  getMatchLiveGoalEventSuccess,
  getMatchOtherEventSuccess,
  DeleteMatchLiveEventSuccess,
  getMatchLiveStartEventSuccess,
  getMatchStatistics,
};
