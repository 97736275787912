import React from "react";

const SportsSectionHeader = ({ title }) => {
  return (
    <h3 className="text-light fs-xxm my-3">
      <strong>{title}</strong>
    </h3>
  );
};

export default SportsSectionHeader;
