import MatchesTypes from "./Matches.Types"; 
import axios from 'axios'

const { REACT_APP_NEW_BASE_URL , REACT_APP_NEW_GET_MATCHES   } = process.env

const getMatchesStart = () => ({
    type:MatchesTypes.GET_MATCHES_START
})

const getMatchesSuccess = (info) => ({
    type:MatchesTypes.GET_MATCHES_SUCCESS,
    payload:info
})

const getMatchesFailed = (info) => ({
    type:MatchesTypes.GET_MATCHES_FAILED,
    payload:info
})


const getMatches = (sport_id , start_date , lang) => (
    dispatch => {
        dispatch(getMatchesStart())
        axios({
              method: "get",
              url: `${REACT_APP_NEW_BASE_URL}${REACT_APP_NEW_GET_MATCHES}`,
               params: {  sport_id, start_date },
              headers: {
                "Content-Type": "application/json",
                "Accept-Language":`${lang}`
              },
            }).then(resp => {   
              if (resp.data.status===200)
              { 
                dispatch(getMatchesSuccess(resp.data.data))
              }
              else {
                dispatch(getMatchesFailed(resp.data.message))
              }}
              )
              .catch(error => dispatch(getMatchesFailed(error)))
     
      }
)

export { getMatches }