import React, { useEffect, useState } from "react";
import { Container, Nav, Tab, TabContent } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import pepsiAdd from "../../assets/adds/pepsi2 add.jpg";
import ErrorPage from "../../components/ErrorPage/ErrorPage";
import SeasonsDropdown from "../../components/SeasonsDropdown/SeasonsDropdown";
import TournamentHistory from "../../components/TournamentHistory/TournamentHistory";
import TournamentMatches from "../../components/TournamentMatches/TournamentMatches";
import TournamentOverview from "../../components/TournamentOverview/TournamentOverview";
import TourStandingDetails from "../../components/TournamentStanding/TourStandingDetails";
import TourStatsDetails from "../../components/TournamentStatistics/TourStatsDetails";
import {
  getTournamentDetails,
  setTournamentActiveTab,
} from "../../redux/TournamentDetails/TournamentDetails.Actions";
import Matches from "../Matches/Matches";
import HeaderInfoComponentLoader from "./HeaderInfoComponentLoader";
import "./tournament.css";

const Tournament = ({
  TournamentDetails,
  TournamentDetailsFailed,
  activeSeason,
  isFetchingTournamentDetails,
  getTournamentDetails,
  setTournamentActiveTab,
  activeTab,
  currentLanguageCode,
}) => {
  const { t } = useTranslation();

  const id = useParams().id;
  const params = useParams();
  const tab = useParams().tab || "overview";

  const history = useHistory();
  const handelTabClick = (tab) => {
    history.push(`/tournament/${id}/${tab}`);
  };

  useEffect(() => {
    getTournamentDetails(1, id, currentLanguageCode);
  }, [id, currentLanguageCode]);

  return (
    <div>
      {TournamentDetailsFailed !== null ? (
        <ErrorPage error={t("Error.TournamentError")} />
      ) : isFetchingTournamentDetails ? (
        <HeaderInfoComponentLoader />
      ) : (
        <div className="Tournament-details-header-container">
          <div className="Tournament-details-header"></div>
          <div className="Tournament-details-header-desc text-center">
            <img
              src={process.env.REACT_APP_NEW_MEDIA_URL + TournamentDetails.logo}
            />
            <div className="text-light text-center mt-3 fs-lg">
              <strong>{TournamentDetails.title}</strong>
            </div>
          </div>
        </div>
      )}
      <div className="container"></div>
      <Tab.Container
        id="left-tabs-example"
        defaultActiveKey={activeTab}
        onSelect={(k) => setTournamentActiveTab(k)}
        activeKey={tab || activeTab}
        mountOnEnter={true}
        unmountOnExit={true}
      >
        <Nav variant="tabs" className="mb-3  flex-nowrap fs-sm">
          <div className="d-flex justify-content-center mx-auto">
            <Nav.Item>
              <Nav.Link
                eventKey="overview"
                onClick={() => handelTabClick("overview")}
              >
                {t(`TournamentDetails.Overview`)}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="matches"
                onClick={() => handelTabClick("matches")}
              >
                {t(`TournamentDetails.Matches`)}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="standing"
                onClick={() => handelTabClick("standing")}
              >
                {t(`TournamentDetails.Table`)}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="stats"
                onClick={() => handelTabClick("stats")}
              >
                {t(`TournamentDetails.Stats`)}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="history"
                onClick={() => handelTabClick("history")}
              >
                {t(`TournamentDetails.History`)}
              </Nav.Link>
            </Nav.Item>
          </div>
        </Nav>
        <Nav className="container mt-3">
          <SeasonsDropdown />
        </Nav>

        <Tab.Content>
          <Tab.Pane eventKey="overview">
            <TournamentOverview
              toLocalTab={handelTabClick}
              season_id={activeSeason.id}
            />
          </Tab.Pane>
          <Tab.Pane eventKey="matches">
            <TournamentMatches sport_id={1} tournament_id={id} />
          </Tab.Pane>
          <Tab.Pane eventKey="standing">
            <TourStandingDetails sport_id={1} season_id={activeSeason.id} />
          </Tab.Pane>
          <Tab.Pane eventKey="stats">
            <TourStatsDetails sport_id={1} season_id={activeSeason.id} />
          </Tab.Pane>
          <Tab.Pane eventKey="history">
            <TournamentHistory />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getTournamentDetails: (sport_id, tournament_id, currentLanguageCode) =>
    dispatch(
      getTournamentDetails(sport_id, tournament_id, currentLanguageCode)
    ),
  setTournamentActiveTab: (tabName) =>
    dispatch(setTournamentActiveTab(tabName)),
});

const mapStateToProps = (state) => ({
  TournamentDetailsFailed: state.tournamentDetails.TournamentDetailsFailed,
  TournamentDetails: state.tournamentDetails.TournamentDetails,
  isFetchingTournamentDetails:
    state.tournamentDetails.isFetchingTournamentDetails,
  activeSeason: state.seasons.activeSeason,
  activeTab: state.tournamentDetails.activeTab,
  currentLanguageCode: state.language.currentLanguageCode,
});

export default connect(mapStateToProps, mapDispatchToProps)(Tournament);
