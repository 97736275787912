import getTournamentDetailsTypes from "./TournamentDetails.Types"; 
import axios from 'axios'

const { REACT_APP_NEW_BASE_URL , REACT_APP_NEW_GET_TOURNAMENTS } = process.env


const getTournamentDetailsStart = () => ({
    type:getTournamentDetailsTypes.GET_TOURNAMENT_DETAILS_START
})

const getTournamentDetailsSuccess = (info) => ({
    type:getTournamentDetailsTypes.GET_TOURNAMENT_DETAILS_SUCCESS,
    payload:info
})

const getTournamentDetailsFailed = (info) => ({
    type:getTournamentDetailsTypes.GET_TOURNAMENT_DETAILS_FAILED,
    payload:info
})

const setTournamentActiveTab = (tabName) => ({
  type:getTournamentDetailsTypes.SET_TOURNAMENT_ACTIVE_TAB,
  payload:tabName
})

const getTournamentDetails = (sport_id=1,id,lang) => (
    dispatch => {
        dispatch(getTournamentDetailsStart())
        axios({
              method: "get",
              url: `${REACT_APP_NEW_BASE_URL}${REACT_APP_NEW_GET_TOURNAMENTS}/${id}`,
              params:{ sport_id },
              headers: {
                "Content-Type": "application/json",
                "Accept-Language":`${lang}`
              },
            }).then(resp => {   
              if (resp.data.status===200)
              { 
                dispatch(getTournamentDetailsSuccess(resp.data.data))
              }
              else {
                dispatch(getTournamentDetailsFailed(resp.data.message))
              }}
              )
              .catch(error => dispatch(getTournamentDetailsFailed(error)))
     
      }
)

export { getTournamentDetails , setTournamentActiveTab}
