import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import { getSeasons } from '../../redux/Seasons/Seasons.Actions'
import { Dropdown } from 'react-bootstrap'
import { useParams } from "react-router-dom";
import MatchesCalendar from '../MatchesCalendar/MatchesCalendar'
import { setActiveSeason } from '../../redux/Seasons/Seasons.Actions'
import TournamentOverviewStats from '../TournamentStatistics/TournamentOverviewStats'
import TournamentOverviewStand from '../TournamentStanding/TournamentOverviewStand'
import TopScorers from '../topScorers/topScorers'
import TopAssists from '../topAssists/topAssists'
import LatestNews from '../News/LatestNews'
import { useTranslation } from "react-i18next"
import SportsSectionHeader from '../sportsSectionHeader/sportsSectionHeader.component'
import LatestVids from '../News/LatestVids'
import LoadingSpinnner from '../loadingSpinner/loadingSpinner'

const TournamentOverview =({ season_id , toLocalTab})=>{

    const id = useParams().id;
    const { t } = useTranslation()

    return (
        <Container>
            <MatchesCalendar sport_id={1} season_id={season_id} tournament_id={id}/>
            <Row>
                <Col xs={12} md={8}>
                    <TournamentOverviewStats sport_id={1} season_id={season_id} toLocalTab={toLocalTab}  tournament_id={id}/> 
                    <div className='bg-primary-color p-2'>
                       <div className='secondary-color-light fs-xm p-2'>{t(`News.latestNews`)}</div>
                       <LatestNews  sport_id={1} season_id={season_id} tournament_id={id} items_count={6} mini={true} xs={6} md={4} media_type={1} latest={true}/>
                    </div>
                    <div className='bg-primary-color p-2'>
                       <div className='secondary-color-light fs-xm p-2'>{t(`News.latestVids`)}</div>
                       <LatestVids  sport_id={1} season_id={season_id} tournament_id={id} items_count={6} mini={true} xs={6} md={4} media_type={2} latest={true}/>
                    </div>
                </Col>
                <Col xs={12} md={4}>
                    <TournamentOverviewStand  toLocalTab={toLocalTab}  tournament_id={id}/>
                    <TopScorers  sport_id={1} season_id={season_id} tournament_id={id}/>
                    <TopAssists  sport_id={1} season_id={season_id} tournament_id={id}/>
                </Col>
            </Row>
        </Container>
    )
}



export default TournamentOverview