import React,{ useEffect } from 'react'
import { BarChart, Bar ,Cell, ResponsiveContainer} from "recharts";
import { connect } from 'react-redux'
import { getPlayerStatistics } from '../../redux/PlayerStatistics/PlayerStatistics.Actions';
import { useTranslation } from "react-i18next"
import { Row,Col } from 'react-bootstrap';
import ErrorMsg from '../ErrorPage/ErrorMsg';
import LoadingSpinnner from '../loadingSpinner/loadingSpinner';



const PlayerStatsChart = ({ PlayerStatistics , isFetchingPlayerStatistics , PlayerStatisticsFailed , sport_id,season_id,tournament_id, team_id, player_id, currentLanguageCode,getPlayerStatistics 
    ,  PlayerDetailsFailed , isFetchingPlayerDetails , currentTeam , teams , currentTeamActiveTournament , currentTeamTournaments}) => {


    const { t } = useTranslation()


   useEffect(()=> {
     getPlayerStatistics(sport_id,season_id,tournament_id,team_id,player_id,currentLanguageCode)
   },[sport_id,season_id,tournament_id,team_id,player_id,currentLanguageCode])



    const makeStats = ( obj )=> {
        return [{name:t(`StatsTable.Played`),value:100,color:'#4BB052'},
        {name:t(`StatsTable.Goals`),value:200,color:'#008A7D'},
        {name:t(`Player.Assists`),value:150,color:'#FCA103'},
        {name:t(`StatsTable.RedCards`),value:50,color:'#E34C1C'},
        {name:t(`StatsTable.YellowCards`),value:40,color:'#F8C12F'},
        {name:t(`Player.MinutesPlayed`),value:100,color:'#BECB35'}]
    }
    return (
        <div>
        <div className='bg-secondary-color p-3 mx-3'>
             {
                 t(`Player.PlayerStats`)
             }
         </div> 
        {
        PlayerStatisticsFailed !== null ?
        <div className='mx-3'>
          <ErrorMsg p={5} fs={'lg'} msg={t('Error.StatisticsError')}/>
        </div>
        :
        isFetchingPlayerStatistics   ?
        <div className='mx-3'>
          <LoadingSpinnner p={5} fs={'md'}/>
        </div>
        : 
        <div className='row bg-primary-color justify-content-between justify-content-md-around align-items-end  p-md-2 mx-3'>
            <div className='col-4 col-md-5 fs-xxs p-0'>
                {
                    makeStats(PlayerStatistics).map(stat => 
                        <div className='d-flex justify-content-between my-3 mx-2 mx-md-0'>
                            <div>
                                <span style={{minHeight:'15px', minWidth:'15px', background:`${stat.color}`,color:'transparent'}}>.....</span>
                                <span className='mx-2'>{stat.name}</span>
                            </div>
                            <div>
                                {stat.value}
                            </div>
                        </div>)
                }
            </div>
            <div className='col-7 d-flex flex-column align-items-end p-0'>  
                <BarChart width={230} height={200} data={makeStats(PlayerStatistics)}>
                    <Bar dataKey="value" fill='red'>
                    {
                        makeStats(PlayerStatistics).map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} width={35} />
                    ))
                    }
                    </Bar>
                </BarChart>
            </div>    
        </div>
        }
        </div>
    )
}

const mapStateToProps = state =>({
    PlayerStatistics:state.playerStatistics.PlayerStatistics,
    isFetchingPlayerStatistics:state.playerStatistics.isFetchingPlayerStatistics,
    PlayerStatisticsFailed:state.playerStatistics.PlayerStatisticsFailed,
    currentLanguageCode:state.language.currentLanguageCode,
})

const mapDispatchToProps = dispatch =>({
    getPlayerStatistics:(sport_id , season_id , tournament_id , team_id , player_id ,lang)=> dispatch(getPlayerStatistics(sport_id , season_id , tournament_id , team_id , player_id ,lang))
})

export default connect(mapStateToProps,mapDispatchToProps)(PlayerStatsChart)