import StandingTypes from "./Standing.Types";



const INITIAL_STATE = {
    Standing:[''],
    TournamentDetails:{},
    H2HTeams:[],
    isFetchingStanding:true,
    StandingFailed:null,
    showModal:false
   };
   
   const Standing = (state = INITIAL_STATE, action) => {
     switch (action.type) {
      case StandingTypes.GET_STANDING_START:
        return {
          ...state,
          isFetchingStanding:true
        }
      case StandingTypes.GET_STANDING_SUCCESS:
        return {
          ...state,
          Standing:action.payload.data.data.map(el => ({...el,checked:false})),
          TournamentDetails:action.payload.data.tournament,
          isFetchingStanding:false,
          StandingFailed:null
        }
        case StandingTypes.ADD_H2H_TEAM:
          return {
            ...state,
            H2HTeams:[...state.H2HTeams,{...action.payload,checked:true}],
            Standing:state.Standing.map(el=> el.id===action.payload.id? {...action.payload,checked:true} : el ),
            showModal:[...state.H2HTeams,{...action.payload,checked:true}].length >= 5 ? true : false
          }
          case StandingTypes.CLOSE_H2H_MODAL:
            return {
              ...state,
              H2HTeams:[],
              Standing:state.Standing.map(el=> ({...el,checked:false})),
              showModal:false
            }
            case StandingTypes.OPEN_H2H_MODAL:
              return {
                ...state,
                showModal:true
              }
      case StandingTypes.GET_STANDING_FAILED:
        return {
          ...state,
          StandingFailed:action.payload,
          isFetchingStanding:false
        }
       default:
         return state;
     }
   };
 
   export default Standing