import { Link } from "react-router-dom";
import React from "react";
import { Col, Container, Row } from "reactstrap";
import NewsLoading from "./NewsLoader";
import { convertDate } from "../../globalFn";
import { connect } from 'react-redux';
import { getMedisList,addMedisList } from "../../redux/MediaList/MediaList.Action";
import { GoCalendar } from "react-icons/go";
import NewsCarousel from "./NewsCarousel";
import { useTranslation } from "react-i18next"
import './newsSection.css'
import Moment from "moment";
import { useEffect } from "react";
import NewsCard from "./newsCard";


function LatestVids({  isFetchingLatestVids ,  addingLatestVids , LatestVids , LatestVids_CurrentPage ,  LatestVids_LastPage ,  LatestVidsFailed , currentLanguageCode , getMedisList ,  addMedisList , sport_id , season_id , items_count , media_type, tournament_id ,mini,xs,md , media_id, team_id , player_id , topStory , latest , popular }) {

  useEffect(()=> {
    // this is the params in order  (sport_id , season_id , items_count  , Media_type, tournament_id, media_id, team_id , player_id , topStory , latest , popular , lang)
    getMedisList(sport_id , season_id , items_count ,media_type , tournament_id, media_id, team_id , player_id , topStory , latest , popular , currentLanguageCode)
  }
  ,[sport_id , season_id , tournament_id, team_id , player_id ,media_type , topStory , latest , popular ,currentLanguageCode])
  
  const { t } = useTranslation()

    return (
      LatestVidsFailed !== null ?
      <div className='p-5 bg-secondary-color fs-lg text-light text-center'>{t(`Error.NewsError`)}</div>
      :
      isFetchingLatestVids ? 
       <NewsLoading mini={mini}/> 
       :
       LatestVids.length === 0 ?
       <div className='p-5 bg-secondary-color fs-lg text-light text-center'>{t(`Error.NewsError`)}</div>
       : 
       mini ? 
       <div className="news-section">
          <div className='row p-2'>
          {LatestVids.map((news) => (
                  <Col xs={xs} md={md} key={news.id}  className='p-1'>
                       <NewsCard news={news} type={2}/>
                   </Col>
                      ))}
          </div>
          <div className='text-center'>
            <button className='btn bg-active-color text-light fs-sm' onClick={()=> addMedisList(sport_id , season_id , items_count ,media_type , tournament_id, media_id, team_id , player_id , topStory , latest , popular , LatestVids_CurrentPage+1 , currentLanguageCode)} disabled={ LatestVids_CurrentPage >= LatestVids_LastPage}>
              {
                addingLatestVids ? 
                  <div class="spinner-border text-light fs-xs p-0" role="status">
                      <span class="sr-only">Loading...</span>
                  </div> 
                  :
                  t(`News.LoadMore`) 
              }
            </button>
          </div>
       </div>
       : 
        <Container fluid="md">
          <div className="news-section">
            <div className='row'>
              
                <div className='col-md-6 col-12 p-0 mt-2'>
                  <NewsCarousel
                    isLoading={isFetchingLatestVids}
                    MediaList={LatestVids.slice(0,4)}
                    currentLanguageCode={currentLanguageCode}
                    type={2}
                  />
                </div>
                <div className='col-md-6 col-12'>
                    <div className='row'>
                    {LatestVids.slice(0,4).map((news) => (
                        <div className='col-md-6 col-6 p-1' key={news.id}>
                            <NewsCard news={news} type={2}/>
                        </div>
                        ))}
                    </div>
               </div>
            </div>
            <div className='row'>
            {LatestVids.slice(4,8).map((news) => (
                        <div className='col-md-3 col-6 p-1' key={news.id}>
                            <NewsCard news={news} type={2}/>
                            {/* <ReactPlayer
                              stopOnUnmount={false}
                              playIcon={<div className="play-btn"></div>}
                              className="react-player"
                              url={process.env.REACT_APP_NEW_MEDIA_URL + props.mediaSrc}
                              width="100%"
                              height="100%"
                              controls={true}
                              config={{
                                file: {
                                  attributes: {
                                    controlsList: "nodownload", //<- this is the important bit
                                  },
                                },
                              }}
                            /> */}
                        </div>
                        ))}
            </div>    
          </div>
        </Container>
    );
}

const mapStateToProps = state =>({
  isFetchingLatestVids:state.MediaList.isFetchingLatestVids,
  LatestVids:state.MediaList.LatestVids,
  addingLatestVids:state.MediaList.addingLatestVids,
  LatestVids_CurrentPage:state.MediaList.LatestVids_CurrentPage,
  LatestVids_LastPage:state.MediaList.LatestVids_LastPage,
  LatestVidsFailed:state.MediaList.LatestVidsFailed,
  currentLanguageCode:state.language.currentLanguageCode
})

const mapDispatchToProps = (dispatch) => ({
  getMedisList: (sport_id , season_id , items_count ,Media_type, tournament_id, media_id, team_id , player_id, topStory , latest , popular , lang) =>
    dispatch(getMedisList(sport_id , season_id , items_count ,Media_type, tournament_id, media_id, team_id , player_id, topStory , latest , popular , lang)),

  addMedisList: (sport_id , season_id , items_count ,Media_type, tournament_id, media_id, team_id , player_id , topStory , latest , popular , lang) =>
    dispatch(addMedisList(sport_id , season_id , items_count ,Media_type, tournament_id, media_id, team_id , player_id , topStory , latest , popular , lang))
  
});

export default connect(mapStateToProps,mapDispatchToProps)(LatestVids);