import StandingTypes from "./Standing.Types"; 
import axios from 'axios'

const { REACT_APP_NEW_BASE_URL , REACT_APP_NEW_GET_STANDING   } = process.env

const getStandingStart = () => ({
    type:StandingTypes.GET_STANDING_START
})

const getStandingSuccess = (info) => ({
    type:StandingTypes.GET_STANDING_SUCCESS,
    payload:info
})

const getStandingFailed = (info) => ({
  type:StandingTypes.GET_STANDING_FAILED,
  payload:info
})

const addH2Hteam =(info)=>({
  type:StandingTypes.ADD_H2H_TEAM,
  payload:info
})

const openH2Hmodal=()=>({
  type:StandingTypes.OPEN_H2H_MODAL
})

const closeH2Hmodal=()=>({
  type:StandingTypes.CLOSE_H2H_MODAL
})



const getStanding = (sport_id , season_id , tournament_id , lang) => (
    dispatch => {
        dispatch(getStandingStart())
        axios({
              method: "get",
              url: `${REACT_APP_NEW_BASE_URL}${REACT_APP_NEW_GET_STANDING}`,
              params: { sport_id , season_id , tournament_id},
              headers: {
                "Content-Type": "application/json",
                "Accept-Language":`${lang}`
              },
            }).then(resp => {   
              if (resp.data.status===200)
              { 
                 dispatch(getStandingSuccess(resp.data))
              }
              else {
                dispatch(getStandingFailed(resp.data.message))
              }}
              )
              .catch(error => dispatch(getStandingFailed(error)))
     
      }
)

export { getStanding , addH2Hteam , openH2Hmodal , closeH2Hmodal}