import PlayerStatisticsTypes from "./PlayerStatistics.Types";



const INITIAL_STATE = {
    PlayerStatistics:{},
    isFetchingPlayerStatistics:true,
    PlayerStatisticsFailed:null
   };
   
   const PlayerStatistics = (state = INITIAL_STATE, action) => {
     switch (action.type) {
      case PlayerStatisticsTypes.GET_PLAYER_STATISTICS_START:
        return {
          ...state,
          isFetchingPlayerStatistics:true
        }
      case PlayerStatisticsTypes.GET_PLAYER_STATISTICS_SUCCESS:
        return {
          ...state,
          PlayerStatistics:action.payload,
          isFetchingPlayerStatistics:false,
          PlayerStatisticsFailed:null
        }
      case PlayerStatisticsTypes.GET_PLAYER_STATISTICS_FAILED:
        return {
          ...state,
          PlayerStatisticsFailed:action.payload,
          isFetchingPlayerStatistics:false
        }
       default:
         return state;
     }
   };
 
   export default PlayerStatistics