import React , { useEffect,useState } from 'react'
import { connect } from 'react-redux'
import { useParams } from "react-router-dom";
import { getTournamentHistory } from "../../redux/TournamentHistory/TournamentHistory.Actions";
import { getGeneralStatistics } from '../../redux/GeneralStatistics/GeneralStatistics.Actions'
import { setCurrentlyDisplayedGeneralTournamentHistory } from '../../redux/GeneralStatistics/GeneralStatistics.Actions'
import { addH2Hteam } from '../../redux/TournamentHistory/TournamentHistory.Actions'
import { useTranslation } from "react-i18next"
import Language from '../../redux/language/language.Reducer'
import { Container } from 'react-bootstrap';
import ErrorMsg from '../ErrorPage/ErrorMsg';
import LoadingSpinnner from '../loadingSpinner/loadingSpinner';



const TournamentHistory = ({ TournamentHistory ,  isFetchingTournamentHistory , TournamentHistoryFailed , currentLanguageCode ,  getTournamentHistory}) => {


    const id = useParams().id;
    
    useEffect(()=> {
        getTournamentHistory(id,currentLanguageCode)
    },[id ,currentLanguageCode])

    const { t } = useTranslation()


    return (


    <Container>
    {
        TournamentHistoryFailed !== null?
        <div className='mt-2'>
            <ErrorMsg p={5} fs={'lg'} msg={t('Error.GeneralError')}/>
        </div>
        :
        isFetchingTournamentHistory  ?
        <div className='mt-2'>
            <LoadingSpinnner p={5} fs={'md'} />
        </div>
 
        :
        <div>
            <div className='my-3 bg-primary-color p-5 secondary-color-light'>
                {TournamentHistory.description}
            </div>    
            <div className='bg-primary-color secondary-color-light fs-xs mt-3 row p-1'>
                <table class="table-borderless bg-primary-color table-striped table-dark secondary-color-light col-12">
                <thead>
                    <tr style={{padding:'10px'}} className="text-center">
                    <th>{t(`StatsTable.Position`)}</th>
                    <th scope="col" className={window.document.body.dir === 'rtl' ? 'text-right': 'text-left'}>{t(`StatsTable.Club`)}</th>
                    <th scope="col">{t(`History.Winner`)}</th>
                    <th scope="col">{t(`History.Runner-up`)}</th>
                    <th scope="col">{t(`History.WinningSeasons`)}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        TournamentHistory.winners.length===0 ? 
                        <tr>
                            <td colspan={15} className='p-0'>
                                <ErrorMsg msg={t('Error.GeneralError')} p={5} fs={'lg'} />
                            </td>
                        </tr>
                        :
                        TournamentHistory.winners.map(
                            (team,i) =>        
                            <tr  key={team.id} className="text-center">
                            <td style={{width:'25px'}}>{i+1}</td>
                            <td scope="row" className={window.document.body.dir === 'rtl' ? 'text-right team-name': 'text-left team-name'}><img src={process.env.REACT_APP_NEW_MEDIA_URL+team.team_logo} style={{width:'20px'}}/><span className="mx-2 fs-xxs ">{team.team_name}</span></td>
                            <td>{team.count}</td>
                            <td>tbd</td>
                            <td>{team.seasons}</td>
                            </tr>
                        
                            )
                    }

                </tbody>
                </table>
            </div>
        </div>
    }
    </Container>   
    )
}


const mapDispatchToProps = (dispatch) => ({
    getTournamentHistory: (tournament_id ,lang) => dispatch(getTournamentHistory(tournament_id ,lang)),
  });
  
  const mapStateToProps = (state) => ({
    isFetchingTournamentHistory: state.tournamentHistory.isFetchingTournamentHistory,
    TournamentHistoryFailed:state.tournamentHistory.TournamentHistoryFailed,
    TournamentHistory: state.tournamentHistory.TournamentHistory,
    currentLanguageCode:state.language.currentLanguageCode,
  });
  
  

export default connect(mapStateToProps,mapDispatchToProps)(TournamentHistory)