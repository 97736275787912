import i18next from "i18next";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import "./App.css";
import dorAdd from "./assets/adds/doritous add.jpg";
import FooterComponent from "./components/footer/FooterComponent";
import MainNavBar from "./components/MainNavBar/MainNavBar.component";
import NavbarComponent from "./components/Navbar/NavbarComponent";
import SideMenu from "./components/SideMenu/SideMenu";
import TeamsCarousel from "./components/TeamsCarousel/TeamsCarousel";
import TournamentsPage from "./Pages/Competition/Competition";
import MatchesPage from "./Pages/Matches/Matches";
import MatchPage from "./Pages/MatchPage/MatchPage.component";
import News from "./Pages/News/News";
import player from "./Pages/Player/player";
import Team from "./Pages/Team/Team";
import Tournament from "./Pages/Tournament/Tournament";
import Vids from "./Pages/Vids/Vids";
import { getSeasons } from "./redux/Seasons/Seasons.Actions";
import ScrollToTop from "./ScrollToTop";


const Home = React.lazy(() => import("./Pages/Home/Home"));
const Favorite = React.lazy(() => import("./Pages/Favorite/Favorite"));
const Subscription = React.lazy(() =>
  import("./Pages/Subscription/Subscription")
);
const MediaDetails = React.lazy(() =>
  import("./Pages/MediaDetails/MediaDetails")
);
const Profile = React.lazy(() => import("./Pages/Profile/Profile"));

function App({
  languages,
  currentLanguageCode,
  getSeasons,
}) {
  const { t } = useTranslation();




  useEffect(() => {
    getSeasons();
    
    i18next.changeLanguage(currentLanguageCode);
    window.document.body.lang = i18next.language;
    window.document.documentElement.lang = i18next.language;
    const currentLanguage = languages.find(
      (l) => l.code === currentLanguageCode
    );
    document.body.dir = currentLanguage.dir || "ltr";
    document.title = t("Etisalat Sports");
  }, [currentLanguageCode]);


  return (
    <div>
       <ScrollToTop />
       {/* <div style={{height:'90px'}}></div> */}
      <Switch>
     
        <>
         
          <NavbarComponent />
          <MainNavBar />
          <TeamsCarousel />
          <SideMenu />
          <Route exact path="/" component={Home} />
          <Route exact path="/news" component={News} />
          <Route exact path="/videos" component={Vids} />
          <Route exact path="/tournaments" component={TournamentsPage} />
          <Route exact path="/matches" component={MatchesPage} />
          <Route exact path="/tournament/:id/:tab" component={Tournament} />
          <Route exact path="/tournament/:id" component={Tournament} />
          <Route exact path="/team/:id/:tab" component={Team} />
          <Route exact path="/team/:id" component={Team} />
          <Route exact path="/player/:id/:tab" component={player} />
          <Route exact path="/player/:id" component={player} />
          <Route path="/match/:id" component={MatchPage} />
          <Route exact path="/media/:id" component={MediaDetails} />
          <Route exact path="/profile" component={Profile} />
          <Route exact path="/favorite/:choice" component={Favorite} />
          <Route path="/subscribe" component={Subscription} />
        </>
      </Switch>
        <div className="d-flex justify-content-center container mt-4">
          <img src={dorAdd} height={250} width={"70%"}/>
        </div>
      <FooterComponent />
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  getSeasons: () => dispatch(getSeasons()),
});

const mapStateToProps = (state) => ({
  seasons: state.seasons.Seasons,
  activeSeason: state.seasons.activeSeason,
  isFetchingSeasons: state.seasons.isFetchingSeasons,
  languages: state.language.languages,
  currentLanguageCode: state.language.currentLanguageCode,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
