const arrangebySort = (state,event) => {
    const newStateArr=state.map(el => el)
    const eventSortArr=state.map(el => el.event_sort)
    if(eventSortArr.includes(event.event_sort)){
        const newEventArr=[]
        for(let i=0 ; i < state.length ;i++){
            if(newStateArr[i].event_sort===event.event_sort){
             newEventArr.push(event) 
             state.slice(i).forEach(el =>  {newEventArr.push({...el,event_sort:el.event_sort+1})})
             return newEventArr
            }
            else {
                newEventArr.push(newStateArr[i])
            } 
        };
        return newEventArr
    }
    else {
        return [...state,event]
    }
}

const deleteScoreCounter = (matchEvents,team_id) => {
    const eventsWithGoal=[20,21,22,23,24,25]
    const teamGoals=matchEvents.map(event => event.team_id===team_id && eventsWithGoal.includes(event.event_id)? event : '')
    const teamGoalFiltered=teamGoals.filter(el => el != '')
    console.log(teamGoalFiltered)
    return teamGoalFiltered.length
}

export { arrangebySort , deleteScoreCounter} 

