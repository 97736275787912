import NewsSearchTypes from "./NewsSearch.Types"; 
import axios from 'axios'




const { REACT_APP_NEW_BASE_URL , REACT_APP_NEW_GET_NEWS_SEARCH   } = process.env

const changeNewsSearchInputVal = (query) =>({
  type:NewsSearchTypes.CHANGE_NEWS_SEARCH_VALUE,
  payload:query,
})

const changeNewsSearchState = () => ({
   type:NewsSearchTypes.CHANGE_NEWS_SEARCH_STATE,
})

const getNewsSearchStart = (info) =>({
  type:NewsSearchTypes.GET_NEWS_SEARCH_START,
  payload:info
})

const getNewsSearchSuccess = (info) => ({
    type:NewsSearchTypes.GET_NEWS_SEARCH_SUCCESS,
    payload:info
})

const getNewsSearchFailed = (error) => ({
    type:NewsSearchTypes.GET_NEWS_SEARCH_FAILED,
    payload:error
})




const getNewsSearch = (query,lang,acSignal) => (
    dispatch => {
        dispatch(getNewsSearchStart(query))
        axios({
              method: "get",
              signal: acSignal,
              url: `${REACT_APP_NEW_BASE_URL}/media`,
              headers: {
                "Content-Type": "application/json",
                "Accept-Language":`${lang}`
              },
              params:{
                keyword:query
              }
            }).then(resp => {   
              if (resp.data.status===200)
              { 
                 dispatch(getNewsSearchSuccess(resp.data.data))
              }
              else {
                dispatch(getNewsSearchFailed(resp.data.message))
              }}
              )
              .catch(error => dispatch(getNewsSearchFailed(error)))
     
      }
)

export { getNewsSearch , changeNewsSearchInputVal, changeNewsSearchState}