import React,{ useEffect } from 'react';
import { connect } from 'react-redux';
import { getTeamStatistics } from '../../redux/TeamStatistics/TeamStatistics.Actions';
import { useTranslation } from "react-i18next"
import ErrorMsg from '../ErrorPage/ErrorMsg';
import LoadingSpinnner from '../loadingSpinner/loadingSpinner';



const TeamOverviewStats = ({ teamStatistics , isFetchingTeamStatistics , TeamStatisticsFailed , sport_id,season_id,tournament_id,team_id, currentLanguageCode,getTeamStatistics ,toLocalTab}) => {


    const { t } = useTranslation()


   useEffect(()=> {
     if(tournament_id===null) getTeamStatistics(sport_id,season_id,tournament_id,team_id,currentLanguageCode)
   },[sport_id,season_id,tournament_id,team_id,currentLanguageCode])

   const statisticsKeys={
    played:t(`StatsTable.Played`),
    win: t(`StatsTable.Wins`),
    lose: t(`StatsTable.Losses`),
    draw: t(`StatsTable.Draw`),
    goals_in: t(`StatsTable.GoalsAgainst`),
    goals_out: t(`StatsTable.GoalsFor`),
    goals_penalty: t(`StatsTable.GoalsPenalty`),
    clean_sheet: t(`StatsTable.CleanSheet`),
    yellow_cards: t(`StatsTable.YellowCards`),
    red_cards: t(`StatsTable.RedCards`),
    difference: t(`StatsTable.GoalsDifference`),
    point: t(`StatsTable.Points`),
    passes: t(`StatsTable.passes`),
    lost_balls: t(`StatsTable.LostBalls`),
    attempts: t(`StatsTable.Attempts`),
    chances: t(`StatsTable.Chances`),
    dribbles: t(`StatsTable.Dribbles`),
    fouls_in: t(`StatsTable.FoulsIn`),
    fouls_out: t(`StatsTable.FoulsOut`),
    ball_win: t(`StatsTable.BallWin`),
    ball_lose: t(`StatsTable.BallLose`),
}
    return (
    <div className='bg-primary-color secondary-color-light fs-xs mt-2'>
        <div className='d-flex justify-content-between fs-md p-2'>
        <div>{t('TournamentDetails.Stats')}</div>
        <div  style={{direction:'rtl'}}> 
            <button className={`fa fa-chevron-${currentLanguageCode === 'ar' ? 'left':'right'} btn secondary-color-light`} aria-hidden="true" onClick={()=> toLocalTab('standing')}></button>
        </div>
       </div>
        <table class="table-borderless bg-primary-color table-striped table-dark secondary-color-light">
        <thead>
        </thead>
        <tbody>
            {
            TeamStatisticsFailed !== null ?
            <ErrorMsg p={5} fs={'md'} msg={t('Error.StatisticsError')}/>
            :
            isFetchingTeamStatistics?
            <LoadingSpinnner p={5} fs={'md'} />
            :
              Object.keys(teamStatistics).slice(0,Object.keys(teamStatistics).length-2).map((key,i)=>  
                    <tr key={i} className='d-block'>
                        <div className='d-flex justify-content-between mx-1 p-2'>
                            <div>{statisticsKeys[key]}</div>
                            <div>{Object.values(teamStatistics)[i]}</div>
                        </div>
                    </tr>
                    )
            }

        </tbody>
        </table>
    </div>
    )
}

const mapStateToProps = state =>({
    teamStatistics:state.teamStatistics.TeamStatistics,
    isFetchingTeamStatistics:state.teamStatistics.isFetchingTeamStatistics,
    TeamStatisticsFailed:state.teamStatistics.TeamStatisticsFailed,
    currentLanguageCode:state.language.currentLanguageCode,
})

const mapDispatchToProps = dispatch =>({
    getTeamStatistics:(sport_id,season_id,tournament_id,team_id,lang)=> dispatch(getTeamStatistics(sport_id,season_id,tournament_id,team_id,lang))
})

export default connect(mapStateToProps,mapDispatchToProps)(TeamOverviewStats)