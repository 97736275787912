import { Container, Col } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const NewsLoading = ({mini}) => {
  return (
    <Container fluid="md">
      <SkeletonTheme color="#323232" highlightColor="#ecebeb">
    <div className="news-section">
      <div className='row'>
        
          <div className='col-md-6 col-12 p-0 mt-2 news-card-carousel'>
            <Skeleton height={'100%'} width={'100%'}/>
          </div>
          <div className='col-md-6 col-12'>
          <div className='row'>
          {[1,1,1,1].map((news) => (
            <div className='col-md-6 col-6 p-1' key={news.id}>
              <div className='news-card'>
                <Skeleton height={'100%'} width={'100%'}/>
              </div>
            </div>
          ))}
         </div>
         </div>
      </div>
    </div>
    </SkeletonTheme>
  </Container>

  );
};

export default NewsLoading;

