import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useParams } from 'react-router';
import { Container } from 'reactstrap';
import { connect } from 'react-redux';
import { getTournamentMatches } from '../../redux/TournamentMatches/TournamentMatches.Actions';
import Select from 'react-select';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getName } from '../../globalFn'
import { convertFullDate } from '../../globalFn'
import allCompetitionsImg from '../../assets/allCompetions.png'
import { useTranslation } from "react-i18next"
import { useEffect,useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import MatchCard from '../MatchCard/MatchCard';



// --bk-color-2



function PlayerMatches({ TournamentMatchesFailed, Fixtures , Results , getTournamentMatches , isFetchingTournamentMatches , currentLanguageCode , sport_id  ,tournament_id , team_id}) {

  //NOTE: the same component is used to get team matches just using team_id insted of tournament_id so recap if tournament_id used it get tournament matches if team_id is used it gets team matches
  //NOTE: the same component is used to get player matches just using team_id insted of tournament_id so recap if tournament_id used it get tournament matches if team_id is used it gets team matches
  const today=new Date()
  const todayDate=[today.getFullYear(),today.getMonth()+1,today.getDate()].join("-");
  useEffect(()=>{
      getTournamentMatches(sport_id  ,  tournament_id , team_id , null , currentLanguageCode)
  },[ sport_id ,tournament_id , team_id ,currentLanguageCode])



  const { t } = useTranslation()



    return (
        <div className='mt-2'>
        
            {
                TournamentMatchesFailed != null ? 
                <div>error</div>
                :
                isFetchingTournamentMatches? 
                <div>...loading</div>
                :

                <div className='mx-auto'>
                <div className='fs-md secondary-color-light mb-2'>{t(`MatchesCalendar.Fixtures`)}</div>
                {
                Fixtures.length === 0 ? 
                <div className='my-3 secondary-color-light bg-primary-color p-5'>لا يوجد مباريات</div>
                :
                <Row xs={1} md={2} className='justify-content-between'>
                {
                 Fixtures.slice(0,2).map(el => 
                   <div className='col-12 col-md-6'>
                       <MatchCard el={el} key={el.id}/>
                   </div> 
                )
                }
                </Row>
                }
                <div className='fs-md secondary-color-light my-2'>{t(`MatchesCalendar.Results`)}</div>
                {
                Results.length === 0 ? 
                <div className='my-3 secondary-color-light bg-primary-color p-5'>لا يوجد مباريات</div>
                :
                <Row xs={1} md={2} className='justify-content-between'>
                {
                Results.slice(0,2).map(el => 
                    <div className='col-12 col-md-6'>
                       <MatchCard el={el} key={el.id}/> 
                    </div>
                )
                }
                </Row>
                }
               </div>
               
                     
            }
        </div>    
    )
}

const mapStateToProps = state =>({
    isFetchingTournamentMatches:state.tournamentMatches.isFetchingTournamentMatches,
    Fixtures:state.tournamentMatches.Fixtures,
    Results:state.tournamentMatches.Results,
    TournamentMatchesFailed:state.tournamentMatches.TournamentMatchesFailed,
    currentLanguageCode:state.language.currentLanguageCode
})
const mapDispatchToProps = dispatch =>({
     getTournamentMatches:(sport_id  , tournament_id ,  team_id, start_date ,currentLanguageCode)=> dispatch(getTournamentMatches(sport_id , tournament_id ,  team_id, start_date , currentLanguageCode))
})
export default connect(mapStateToProps,mapDispatchToProps)(PlayerMatches)