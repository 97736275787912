import React from 'react'
import etisalteLogo from '../../assets/etisalat-logo.svg'
import { useTranslation } from "react-i18next"
import { Link } from 'react-router-dom'


const ErrorMsg= ({msg,p,fs}) =>{

    const { t } = useTranslation()


    return (
        <div  className={`p-${p} fs-${fs} bg-secondary-color text-center text-light`}>
            {msg}
        </div>
    )
}

export default ErrorMsg