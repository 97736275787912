import React from "react";
import { Link } from "react-router-dom";
import { GoCalendar } from "react-icons/go";
import Moment from "moment";

const NewsCard = ({ news, type }) => {
  return (
    <div className="news-card p-0">
      <Link to={`/media/${news.id}`}>
        <div>
          {type === 2 ? (
            <div className="play-icon">
              <i className="fa fa-play"></i>
            </div>
          ) : (
            ""
          )}

          <img
            src={`${process.env.REACT_APP_NEW_MEDIA_URL}${
              type === 2 ? news.video_cover : news.media
            }`}
          />
        </div>
        <div>
          <div className="news-card-description bg-primary-color-opacity p-1">
            <p className="text-cut-2 text-light px-1 fs-xs  mb-1 ">
              {news.title}
            </p>
            <small className="text-left px-2 mb-1 secondary-color-light">
              <GoCalendar className="mx-2 " />
              {/* {convertDate(news.createdAt)} */}
              {/* {format(news.createdAt, "MMMM do, yyyy H:mma")} */}
              {Moment(news.createdAt).format("D MM yyyy")}
            </small>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default NewsCard;
