import PlayerDetailsTypes from "./PlayerDetails.Types";

const INITIAL_STATE = {
    PlayerDetails:{},
    isFetchingPlayerDetails:true,
    PlayerDetailsFailed:null,
    currentTeam:{},
    teams:[],
    currentTeamActiveTournament:{},
    currentTeamTournaments:[]
   };
   
   const PlayerDetails = (state = INITIAL_STATE, action) => {
     switch (action.type) {
      case PlayerDetailsTypes.GET_PLAYER_DETAILS_START:
        return {
          ...state,
          isFetchingPlayerDetails:true
        }
      case PlayerDetailsTypes.GET_PLAYER_DETAILS_SUCCESS:
        return {
          ...state,
          PlayerDetails:action.payload,
          isFetchingPlayerDetails:false,
          PlayerDetailsFailed:null,
          currentTeam:action.payload.current_team,
          teams:action.payload.teams,
          currentTeamActiveTournament:action.payload.current_team.tournaments[0],
          currentTeamTournaments:action.payload.current_team.tournaments
        }
      case PlayerDetailsTypes.SET_CURRENT_PLAYER_TEAM:
        return {
          ...state,
          currentTeam:action.payload,
          currentTeamTournaments:action.payload.tournaments,
          currentTeamActiveTournament:action.payload.tournaments[0]
        }
        case PlayerDetailsTypes.SET_CURRENT_PLAYER_TOURNAMENT:
          return {
            ...state,
            currentTeamActiveTournament:action.payload
          }
      case PlayerDetailsTypes.GET_PLAYER_DETAILS_FAILED:
        return {
          ...state,
          PlayerDetailsFailed:action.payload,
          isFetchingPlayerDetails:false
        }
       default:
         return state;
     }
   };
 
   export default PlayerDetails