import React,{ useEffect } from 'react'
import { Row,Col } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import './PlayerPosition.css'
import playerPosImg from '../../assets/playerPos.png';


    

const PositionField = ({ playerPosition, positionAcronym })=> {
    const positions = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16]
    const { t } = useTranslation()

    const positionMap={
        GK:16,
        RB:15,
        CB:14,
        LB:13,
        RMF:12,
        DMF:11,
        LMF:10,
        NONE:9,
        CMF:8,
        NONE:7,
        RW:6,
        AMF:5,
        LW:4,
        NONE:3,
        CF:2,
        NONE:1
    }

    return (
        <div className='bg-primary-color pb-4 text-light mt-3 mt-md-0'>
            <div className='bg-secondary-color secondary-color-light p-3'>{t(`Player.MainPosition`)}</div>
            <Row>
                <Col xs={4} className='text-center d-flex flex-column align-items-center justify-content-center'>
                    <div className='fs-sm secondary-color-light'>{t(`Player.MainPosition`)}:</div>
                    <div className='fs-md'>{playerPosition}</div>
                </Col>
                <Col xs={6}>
                    <div className='player-details-position-field'>
                        {
                            <Row className='d-flex justify-content-around mt-3' style={{height:'90%'}}>
                                {
                                 positions.map(pos=>  positionMap[positionAcronym] === pos ? 
                                <div className='col-4' style={{height:'15%'}} >
                                    <img src={playerPosImg}/>
                                </div>
                                :
                                <div  className='col-4' style={{height:'15%'}} >
                                </div>)
                                }
                            </Row>    
                        }
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default PositionField