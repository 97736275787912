import NewsSearchTypes from "./NewsSearch.Types";



const INITIAL_STATE = {
    NewsSearchQuery:'',
    NewsSearchSearching:false,
    NewsSearch:[],
    abortController:new AbortController(),
    isFetchingNewsSearch:false,
    NewsSearchFailed:null
   };
   
   const NewsSearch = (state = INITIAL_STATE, action) => {
     switch (action.type) {
      case NewsSearchTypes.CHANGE_NEWS_SEARCH_VALUE:
        return {
          ...state,
          NewsSearchQuery:action.payload,
        }
      case NewsSearchTypes.CHANGE_NEWS_SEARCH_STATE:
        return {
          ...state,
          NewsSearchSearching:!state.NewsSearchSearching
        }   
      case NewsSearchTypes.GET_NEWS_SEARCH_START:
        return {
          ...state,
          isFetchingNewsSearch:true,
          NewsSearch:[],
          NewsSearchQuery:action.payload,
          abortController:new AbortController(),

        }
      case NewsSearchTypes.GET_NEWS_SEARCH_SUCCESS:
        return {
          ...state,
          NewsSearch:action.payload,
          isFetchingNewsSearch:false,
          NewsSearchFailed:null
        }
      case NewsSearchTypes.GET_NEWS_SEARCH_FAILED:
        return {
          ...state,
          NewsSearchFailed:action.payload,
          isFetchingNewsSearch:false
        }
       default:
         return state;
     }
   };
 
   export default NewsSearch