import React , { useEffect,useState } from 'react'
import { connect } from 'react-redux'
import { useParams } from "react-router-dom";
import { getStanding } from "../../redux/Standing/Standing.Actions";
import { getGeneralStatistics } from '../../redux/GeneralStatistics/GeneralStatistics.Actions'
import { setCurrentlyDisplayedGeneralStanding } from '../../redux/GeneralStatistics/GeneralStatistics.Actions'
import { addH2Hteam,openH2Hmodal } from '../../redux/Standing/Standing.Actions'
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom";
import Language from '../../redux/language/language.Reducer'
import './TournamentStanding.css'
import { Container } from 'react-bootstrap';
import H2HModal from './H2HModal.jsx';
import ErrorMsg from '../ErrorPage/ErrorMsg';
import LoadingSpinnner from '../loadingSpinner/loadingSpinner';
import Vs from '../../assets/Vs.png'

const TourStandingDetails = ({ Standing , standingFailed, isFetchingStanding , currentLanguageCode ,  getStanding , showModal , H2HTeams, addH2Hteam , openH2Hmodal ,sport_id , season_id ,tournamentDetails ,toLocalTab }) => {


    const id = useParams().id;
    
    useEffect(()=> {
        getStanding(sport_id , season_id ,id,currentLanguageCode)
    },[sport_id , season_id ,id ,currentLanguageCode])

    const history = useHistory(); 
    const handleRowClick = (id) => {
        history.push(`/team/${id}`);
      }  

    const { t } = useTranslation()


    return (
    <Container>
    <H2HModal show={showModal}/>
    <div className='bg-primary-color secondary-color-light fs-xs mt-3 row'>
        <table class="tournament-standing table-borderless bg-primary-color table-striped table-dark secondary-color-light col-12">
        <thead>
            <tr style={{padding:'10px'}} className="text-center">
            <th>{t(`StatsTable.Position`)}</th>
            <th scope="col" className={window.document.body.dir === 'rtl' ? 'text-right': 'text-left'}>{t(`StatsTable.Club`)}</th>
            <th scope="col">{t(`StatsTable.Played`)}</th>       
            <th scope="col">{t(`StatsTable.Wins`)}</th>
            <th scope="col">{t(`StatsTable.Losses`)}</th>
            <th scope="col">{t(`StatsTable.GoalsFor`)}</th>
            <th scope="col">{t(`StatsTable.GoalsAgainst`)}</th>
            <th scope="col" className='d-none d-md-block'>{t(`StatsTable.GoalsDifference`)}</th>
            <th scope="col">{t(`StatsTable.Points`)}</th>
            <th scope="col">Vs</th>
            </tr>
        </thead>
        <tbody>
            {
                standingFailed !== null ?
                <tr>
                    <td colspan={10} className='p-0'>
                        <ErrorMsg msg={t('Error.StandingError')} p={5} fs={'lg'} />
                    </td>
                </tr>
                :
                isFetchingStanding ?
                <tr>
                <td colspan={10} className='p-0'>
                    <LoadingSpinnner p={4} fs={'md'}/>
                </td>
                </tr>
                :
                Standing.length === 0 ?
                <tr>
                <td colspan={10} className='p-0'>
                    <ErrorMsg msg={t('Error.StandingError')} p={5} fs={'lg'} />
                </td>
                </tr>
                :
                Standing.map(
                    (team,i) =>        
                    <tr  key={team.id} onClick={()=> handleRowClick(team.team_id)} className={`table-clickable-row-no-animation text-center`}>
                    <td style={{width:'25px'}}>{i+1}</td>
                    <td scope="row" className={window.document.body.dir === 'rtl' ? 'text-right team-name': 'text-left team-name'}><img src={process.env.REACT_APP_NEW_MEDIA_URL+team.team_logo} style={{width:'20px'}}/><span className="mx-1 mx-md-2 text-nowrap fs-xxs ">{team.team_name}</span></td>
                    <td>{team.played}</td>
                    <td>{team.win}</td>
                    <td>{team.lose}</td>
                    <td>{team.goals_out}</td>
                    <td>{team.goals_in}</td>
                    <td className='d-none d-md-block'>{team.difference}</td>
                    <td>{team.point}</td>
                    <td  onClick={(e)=> e.stopPropagation()}>
                        <div className="form-check d-flex justify-content-center">
                          <input className="form-check-input " type="checkbox" value={i} checked={team.checked} id={team.id} onClick={(e)=> {
                              e.stopPropagation()
                              addH2Hteam(Standing[e.target.value])}}></input>
                        </div>  
                    </td>
                    </tr>
                
                    )
            }

        </tbody>
        </table>
    </div>
    <div className='text-center vs-button'>
       <button className='btn  mt-1' disabled={ H2HTeams.length < 2} onClick={()=> openH2Hmodal()}><img src={Vs} width={90}/></button>
    </div>
    </Container>   
    )
}


const mapDispatchToProps = (dispatch) => ({
    getStanding: (sport_id , season_id ,tournament_id ,lang) => dispatch(getStanding(sport_id , season_id  ,tournament_id ,lang)),
    addH2Hteam:(info)=> dispatch(addH2Hteam(info)),
    openH2Hmodal:()=> dispatch(openH2Hmodal())
  });
  
  const mapStateToProps = (state) => ({
    isFetchingStanding: state.standing.isFetchingStanding,
    standingFailed:state.standing.StandingFailed,
    Standing: state.standing.Standing,
    currentLanguageCode:state.language.currentLanguageCode,
    tournamentDetails: state.standing.TournamentDetails,
    H2HTeams:state.standing.H2HTeams,
    showModal:state.standing.showModal
  });
  
  

export default connect(mapStateToProps,mapDispatchToProps)(TourStandingDetails)