import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { getNewsSearch , changeNewsSearchInputVal, changeNewsSearchState} from '../../redux/NewsSearch/NewsSearch.Actions'
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import NewsCard from './newsCard';
import { abortController } from '../../redux/Search/Search.Actions'
import { Form } from 'react-bootstrap'
import LoadingSpinnner from '../loadingSpinner/loadingSpinner';


const NewsSearch = ({NewsSearchQuery, NewsSearch,  getNewsSearch , abortController , changeNewsSearchInputVal , changeNewsSearchState , NewsSearchsearching , isFetchingNewsSearch, NewsSearchFailed}) => {

    // useEffect(()=> 
    // searching ? "" : getNewsSearch(NewsSearchQuery,'ar')
    // ,[searching])
    const { t } = useTranslation();
    const history = useHistory();

    const handleRowClick = (type,id) => {
      history.push(`/${type}/${id}`);
    };


    return (
    <div>
        <input className="form-control me-2" type="search" placeholder="ابحث هنا......" aria-label="Search" value={NewsSearchQuery} style={{width:'300px'}}
        onChange={(e)=> {
            changeNewsSearchInputVal(e.target.value)
            if(!NewsSearchsearching) {
                changeNewsSearchState()
                {
                    setTimeout(()=> {
                    changeNewsSearchState()
                    getNewsSearch(e.target.value,'ar')
                    }
                    ,1000)
                }
            }

            
            // getNewsSearch(e.target.value,'ar',abortController.signal)
            // abortController.abort()
            
        }}
            />     
        <div className={`${NewsSearchQuery === "" ? 'd-none' : ''} text-light`}>
           {
               isFetchingNewsSearch ?
               <div className='text-center'>
                <div className="lds-roller mx-auto"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                <div  className={`text-center text-light`}>
               {t(`Error.Loading`)}
               </div>
               </div>
               :
               NewsSearch.length === 0 ? 
               <div className='text-center p-5'>لايوجد نتائج</div>
               :
               <div className='row news-section mt-3'>
               {
                   NewsSearch.map(news=> 
                     <div className='col-4 col-md-3 p-1'>
                         <NewsCard news={news} type={1}/>
                     </div>)
               }
               </div>
           }
        </div>
        {/* <i className="fa fa-search fs-lg  text-light"></i> */}
    </div>
    )
}



const mapStateToProps= state => ({
    NewsSearchQuery:state.newsSearch.NewsSearchQuery,
    NewsSearch:state.newsSearch.NewsSearch,
    isFetchingNewsSearch:state.newsSearch.isFetchingNewsSearch,
    NewsSearchFailed:state.newsSearch.NewsSearchFailed,
    abortController:state.newsSearch.abortController,
    NewsSearchsearching:state.newsSearch.NewsSearchSearching
})

const mapDispatchToProps = dispatch =>({
    getNewsSearch:(query,lang) => dispatch(getNewsSearch(query,lang)),
    changeNewsSearchInputVal:(query) => dispatch(changeNewsSearchInputVal(query)),
    changeNewsSearchState:() => dispatch(changeNewsSearchState())
})
export default connect(mapStateToProps,mapDispatchToProps)(NewsSearch)