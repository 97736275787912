import { connect } from 'react-redux'
import { getMatchInfo } from '../../redux/Match/Actions.Match'
import {Row,Col} from 'react-bootstrap'
import './MatchHeader.css'
import { useEffect } from 'react'
import Countdown from 'react-countdown'
import { convertFullDate } from '../../globalFn'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/free-regular-svg-icons'
import stadiumIcon from '../../assets/stadium.png'
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons'
import { useHistory } from "react-router-dom";
import MatchHeaderLoader from './matchHeaderLoader'
import RadialTimer from '../matchRadialTimer/RadialTimer'



const MatchHeader = ({
    getMatchInfo,  matchRoundInfo ,team1Goals, team2Goals, team1, team2, isFetchingMatchStatus, 
    matchStatus, stadium, time, date, matchId,statusString}) => {

    const { REACT_APP_NEW_BASE_URL ,REACT_APP_NEW_MEDIA_URL,   } = process.env

    useEffect (() => {

       getMatchInfo(matchId)
       
    }
    ,[ matchStatus])

    const history = useHistory();
    const handleRowClick = (id) => {
      history.push(`/team/${id}`);
    };

    

    const getTime = () => {
       return date.split(' ')[1].split(':').slice(0,2).join(':')
    }

    const parseDate=convertFullDate(date,'ar')


    const getTimer= () => {
        
        // if(matchStatus===1){
        //     const date=dateTime.split(' ').join('T')
        //     return  <div>
        //                 <Countdown date={ Date.parse(date)} zeroPadDays={false} onComplete={()=> {      
        //                     getMatchInfoUpdate(matchId) 
        //                 }}/>
        //            </div>
        // }
        if(Date.now()<Date.parse(date) && Date.parse(date)-Date.now()<=3600000){
            const d=date.split(' ').join('T')
                return  <div>
                            <Countdown date={ Date.parse(d)} zeroPadDays={false}  onComplete={()=> {      
                                 getMatchInfo(matchId)
                            }}/>
                       </div>

        }
        if(matchStatus===4){
            return <div>ملغي</div>
        }
        if(matchStatus===3){
            return <div>موجل</div>
        }
        if(matchStatus===1 || matchRoundInfo===null){
            return <div>لم تبدا</div>
        }
        if(matchStatus===5 || matchRoundInfo.event_id===11){
            return <div>نهاية المبارة</div>
        }
        if(matchStatus===2&&matchRoundInfo.event_id===10){
           return <div>بداية الضربات الترجيحية</div>
        }
        if(matchStatus===2&&matchRoundInfo.event_id===8){
            return <div>
                      <div>نهاية الشوط الثاني الاضافي </div>
                      <div>30:00</div>
                  </div>
        }
        else if(matchStatus===2&&matchRoundInfo.event_id===7){
            const d=matchRoundInfo.created_at.split(' ').join('T')
            return  <div>
                       <Countdown date={ Date.parse(d)+900000} renderer={round2extra}/>
                    </div> 
        }
        else if(matchStatus===2&&matchRoundInfo.event_id===6){
            return  <div>
                        <div>نهاية الشوط الاضافي الاول</div>
                        <div>15:00</div>
                   </div>

        }
        else if(matchStatus===2&&matchRoundInfo.event_id===5){
            const d=matchRoundInfo.created_at.split(' ').join('T')
            return  <div>
                       <Countdown date={ Date.parse(d)+900000} renderer={round1extra}/>
                    </div> 
        }
        else if(matchStatus===2&&matchRoundInfo.event_id===4){
            return <RadialTimer initialStart={45*60*1000} child={<div className='fs-xxs p-3'>
                        <div>نهاية الشوط الثاني</div>
                        <div>90:00</div>
                        <div>{matchRoundInfo.event_time.split('+')[1]} دقيقه وقت بدل ضائع</div>
                   </div>
                    }/>
        }
        else if(matchStatus===2&&matchRoundInfo.event_id===3){
            const d=matchRoundInfo.created_at.split(' ').join('T')
            return  <div>
                        <RadialTimer initialStart={(Date.parse(d)+2700000)-Date.now()} child={<Countdown date={Date.parse(d)+2700000} renderer={round2}/>}/>
                    </div> 
        }
        else if(matchStatus===2&&matchRoundInfo.event_id===2){
            return  <RadialTimer initialStart={45*60*1000} child={ 
                    <div className='fs-xxs p-3'>
                       <div>نهاية الشوط الاول</div>
                       <div>45:00</div>
                       <div>{matchRoundInfo.event_time.split('+')[1]} دقيقه وقت بدل ضائع</div>
                   </div>}/>
        }
        else if(matchStatus===2 || matchRoundInfo.event_id===1){
            
             const d=matchRoundInfo.created_at.split(' ').join('T')
            return  <div>
                         <RadialTimer initialStart={(Date.parse(d)+2700000)-Date.now()} child={<Countdown date={Date.parse(d)+2700000} renderer={round1}/>}/>
                    </div>
        }

    }
    const round1 = ({ hours, minutes, seconds, completed, api,formatted}) => {
        const minLeft=44
        if (completed) {
          // Render a completed state
          
          return <span>45:00</span>;
        } else { 
            
          // Render a countdown
        return  <div className='mt-3'>
                   
                    <div>الشوط الاول</div>
                    <div>{minLeft-parseInt(formatted.minutes)}:{60-parseInt(formatted.seconds)}</div>
                    <div> <span className='mx-1'>{team1.score}</span>-<span className='mx-1'>{team2.score}</span></div>
                </div>;
        }
      };
      const round1extra = ({ hours, minutes, seconds, completed, api,formatted}) => {
        const minLeft=14
        if (completed) {
          // Render a completed state
          
          return <span>15:00</span>;
        } else { 
            
          // Render a countdown
        return  <div>
                    <div>بدأت</div>
                    <div> الشوط الاول الاضافي</div>
                    <div>{minLeft-parseInt(formatted.minutes)}:{60-parseInt(formatted.seconds)}</div>
                </div>;
        }
      };
      const round2 = ({ hours, minutes, seconds, completed, api,formatted}) => {
        const minLeft=89
        if (completed) {
          // Render a completed state
          
          return <span>90:00</span>;
        } else { 
            
        return  <div className='mt-3'>
              
                    <div>الشوط الثاني</div>
                    <div>{minLeft-parseInt(formatted.minutes)}:{60-parseInt(formatted.seconds)}</div>
                    <div> <span className='mx-1'>{team1.score}</span>-<span className='mx-1'>{team2.score}</span></div>
                </div>;
        }
      };
      const round2extra = ({ hours, minutes, seconds, completed, api,formatted}) => {
        const minLeft=29
        if (completed) {
          // Render a completed state
          
          return <span>30:00</span>;
        } else { 
            
        return  <div>
                    <div>بدأت</div>
                    <div> الشوط الثاني الاضافي</div>
                    <div>{minLeft-parseInt(formatted.minutes)}:{60-parseInt(formatted.seconds)}</div>
                </div>;
        }
      };

    return (
        isFetchingMatchStatus ? <MatchHeaderLoader/>
        :
        <div className='match-header text-light font-light-ar p-4'>
     
            <div className='match-header-team-info p-3'>
                <div className='d-flex mt-1 justify-content-start'>
                    <div className='fs-xs d-none d-md-inline'>
                        <b>
                        <FontAwesomeIcon icon={faClock} size="lg"/>

                        </b>
                        <b> 
                        <span className='mx-1'> {getTime()}</span>
                        </b>
                    </div> 
                    <div className='fs-xs mx-2 mx-md-3'>
                        <b>
                        <img src='/icons/calendar.svg' width="25px" height='20px'/>
                        <span>{parseDate}</span>
                        </b>
                    </div>  
                    <div className='fs-xs '> <img src='/icons/stadium (1).svg' width="25px" height='20px'/> <b>{stadium.title}</b></div>
                </div> 
            <Row className="d-flex align-items-center justify-content-center fs-xm text-center mt-1 mt-md-4">
                <Col xs={4} className='d-flex justify-content-center align-items-center'>
                    <div>
                        <div onClick={() => handleRowClick(team1.id)} style={{cursor:"pointer"}}><img className='match-header-team-img 'src={`${REACT_APP_NEW_MEDIA_URL}${team1.logo}`}></img><div className='fs-sm text-center'>{team1.title}</div></div>
                        <div className='secondary-color-light fs-xs'>{team1.manager_name}<img src='/icons/manager.svg' className="mx-2"></img></div>
                    </div>
                    {/* <div className='fs-md match-header-score  mr-3 pt-1 pt-md-2'>
                        {team1.score}
                    </div> */}
                </Col>
                <Col xs={4} md={4} className='p-2'>
                <div className='fs-md d-flex justify-content-center'>  
                {
                    getTimer()
                    
                }
                
                </div>
                </Col>
                <Col xs={4} className='d-flex justify-content-center align-items-center'>
                    {/* <div className='fs-md match-header-score ml-3 pt-1 pt-md-2'>{team2.score}</div> */}
                    <div>
                        <div  onClick={() => handleRowClick(team2.id)} style={{cursor:"pointer"}}><img className='match-header-team-img' src={`${REACT_APP_NEW_MEDIA_URL}${team2.logo}`}></img><div className='fs-sm'>{team2.title}</div></div>
                        <div className='secondary-color-light fs-xs'>{team2.manager_name}<img src='/icons/manager.svg' className="mx-2"></img></div>
                    </div>
                </Col>
            </Row>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    stadium:state.matchInfo.matchStatus[0].stadium || '',
    team1:state.matchInfo.matchStatus[0].team1,
    team2:state.matchInfo.matchStatus[0].team2,
    isFetchingMatchStatus:state.matchInfo.isFetchingMatchStatus,
    statusString:state.matchInfo.matchStatus[0].status,
    matchStatus:state.matchInfo.matchStatus[0].matchStatus,
    date:state.matchInfo.matchStatus[0].date,
    matchRoundInfo:state.matchInfo.matchRoundInfo
})
const mapDispatchToProps = dispatch => ({
    getMatchInfo: (matchId) => dispatch(getMatchInfo(matchId)),
})


export default connect(mapStateToProps,mapDispatchToProps)(MatchHeader)