import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useParams } from "react-router";
import { Container } from "reactstrap";
import { connect } from "react-redux";
import { getMatches } from "../../redux/Matches/Matches.Actions";
import Select from "react-select";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getName } from "../../globalFn";
import { convertFullDate } from "../../globalFn";
import allCompetitionsImg from "../../assets/allCompetions.png";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./Matches.css";
import ErrorPage from "../../components/ErrorPage/ErrorPage";
import LoadingSpinnner from "../../components/loadingSpinner/loadingSpinner";
import compoundAdd from "../../assets/adds/Compound-October-Plaza.png";
import IphoneAdd from "../../assets/adds/iphone add.webp";

// --bk-color-2

function MatchesPage({
  Matches,
  getMatches,
  MatchesFailed,
  isFetchingMatches,
  currentLanguageCode,
}) {
  const id = useParams().id;

  useEffect(() => {
    getMatches(1, null, currentLanguageCode);
  }, [currentLanguageCode]);

  const [value, onChange] = useState(new Date());

  const { REACT_APP_NEW_MEDIA_URL } = process.env;

  const { t } = useTranslation();

  const getTime = (el) => {
    if (el.date === null) {
      return <div>وقت غير محدد</div>;
    } else {
      return el.date.split(" ")[1].split(":").slice(0, 2).join(":");
    }
  };

  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  return (
    <div>
      <h3 className="all-competions-header text-center p-4 text-light">
        <strong>{t("mainNavBar.Matches")}</strong>
        <span className="competitions-overlay"></span>
      </h3>

      <Container>
        <div className="row d-flex mt-5 justify-content-center">
          <div className="col-10 col-md-4 my-3">
            <Calendar
              onChange={(e) => getMatches(1, convert(e), currentLanguageCode)}
              value={value}
              className="bg-primary-color"
              defaultValue={
                new Date(
                  "Tue Mar 11 2021 00:00:00 GMT+0100 (Central European Standard Time)"
                )
              }
            />
            <img src={compoundAdd} width={"100%"} className="mt-3" />
          </div>

          {MatchesFailed !== null ? (
            <div className="col-12 col-md-8">
              <ErrorPage error={t("Error.MatchesError")} />
            </div>
          ) : isFetchingMatches ? (
            <div className="col-12 mx-auto col-md-8">
              <div className="mb-2">
                <div className="fs-md secondary-color-light mb-2">
                  {t(`MatchesCalendar.Fixtures`)}
                </div>
                <LoadingSpinnner p={3} fs={"md"} />
              </div>
              <div>
                <div className="fs-md secondary-color-light mb-2">
                  {t(`MatchesCalendar.Results`)}
                </div>
                <LoadingSpinnner p={3} fs={"md"} />
              </div>
            </div>
          ) : Matches.length === 0 ? (
            <div className="my-5">{t(`Error.MatchesError`)}</div>
          ) : (
            <div className="col-12 mx-auto col-md-8">
              {Matches.map((tour) => (
                <div className="my-3">
                  <div className="bg-secondary-color p-2 secondary-color-light">
                    {tour.title}
                  </div>
                  {tour.matches.length === 0 ? (
                    <div className="p-5 secondary-color-light bg-primary-color">
                      {t(`Error.MatchesError`)}
                    </div>
                  ) : (
                    tour.matches.map((el) => (
                      <Link to={`/match/${el.id}`}>
                        <Col
                          xs={12}
                          className="secondary-color-light border-bottom border-secondary bg-primary-color py-3"
                        >
                          <Row className="ms-0">
                            <Col
                              xs={4}
                              md={3}
                              lg={3}
                              className=" my-auto d-flex justify-content-start align-items-center "
                            >
                              <div className="match-carousel-rounded-white mx-2">
                                <img
                                  src={`${REACT_APP_NEW_MEDIA_URL}${el.team1.logo}`}
                                  width="30px"
                                />
                              </div>
                              <div className="text-nowrap fs-sm ">
                                {getName(el.team1.title, 12)}
                              </div>
                            </Col>
                            <Col
                              xs={4}
                              md={6}
                              lg={6}
                              className=" d-flex flex-column align-items-center"
                            >
                              <div className="bg-active-color px-4 px-md-5 py-1 py-md-2 text-nowrap text-light fs-xxs">
                                {" "}
                                {getTime(el)}
                              </div>
                              <div className="row d-flex flex-row justify-content-center">
                                <div className="col-1">{el.team1.score}</div>
                                <div className="col-3" />
                                <div className="col-1">{el.team2.score}</div>
                              </div>
                            </Col>
                            <Col
                              xs={4}
                              md={3}
                              lg={3}
                              className="my-auto d-flex justify-content-end align-items-center"
                            >
                              <div className=" text-nowrap fs-sm">
                                {getName(el.team2.title, 12)}
                              </div>
                              <div className="match-carousel-rounded-white mx-2">
                                <img
                                  src={`${REACT_APP_NEW_MEDIA_URL}${el.team2.logo}`}
                                  width="30px"
                                />
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Link>
                    ))
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </Container>
    </div>
  );
}

const mapStateToProps = (state) => ({
  MatchesFailed: state.Matches.MatchesFailed,
  isFetchingMatches: state.Matches.isFetchingMatches,
  Matches: state.Matches.Matches,
  currentLanguageCode: state.language.currentLanguageCode,
});
const mapDispatchToProps = (dispatch) => ({
  getMatches: (sport_id, start_date, currentLanguageCode) =>
    dispatch(getMatches(sport_id, start_date, currentLanguageCode)),
});
export default connect(mapStateToProps, mapDispatchToProps)(MatchesPage);
