import React,{ useEffect } from 'react';
import { connect } from 'react-redux';
import { getTeamStatistics } from '../../redux/TeamStatistics/TeamStatistics.Actions';
import { Row,Col, Container } from 'reactstrap';
import { PieChart } from 'react-minimal-pie-chart';
import { useTranslation } from "react-i18next"
import TeamStatChart from './TeamStatChart';
import TeamOverviewStats from './TeamOverviewStats';
import TopScorers from '../topScorers/topScorers'
import TopAssists from '../topAssists/topAssists'
import ErrorMsg from '../ErrorPage/ErrorMsg';
import LoadingSpinnner from '../loadingSpinner/loadingSpinner';
import Comparison from '../Comparison/Comparison'



const TeamDetailStats = ({ TeamStatistics , TeamDetails, isFetchingTeamStatistics ,   isFetchingTeamDetails , TeamStatisticsFailed ,  TeamDetailsFailed, sport_id,season_id,tournament_id,team_id, currentLanguageCode,getTeamStatistics ,toLocalTab}) => {


    const { t } = useTranslation()


   useEffect(()=> {
      getTeamStatistics(sport_id,season_id,tournament_id,team_id,currentLanguageCode)
   },[sport_id,season_id,tournament_id,team_id,currentLanguageCode])

   const statisticsKeys={
    played:t(`StatsTable.Played`),
    win: t(`StatsTable.Wins`),
    lose: t(`StatsTable.Losses`),
    draw: t(`StatsTable.Draw`),
    goals_in: t(`StatsTable.GoalsAgainst`),
    goals_out: t(`StatsTable.GoalsFor`),
    goals_penalty: t(`StatsTable.GoalsPenalty`),
    clean_sheet: t(`StatsTable.CleanSheet`),
    yellow_cards: t(`StatsTable.YellowCards`),
    red_cards: t(`StatsTable.RedCards`),
    difference: t(`StatsTable.GoalsDifference`),
    point: t(`StatsTable.Points`),
    passes: t(`StatsTable.passes`),
    lost_balls: t(`StatsTable.LostBalls`),
    attempts: t(`StatsTable.Attempts`),
    chances: t(`StatsTable.Chances`),
    dribbles: t(`StatsTable.Dribbles`),
    fouls_in: t(`StatsTable.FoulsIn`),
    fouls_out: t(`StatsTable.FoulsOut`),
    ball_win: t(`StatsTable.BallWin`),
    ball_lose: t(`StatsTable.BallLose`),
}



    return (
        <Container>
        <Row>
          <Col xs={12} md={4}>
              {
                TeamStatisticsFailed !== null ?
                <ErrorMsg p={5} fs={'md'} msg={'Error.StatisticsError'}/>
                :
                isFetchingTeamStatistics   ?
                <LoadingSpinnner p={5} fs={'md'}/>
                :
                <div>
                    <TeamStatChart TeamStatistics={TeamStatistics}/>
                    <div className='bg-primary-color mt-3 fs-sm  p-4 d-flex justify-content-around text-light'>
                        <div>
                            <span className='mx-2'>{TeamStatistics.red_cards}</span>
                            <span style={{backgroundColor:'red',padding:'7px',color:'transparent'}}>1</span>
                            <span className="fs-sm">{t(`StatsTable.RedCards`)}</span>
                        </div>
                        <div>
                            <span className='mx-2'>{TeamStatistics.yellow_cards}</span>
                            <span style={{backgroundColor:'yellow',padding:'7px',color:'transparent'}}>1</span>
                            <span className="fs-sm">{t(`StatsTable.YellowCards`)}</span>
                        </div>
                    </div>
                </div>
              }
            <div className="col-12 ml-auto mr-auto  mt-4 mx-md-0 text-center  fs-xs p-3" style={{height:'300px'}}>
            <Comparison />
            </div>
          </Col>
          <Col xs={12} md={4}>
             <TeamOverviewStats sport_id={1} season_id={null} tournament_id={1} team_id={team_id}  toLocalTab={toLocalTab}/>
             <div className='bg-primary-color secondary-color-light mt-3 p-3'>
                 <div className='p-1'>{t(`StatsTable.ScoringIntervals`)}</div>
                 {
                    TeamStatisticsFailed !== null ?
                    <ErrorMsg p={5} fs={'md'} msg={'Error.StatisticsError'}/>
                    :
                    isFetchingTeamStatistics   ?
                    <LoadingSpinnner p={5} fs={'md'}/>
                    :
                    <div className='row '>
                    <div className='col-3'>
                        {
                          Object.keys(TeamStatistics.scoringIntervals).map((el,i) => 
                          <div className='mt-1 fs-sm p-1'>
                              {i*15} - {el}'
                          </div>
                          )
                        }
                    </div>
                    <div className='col-9'>
                        {
                           Object.values(TeamStatistics.scoringIntervals).map((el,i) => 
                               <div style={{width:`${el}%`, backgroundColor:'var(--active-color)'}} className='mt-2 p-1 fs-xs text-light'>
                                   <strong>
                                   {el}
                                   </strong>
                                   %
                               </div>
                               )
                        }
                    </div>
                </div>
                 }
             </div>

          </Col>
          <Col xs={12} md={4}>
              {
                isFetchingTeamDetails || TeamDetailsFailed !== null ?
                <div>
                    <div className="p-2 fs-md bg-primary-color secondary-color-light ">{t('General.topScorer')}</div> 
                    <LoadingSpinnner p={5} fs={'sm'}/>
                </div> 
                    : 
                <TopScorers  sport_id={1} season_id={season_id} tournament_id={TeamDetails.main_tournament !== null ? TeamDetails.main_tournament.id : ''} team_id={team_id}/>
              }

              {
                isFetchingTeamDetails || TeamDetailsFailed !== null ?
                <div className='mt-2'>
                    <div className='bg-primary-color secondary-color-light p-2'>{t('TournamentDetails.Table')}</div>
                    <LoadingSpinnner p={5} fs={'sm'}/>
                </div>
                    :
                <TopAssists  sport_id={1} season_id={season_id} tournament_id={TeamDetails.main_tournament !== null ? TeamDetails.main_tournament.id : ''} team_id={team_id}/>
              }
          </Col>  
        </Row>
        </Container>
    )
}

const mapStateToProps = state =>({
    TeamDetailsFailed:state.teamDetails.TeamDetailsFailed,
    TeamDetails: state.teamDetails.TeamDetails,
    isFetchingTeamDetails:state.teamDetails.isFetchingTeamDetails,
    TeamStatistics:state.teamStatistics.TeamStatistics,
    isFetchingTeamStatistics:state.teamStatistics.isFetchingTeamStatistics,
    TeamStatisticsFailed:state.teamStatistics.TeamStatisticsFailed,
    currentLanguageCode:state.language.currentLanguageCode,
})

const mapDispatchToProps = dispatch =>({
    getTeamStatistics:(sport_id,season_id,tournament_id,team_id,lang)=> dispatch(getTeamStatistics(sport_id,season_id,tournament_id,team_id,lang))
})

export default connect(mapStateToProps,mapDispatchToProps)(TeamDetailStats)