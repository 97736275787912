import { Link } from "react-router-dom";
import React from "react";
import { Col, Container, Row } from "reactstrap";
import NewsLoading from "./NewsLoader";
import { convertDate } from "../../globalFn";
import { connect } from 'react-redux';
import { getMedisList,addMedisList } from "../../redux/MediaList/MediaList.Action";
import { GoCalendar } from "react-icons/go";
import NewsCarousel from "./NewsCarousel";
import { useTranslation } from "react-i18next"
import './newsSection.css'
import Moment from "moment";
import { useEffect } from "react";
import NewsCard from "./newsCard";


function AllNews({  isFetchingAllNews , addingAllNews , AllNews_CurrentPage , AllNews_LastPage , AllNews ,  AllNewsFailed , currentLanguageCode , getMedisList , addMedisList ,type , sport_id , season_id , items_count ,media_type , tournament_id, media_id, team_id , player_id , topStory , latest , popular}) {

  useEffect(()=> {
    // this is the params in order  (sport_id , season_id , items_count ,Media_type, tournament_id, media_id, team_id , player_id , topStory , latest , popular , lang)
    getMedisList(sport_id , season_id , items_count ,media_type , tournament_id, media_id, team_id , player_id , topStory , latest , popular , currentLanguageCode)
  }
  ,[currentLanguageCode])

  const { t } = useTranslation()

    return (
      AllNewsFailed !== null ?
      <div className='p-5 bg-secondary-color fs-lg text-light text-center'>{t(`Error.NewsError`)}</div>
      :
      isFetchingAllNews ? 
       <NewsLoading /> 
       :
       AllNews.length === 0 ?
       <div className='p-5 bg-secondary-color fs-lg text-light text-center'>{t(`Error.NewsError`)}</div>
       : 
        <Container fluid="md">
          <div className="news-section">
            <div className='row'>
              {
                  AllNews.map(news=> 
                    <div className='col-4 col-md-3 p-1'>
                        <NewsCard news={news} type={1}/>
                    </div>)
              }
            </div>
            <div className='text-center mt-3'>
                <button className='btn bg-active-color text-light fs-sm' onClick={()=> addMedisList(sport_id , season_id , items_count ,media_type , tournament_id, media_id, team_id , player_id , topStory , latest , popular , AllNews_CurrentPage+1 , currentLanguageCode)} disabled={AllNews_CurrentPage >= AllNews_LastPage}>
                  {
                    addingAllNews ? 
                      <div class="spinner-border text-light fs-xs p-0" role="status">
                          <span class="sr-only">Loading...</span>
                      </div> 
                      :
                      t(`News.LoadMore`) 
                  }
                </button>
            </div>
          </div>
        </Container>
    );
}

const mapStateToProps = state =>({
  isFetchingAllNews:state.MediaList.isFetchingAllNews,
  addingAllNews:state.MediaList.addingAllNews,
  AllNews_CurrentPage:state.MediaList.AllNews_CurrentPage,
  AllNews_LastPage:state.MediaList.AllNews_LastPage,
  AllNews:state.MediaList.AllNews,
  AllNewsFailed:state.MediaList.AllNewsFailed,
  currentLanguageCode:state.language.currentLanguageCode
})

const mapDispatchToProps = (dispatch) => ({
  getMedisList: (sport_id , season_id , items_count ,Media_type, tournament_id, media_id, team_id , player_id , topStory , latest , popular , lang) =>
    dispatch(getMedisList(sport_id , season_id , items_count , Media_type, tournament_id, media_id, team_id , player_id , topStory , latest , popular , lang)),

  addMedisList: (sport_id , season_id , items_count ,Media_type, tournament_id, media_id, team_id , player_id , topStory , latest , popular , page , lang) =>
  dispatch(addMedisList(sport_id , season_id , items_count ,Media_type, tournament_id, media_id, team_id , player_id , topStory , latest , popular , page , lang))
});

export default connect(mapStateToProps,mapDispatchToProps)(AllNews);