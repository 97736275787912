import React,{ useEffect } from 'react'
import { getSeasons } from '../../redux/Seasons/Seasons.Actions'
import { setActiveSeason } from '../../redux/Seasons/Seasons.Actions'
import { connect } from 'react-redux'
import { Dropdown } from 'react-bootstrap'

const SeasonsDropdown =({getSeasons , seasons , activeSeason , isFetchingSeasons , setActiveSeason})=>{
    
    // useEffect(()=> getSeasons()
    // ,[])
   
    return (
            isFetchingSeasons ? 
            <Dropdown>
              <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                  ...Loading
              </Dropdown.Toggle>
            </Dropdown>
            :
            <Dropdown>
               <Dropdown.Toggle variant="secondary" id={`dropdown-button-drop-down`} drop='down'>
                       {activeSeason.title}
               </Dropdown.Toggle>

               <Dropdown.Menu>
                   {
                       seasons.map(season => <div key={season.id}><Dropdown.Item className='text-light' onClick={()=> setActiveSeason(season)} >{season.title}</Dropdown.Item></div>)
                   }
               </Dropdown.Menu>
            </Dropdown>
    )
}
const mapDispatchToProps = dispatch =>({
    getSeasons:()=> dispatch(getSeasons()),
    setActiveSeason:(info)=> dispatch(setActiveSeason(info))
})

const mapStateToProps = state =>({
    seasons:state.seasons.Seasons,
    activeSeason:state.seasons.activeSeason,
    isFetchingSeasons:state.seasons.isFetchingSeasons
})

export default connect(mapStateToProps,mapDispatchToProps)(SeasonsDropdown)