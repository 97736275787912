import React , { useEffect,useState } from 'react'
import { connect } from 'react-redux'
import { useParams } from "react-router-dom";
import { getStanding } from "../../redux/Standing/Standing.Actions";
import { getGeneralStatistics } from '../../redux/GeneralStatistics/GeneralStatistics.Actions'
import { setCurrentlyDisplayedGeneralStanding } from '../../redux/GeneralStatistics/GeneralStatistics.Actions'
import { addH2Hteam } from '../../redux/Standing/Standing.Actions'
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom";
import Language from '../../redux/language/language.Reducer'
import { Container } from 'react-bootstrap';
import './TournamentStatistics.css'
import SeasonsDropdown from '../SeasonsDropdown/SeasonsDropdown';
import ErrorMsg from '../ErrorPage/ErrorMsg';
import LoadingSpinnner from '../loadingSpinner/loadingSpinner';



const TourStandingDetails = ({ Standing ,standingFailed,  isFetchingStanding , currentLanguageCode ,  getStanding  ,sport_id , season_id }) => {


    const id = useParams().id;
    
    useEffect(()=> {
        getStanding(sport_id , season_id  ,id,currentLanguageCode)
    },[sport_id  ,  season_id  ,id ,currentLanguageCode])

    const { t } = useTranslation()
    const history = useHistory(); 
    const handleRowClick = (id) => {
        history.push(`/team/${id}`);
      }  


    return (
    <Container>  
    <div className='bg-primary-color secondary-color-light fs-xs mt-3 row'>
        <table class="tournament-statistics table-borderless bg-primary-color table-striped table-dark secondary-color-light col-12">
        <thead>
            <tr style={{padding:'10px'}} className="text-center">
            <th className='d-none d-md-table-cell'>{t(`StatsTable.Position`)}</th>
            <th scope="col" className={window.document.body.dir === 'rtl' ? 'text-right': 'text-left'}>{t(`StatsTable.Club`)}</th>
            <th scope="col" className='d-none d-md-table-cell'>{t(`StatsTable.GoalsFor`)}</th>       
            <th scope="col" className='d-none d-md-table-cell'>{t(`StatsTable.GoalsPenalty`)}</th>
            <th scope="col" className='d-none d-md-table-cell'>{t(`StatsTable.Attempts`)}</th>
            <th scope="col" className='d-none d-md-table-cell'>{t(`StatsTable.Chances`)}</th>
            <th scope="col" className='d-none d-md-table-cell'>{t(`StatsTable.Dribbles`)}</th>
            <th scope="col" className='d-none d-md-table-cell'>{t(`StatsTable.passes`)}</th>
            <th scope="col">{t(`StatsTable.BallWin`)}</th>
            <th scope="col">{t(`StatsTable.BallLose`)}</th>
            <th scope="col">{t(`StatsTable.FoulsOut`)}</th>
            <th scope="col">{t(`StatsTable.FoulsIn`)}</th>
            </tr>
        </thead>
        <tbody>
            {
                standingFailed !== null ?
                <tr>
                    <td colspan={15} className='p-0'>
                        <ErrorMsg msg={t('Error.StatisticsError')} p={5} fs={'lg'} />
                    </td>
                </tr>
                :
                isFetchingStanding ?
                <tr>
                <td colspan={15} className='p-0'>
                    <LoadingSpinnner p={4} fs={'md'}/>
                </td>
                </tr>
                :
                Standing.length === 0 ?
                <tr>
                <td colspan={15} className='p-0'>
                    <ErrorMsg msg={t('Error.StatisticsError')} p={5} fs={'lg'} />
                </td>
                </tr>
                :
                Standing.map(
                    (team,i) =>        
                    <tr  key={team.id} onClick={()=> handleRowClick(team.team_id)} className='table-clickable-row text-center'>
                    <td style={{width:'25px'}} className='d-none d-md-table-cell'>{i+1}</td>
                    <td scope="row" className={window.document.body.dir === 'rtl' ? 'text-right team-name': 'text-left team-name'}><img src={process.env.REACT_APP_NEW_MEDIA_URL+team.team_logo} style={{width:'20px'}}/><span className="mx-2 fs-xxs ">{team.team_name}</span></td>
                    <td className='d-none d-md-table-cell'>{team.goals_out}</td>
                    <td className='d-none d-md-table-cell'>{team.goals_penalty}</td>
                    <td className='d-none d-md-table-cell'>{team.attempts}</td>
                    <td className='d-none d-md-table-cell'>{team.chances}</td>
                    <td className='d-none d-md-table-cell'>{team.dribbles}</td>
                    <td className='d-none d-md-table-cell'>{team.passes}</td>
                    <td>{team.ball_win}</td>
                    <td>{team.ball_lose}</td>
                    <td>{team.fouls_out}</td>
                    <td>{team.fouls_in}</td>
                    </tr>
                
                    )
            }

        </tbody>
        </table>
    </div>
    </Container>   
    )
}


const mapDispatchToProps = (dispatch) => ({
    getStanding: (sport_id , season_id ,tournament_id ,lang) => dispatch(getStanding(sport_id , season_id  ,tournament_id ,lang)),
    addH2Hteam:(info)=> dispatch(addH2Hteam(info))
  });
  
  const mapStateToProps = (state) => ({
    isFetchingStanding: state.standing.isFetchingStanding,
    standingFailed:state.standing.StandingFailed,
    Standing:state.standing.Standing,
    currentLanguageCode:state.language.currentLanguageCode,
    tournamentDetails: state.standing.TournamentDetails,
    H2HTeams:state.standing.H2HTeams,
    showModal:state.standing.showModal,
    activeSeason:state.seasons.activeSeason,
  });
  
  

export default connect(mapStateToProps,mapDispatchToProps)(TourStandingDetails)