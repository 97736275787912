import SeasonsTypes from "./Seasons.Types"; 
import axios from 'axios'

const { REACT_APP_NEW_BASE_URL , REACT_APP_NEW_GET_SEASONS   } = process.env

const getSeasonsStart = () => ({
    type:SeasonsTypes.GET_SEASONS_START
})

const getSeasonsSuccess = (info) => ({
    type:SeasonsTypes.GET_SEASONS_SUCCESS,
    payload:info
})

const getSeasonsFailed = (error) => ({
    type:SeasonsTypes.GET_SEASONS_FAILED,
    payload:error
})

const setActiveSeason = (info) =>({
    type:SeasonsTypes.SET_ACTIVE_SEASON,
    payload:info
})


const getSeasons = () => (
    dispatch => {
        dispatch(getSeasonsStart())
        axios({
              method: "get",
              url: `${REACT_APP_NEW_BASE_URL}/seasons`,
              headers: {
                "Content-Type": "application/json",
                "Accept-Language":`ar`
              },
            }).then(resp => {   
              if (resp.data.status===200)
              { 
                 dispatch(getSeasonsSuccess(resp.data))
              }
              else {
                dispatch(getSeasonsFailed(resp.data.message))
              }}
              )
              .catch(error => dispatch(getSeasonsFailed(error)))
     
      }
)

export { getSeasons , setActiveSeason }