import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import { getSeasons } from '../../redux/Seasons/Seasons.Actions'
import { Dropdown } from 'react-bootstrap'
import { useParams } from "react-router-dom";
import MatchesCalendar from '../MatchesCalendar/MatchesCalendar'
import { setActiveSeason } from '../../redux/Seasons/Seasons.Actions'
import TournamentOverviewStats from '../TournamentStatistics/TournamentOverviewStats'
import TournamentOverviewStand from '../TournamentStanding/TournamentOverviewStand'
import TopScorers from '../topScorers/topScorers'
import TopAssists from '../topAssists/topAssists'
import LatestNews from '../News/LatestNews'
import { useTranslation } from "react-i18next"
import SportsSectionHeader from '../sportsSectionHeader/sportsSectionHeader.component'
import LatestVids from '../News/LatestVids'
import TopScorer from '../topScorers/topScorer'
import TeamOverviewStats from '../TeamStatistics/TeamOverviewStats'
import LoadingSpinnner from '../loadingSpinner/loadingSpinner'

const TeamOverview =({ season_id , toLocalTab ,  TeamDetails ,  isFetchingTeamDetails , TeamDetailsFailed , })=>{

    const id = useParams().id;
    const { t } = useTranslation()

    return (
        <Container>
            <MatchesCalendar sport_id={1} season_id={season_id} team_id={id}/>
            <Row>
                <Col xs={12} md={8}>
                    <div className='bg-primary-color p-2'>
                       <div className='secondary-color-light fs-xm'>{t(`News.latestNews`)}</div>
                       <LatestNews  sport_id={1} season_id={null} tournament_id={null} media_type={1} team_id={id} items_count={6} mini={true} xs={6} md={4} latest={true}/>
                    </div>
                    <div className='bg-primary-color p-2'>
                       <div className='secondary-color-light fs-xm'>{t(`News.latestVids`)}</div>
                       <LatestVids  sport_id={1} season_id={null} tournament_id={null} media_type={2} team_id={id} items_count={6} mini={true} xs={6} md={4} latest={true}/>
                    </div>
                </Col>
                <Col xs={12} md={4}>
                    <div className='mt-2 mt-md-0'>
                        {
                            isFetchingTeamDetails || TeamDetailsFailed !== null ?
                            <div>
                                <div className="p-2 fs-md bg-primary-color secondary-color-light ">{t('General.topScorer')}</div> 
                                <LoadingSpinnner p={5} fs={'sm'}/>
                            </div> 
                            :
                            <TopScorer sport_id={1} season_id={season_id} tournament_id={TeamDetails.main_tournament !== null ? TeamDetails.main_tournament.id : null }  team_id={id} />
                        }
                    </div>
                    {
                        isFetchingTeamDetails || TeamDetailsFailed !== null ?
                        <div className='mt-2'>
                            <div className='bg-primary-color secondary-color-light p-2'>{t('TournamentDetails.Table')}</div>
                            <LoadingSpinnner p={5} fs={'sm'}/>
                        </div>
                        :
                        <TournamentOverviewStand  toLocalTab={toLocalTab}  sport_id={1} season_id={season_id} tournament_id={TeamDetails.main_tournament !== null ? TeamDetails.main_tournament.id : null} team_id={id}/>
                    }
                    <TeamOverviewStats sport_id={1} season_id={null} tournament_id={null} team_id={id}  toLocalTab={toLocalTab}/>
                   
                </Col>
            </Row>
        </Container>
    )
}

const mapStateToProps = (state) => ({
    TeamDetailsFailed:state.teamDetails.TeamDetailsFailed,
    TeamDetails: state.teamDetails.TeamDetails,
    isFetchingTeamDetails:state.teamDetails.isFetchingTeamDetails,
    activeSeason:state.seasons.activeSeason,
    currentLanguageCode:state.language.currentLanguageCode,
    });
      
export default connect(mapStateToProps)(TeamOverview);
