import React from 'react'
import "./MatchesCarouselComponent.css"
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useParams } from 'react-router';
import { Container } from 'reactstrap';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getName } from '../../globalFn'
import { convertFullDate } from '../../globalFn'




function SampleNextArrow(props) {
  const { className, style, onClick } = props;

  return (
    <button  onClick={onClick} style={{...style,fontSize: "20px", display: 'block', background: "var(--bk-color-2)", borderRadius:"6px",border: "unset" ,position: "absolute", top: 0, height: "100%", right: "-14px", zIndex:"15",width:" 22px", opacity:"1", color:"var(--tertiary-color)"}} >
      <i className="fa fa-chevron-right" aria-hidden="true"></i>
   </button>
    
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;

  return (
    <button  onClick={onClick} style={{...style,fontSize: "20px", display: 'block', background: "var(--bk-color-2)", borderRadius:"6px", border: "unset" ,position: "absolute", top: 0, height: "100%", left: "-14px", zIndex:"15", width:" 22px", opacity:"1", color:"var(--tertiary-color)"}} >
      <i className="fa fa-chevron-left" aria-hidden="true"></i>
    
    </button>
  );
}
function MatchesCarouselComponent({ matches }) {

  const id = useParams().id;
  const { REACT_APP_NEW_MEDIA_URL  } = process.env
    const settings = {
      infinite: false,
      speed: 900,
      slidesToShow: 3.8,
      slidesToScroll: 1,
      rtl:false,
      accessibility: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1000,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
      };

      const getDate = (el) => {
        if(el.date === null){
            return <div>معاد غير محدد</div>
        }
        else {
            return convertFullDate(el.date.split(' ')[0],'ar')
        }
    }

    const getTime = (el) =>{
        if(el.date === null ){
            return <div>وقت غير محدد</div>
        }
        else {
            return  el.date.split(' ')[1].split(':').slice(0,2).join(':')
        }
            
    }
    return (
        <div className="matchs-carousel">
        <Slider {...settings}>
            {
              matches.length === 0 ? 
              <div className='my-5 text-light text-center'>لا يوجد مباريات </div>
              :
               matches.map(el => 
                <Link to={`/match/${el.id}`}>
                <div className="text-center  match-slide bg-primary-color py-2" key={el.id}>
  
                    <Col xs={12} className="text-light border-left border-right border-secondary">
                        <Row className='ms-0'>
                            <Col   xs={4} md={3} lg={3} className=" my-auto d-flex justify-content-around align-items-center ">
                            <div className='d-flex flex-column align-items-start'>
                            <div className='match-carousel-rounded-white'>  
                              <img src={`${REACT_APP_NEW_MEDIA_URL}${el.team1.logo}`} width="20px"/>
                            </div>   
                              <div className="text-nowrap fs-xs mt-2">{getName(el.team1.title,12)}</div>
                            </div>
                           
                            </Col>
                            <Col  xs={4}md={6} lg={6} className=" d-flex flex-column align-items-center">
                      
                            <div className="bg-active-color px-4 px-md-5 py-1 py-md-2 text-nowrap text-light fs-xxs"> { getTime(el) }</div>
                            <div className='row d-flex flex-row justify-content-between'>
                                <div className='col-1'>{el.team1.score}</div>
                                <div className='col-3'/>
                                <div className='col-1'>{el.team2.score}</div>
                            </div>
                            </Col>
                            <Col  xs={4} md={3} lg={3} className="my-auto d-flex justify-content-around align-items-center">
                              <div className='d-flex flex-column align-items-end'>
                                <div className='match-carousel-rounded-white'>
                                <img src={`${REACT_APP_NEW_MEDIA_URL}${el.team2.logo}`} width="20px" />
                                </div>
                                <div className=" text-nowrap fs-xs mt-2">{getName(el.team2.title,12)}</div>
                              </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} className="d-flex mt-2 justify-content-center">
                        <h6 className="mx-2 my-auto fs-sm secondary-color-light">{el.tournament.title}</h6>
                    </Col>
        
                </div>
                </Link>
                
                )
            }
        </Slider>
        </div>
    )
}


export default MatchesCarouselComponent