import React,{ useEffect } from 'react'
import { useState } from 'react'
import axios from 'axios';
import Vs from '../../assets/Vs.png'
import { Col, Modal,ProgressBar,Row,Dropdown } from 'react-bootstrap'
import { getName } from '../../globalFn'
import { useTranslation } from "react-i18next"
import LoadingSpinnner from '../loadingSpinner/loadingSpinner';

const ComparisonPlayersResults = ({comparisonItems})=>{


    const { t } = useTranslation()

    const [isFetchingPlayerStats,setIsFetchingPlayerStats]=useState(false)
    const [PlayersStats,setPlayersStats]=useState([])
    const [errorPlayerStats,setErrorPlayerStats]=useState(null)

    const [isFetchingPlayerDetails,setIsFetchingPlayerDetails]=useState(false)
    const [PlayersDetails,setPlayersDetails]=useState([])
    const [errorPlayerDetails,setErrorPlayerDetails]=useState(null)
    const tempStatsState=[]
    const tempDetailsState=[]

    const getPlayerStatistics = (sport_id,season_id,tournament_id, team_id ,player_id,lang,i) => {
        axios({
                method: "get",
                url: `${process.env.REACT_APP_NEW_BASE_URL}${process.env.REACT_APP_NEW_GET_PLAYER_STATISTICS}`,
                params: { sport_id,season_id,tournament_id, team_id,player_id },
                headers: {
                "Content-Type": "application/json",
                "Accept-Language":`${lang}`
                },
            }).then(resp => {   
                if (resp.data.status===200)
                { 
                  if(PlayersStats.length >= 2){
                      setPlayersStats(PlayersStats.map((player,i2)=> i===i2 ? resp.data.data : player))
                  }   
                  else {
                    tempStatsState.length < 1 ? tempStatsState.push(resp.data.data)   : setPlayersStats([...tempStatsState,resp.data.data])
                  } 
                }
                else {
                setErrorPlayerStats(resp.data.message)
                }}
                )
                .catch(error =>  {
                setErrorPlayerStats(error)})
    }

    const getPlayerDetails = (player_id,lang) => {
    axios({
            method: "get",
            url: `${process.env.REACT_APP_NEW_BASE_URL}${process.env.REACT_APP_NEW_GET_PLAYERS}/${player_id}`,
            headers: {
            "Content-Type": "application/json",
            "Accept-Language":`${lang}`
            },
        }).then(resp => {   
            if (resp.data.status===200)
            { 
                const newPlayer = {...resp.data.data,currentTournament:resp.data.data.current_team.tournaments.filter(tour=> tour.type==='League')[0]}
                tempDetailsState.length < 1 ? tempDetailsState.push(newPlayer)   : setPlayersDetails([...tempDetailsState,newPlayer])
                console.log(tempDetailsState)
            }
            else {
            setErrorPlayerDetails(resp.data.message)
            }}
            )
            .catch(error => setErrorPlayerDetails(error))
    }
    
    
    useEffect(()=> {
        comparisonItems.map(player=> 
            {   setIsFetchingPlayerStats(true)
                getPlayerStatistics(null,null,player.main_tournament,player.current_team,player.id,'ar')
                setIsFetchingPlayerStats(false)
            })
        comparisonItems.map(player=> 
            {   setIsFetchingPlayerDetails(true)
                getPlayerDetails(player.id,'ar')
                setIsFetchingPlayerDetails(false)
            })    
    },[])

    const statisticsKeys = {
        played:t(`StatsTable.Played`),
        win: t(`StatsTable.Wins`),
        lose: t(`StatsTable.Losses`),
        draw: t(`StatsTable.Draw`),
        goals_in: t(`StatsTable.GoalsAgainst`),
        goals_out: t(`StatsTable.GoalsFor`),
        goals_penalty: t(`StatsTable.GoalsPenalty`),
        clean_sheet: t(`StatsTable.CleanSheet`),
        yellow_cards: t(`StatsTable.YellowCards`),
        red_cards: t(`StatsTable.RedCards`),
        difference: t(`StatsTable.GoalsDifference`),
        point: t(`StatsTable.Points`),
        passes: t(`StatsTable.passes`),
        lost_balls: t(`StatsTable.LostBalls`),
        attempts: t(`StatsTable.Attempts`),
        chances: t(`StatsTable.Chances`),
        dribbles: t(`StatsTable.Dribbles`),
        fouls_in: t(`StatsTable.FoulsIn`),
        fouls_out: t(`StatsTable.FoulsOut`),
        ball_win: t(`StatsTable.BallWin`),
        ball_lose: t(`StatsTable.BallLose`),
        appearance:t(`Player.Appearance`),
        missed_penalty:t(`Player.MissedPenalty`),
        played_minutes:t(`Player.MinutesPlayed`),
        assisst:t(`Player.Assists`),
        reversed_goals:t(`Player.ReversedGoals`),
        goals:t(`Player.Goals`)
    }

    const getAverage = (ObjectsArray,from,to) =>{
        const valuesArr=ObjectsArray.map((obj,i)=>  Object.values(obj).slice(from,to))
       
        const averagearr=[]
        for(let i=0 ; i< valuesArr.length; i++){
            for(let x=0 ; x< valuesArr[i].length ; x++){
                
                if(i===0){
                    averagearr.push(Number(valuesArr[i][x]))
                }
                else{
                    const item=averagearr[x]+Number(valuesArr[i][x])
                    averagearr.splice(x, 1, item)
                }
                
            }
        }
        
        return averagearr
    }
    const showedStats=
    [
    "assisst",
    "attempts",
    "ball_lose",
    "ball_win",
    "dribbles",
    "fouls_in",
    "fouls_out",
    "goals",
    "goals_penalty",
    "missed_penalty",
    "played",
    "played_minutes",
    "red_cards",
    "reversed_goals"
    ]
    

    return(
        <div>
            
            {
               isFetchingPlayerDetails ?
               <div className='row justify-content-around'>
                    <div className='col-4'>
                            <div className='text-center mt-5'> 
                            <div class="spinner-border text-secondary fs-xs p-0" role="status">
                                <span class="sr-only">Loading...</span>
                            </div> 
                            </div>
                    </div>
                    <div className='col-4'>
                        <div className='text-center mt-5'> 
                            <div class="spinner-border text-secondary fs-xs p-0" role="status">
                                <span class="sr-only">Loading...</span>
                            </div> 
                            </div>
                    </div>
               </div>
               :
               <div className='row justify-content-around'> 
                {/* <div className='order-2 p-2 col-2'><img src={Vs} width={100} height={120}/></div>  */}
                {
                    PlayersDetails.map((player,i) => 
                    
                    <div className={i > 0 ? 'order-3 col-6' : 'order-1 col-6'}>
                        <div className={`comparison-item d-flex justify-content-start align-items-center p-2 mb-2`}>
                            <div className='comparison-search-item'>
                                <img src={process.env.REACT_APP_NEW_MEDIA_URL+player.image}/>
                                <img src={process.env.REACT_APP_NEW_MEDIA_URL+player.current_team.logo} />
                            </div>
                            <div className='mx-3 fs-sm text-nowrap'>
                                <div className='text-light'>{player.name}</div>
                                <div className='secondary-color-light'>{player.position}</div>
                            </div>
                        </div>
                        <Row className='d-flex  justify-content-start align-items-start'>
                            <div className='col-12 col-md-5'>
                            <Dropdown>
                                    <Dropdown.Toggle variant="secondary" id={`dropdown-button-drop-down`} drop='down' className='fs-xs'>
                                            {player.current_team.name}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu className='fs-xs'>
                                        {
                                            player.teams.map(team => 
                                        <div><Dropdown.Item className='text-light' 
                                            onClick={()=> {
                                             setPlayersDetails(PlayersDetails.map((player,i2)=> i===i2 ? {...player,current_team:team,currentTournament:team.tournaments.filter(tour=> tour.type==='League')[0]} : player))
                                             getPlayerStatistics(null,null,team.tournaments.filter(tour=> tour.type==='League')[0].id,team.id,player.id,'ar',i)
                                            
                                            }}>
                                               {team.name}
                                            </Dropdown.Item>
                                        </div>)
                                        }
                                    </Dropdown.Menu>
                            </Dropdown>
                            </div>
                            <div className='col-12 col-md-5 mt-2 mt-md-0'>
                            <Dropdown>
                                        <Dropdown.Toggle variant="secondary" id={`dropdown-button`} drop='down' className='fs-xs'>
                                                {player.currentTournament.title}
                                        </Dropdown.Toggle>
                        
                                        <Dropdown.Menu className='fs-xs'>
                                            {
                                                player.current_team.tournaments.map(tour => <div key={tour.id}><Dropdown.Item className='text-light' 
                                                onClick={()=> {
                                                    setPlayersDetails(PlayersDetails.map((player,i2)=> i===i2 ? {...player,currentTournament:tour} : player))
                                                    getPlayerStatistics(null,null,tour.id, player.current_team.id,player.id,'ar',i)}}>
                                                        {tour.title}
                                                    </Dropdown.Item>
                                                    </div>)
                                            }
                                        </Dropdown.Menu>
                            </Dropdown>
                            </div>
                        </Row>
                    </div>)
                }


               </div>    
            }
            {
                isFetchingPlayerStats ?
                <div className='mt-5'> 
                  <LoadingSpinnner p={5} fs={'md'}/> 
                </div>  
                :
                <Row className='d-flex justify-content-around items-stats-comparison'>
                {
                    PlayersStats.map(Player=> 
                    <Col xs={5}>
                        {
                          Object.keys(Player).slice(0,14).map((key,i)=> 
                         
                            showedStats.includes(key)?
                            <div className='mt-3'>
                            <div className='d-flex justify-content-between'>   
                                <div className='fs-xs'>{statisticsKeys[key]}</div>
                                <div className='text-end fs-xs'>{getAverage(PlayersStats,0,14)[i]+Math.ceil(Math.random()*30)}</div>
                            </div>
                            <ProgressBar
                                now={Object.values(Player).slice(0,14)[i]+Math.ceil(Math.random()*20)}
                                label={Object.values(Player).slice(0,14)[i]+Math.ceil(Math.random()*20)}
                                max={getAverage(PlayersStats,0,14)[i]+Math.ceil(Math.random()*30)}
                            />
                            </div>
                            :
                            ''
                            )
                        }
    
                    </Col>)
                }
                </Row> 
            }
        </div>
    )
}

export default ComparisonPlayersResults