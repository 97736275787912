import React, { useEffect } from 'react'
import { getTopAssists } from '../../redux/topAssists/topAssists.Actions'
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import ErrorMsg from '../ErrorPage/ErrorMsg';
import LoadingSpinnner from '../loadingSpinner/loadingSpinner';

const TopAssists =({ getTopAssists, topAssists , isFetchingTopAssists , TopAssistsFailed , currentLanguageCode ,sport_id , season_id , tournament_id, team_id}) =>{
    
    const history = useHistory();

    const handleRowClick = (id) => {
      history.push(`/player/${id}`);
    }  
    const { t } = useTranslation() 


    useEffect(()=> 
    getTopAssists(sport_id , season_id , tournament_id, team_id,currentLanguageCode)
    ,[sport_id , season_id , tournament_id , team_id, currentLanguageCode])

    return (
        <div className='bg-primary-color secondary-color-light fs-xs mt-2'>
            <div className='d-flex justify-content-between fs-md p-2'>
              <div>{t('General.topAssists')}</div>
            </div>
            <table class="table-borderless bg-primary-color table-dark secondary-color-light">
            <thead>
                <tr className='text-center'>
                <th scope="col"></th>
                <th scope="col">{t(`StatsTable.Played`)}</th>
                <th scope="col">{t(`Player.Assists`)}</th>
                </tr>
            </thead>
            <tbody>
                {
                    TopAssistsFailed !== null?
                    <tr>
                        <td colspan={3} className='p-0'>
                            <ErrorMsg msg={t('Error.GeneralError')} p={5} fs={'md'} />
                        </td>
                    </tr>
                    :
                    isFetchingTopAssists ? 
                    <tr>
                       <td colspan={7} className='p-0'>
                       <LoadingSpinnner p={4} fs={"sm"}/>
                       </td>
                    </tr>
                    :
                    topAssists.length === 0 ?
                    <tr>
                        <td colspan={3} className='p-0'>
                            <ErrorMsg msg={t('Error.GeneralError')} p={5} fs={'md'} />
                        </td>
                    </tr>
                    :
                    topAssists.map(
                        (player,i) =>   i <= 4 ?
                        <tr key={player.id} onClick={()=> handleRowClick(player.id)} style={{cursor:"pointer"}} className='table-clickable-row'>
                            <th scope="row"><img src={process.env.REACT_APP_NEW_MEDIA_URL+player.image} width='30px' height="30px" className='mx-2' style={{borderRadius:'50%'}}/><span>{player.name}</span></th>
                            <td className='text-center' >{player.played}</td>
                            <td className='text-center'>{player.assissts}</td>
                        </tr>
                        :
                        ''
                        )
                }

            </tbody>
            </table>
        </div>
    )
}

const mapStateToProps = state => ({
    topAssists:state.topAssists.TopAssists,
    isFetchingTopAssists:state.topAssists.isFetchingTopAssists,
    TopAssistsFailed:state.topAssists.TopAssistsFailed,
    currentLanguageCode:state.language.currentLanguageCode,
})

const mapDispatchToProps = dispatch =>({
    getTopAssists:(sport_id , season_id , tournament_id , team_id, lang)=> dispatch(getTopAssists(sport_id , season_id , tournament_id, team_id , lang))
})

export default connect(mapStateToProps,mapDispatchToProps)(TopAssists)