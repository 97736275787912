import TournamentMatchesTypes from "./TournamentMatches.Types"; 
import axios from 'axios'

const { REACT_APP_NEW_BASE_URL , REACT_APP_NEW_GET_TOURNAMENT_MATCHES   } = process.env

const getTournamentMatchesStart = () => ({
    type:TournamentMatchesTypes.GET_TOURNAMENT_MATCHES_START
})

const getTournamentMatchesSuccess = (info) => ({
    type:TournamentMatchesTypes.GET_TOURNAMENT_MATCHES_SUCCESS,
    payload:info
})

const getTournamentMatchesFailed = (info) => ({
    type:TournamentMatchesTypes.GET_TOURNAMENT_MATCHES_FAILED,
    payload:info
})


const getTournamentMatches = (sport_id , tournament_id , team_id , start_date , lang) => (
    dispatch => {
        dispatch(getTournamentMatchesStart())
        axios({
              method: "get",
              url: `${REACT_APP_NEW_BASE_URL}${REACT_APP_NEW_GET_TOURNAMENT_MATCHES}`,
               params: {  sport_id, start_date , tournament_id , team_id},
              headers: {
                "Content-Type": "application/json",
                "Accept-Language":`${lang}`
              },
            }).then(resp => {   
              if (resp.data.status===200)
              { 
                dispatch(getTournamentMatchesSuccess(resp.data.data))
              }
              else {
                dispatch(getTournamentMatchesFailed(resp.data.message))
              }}
              )
              .catch(error => dispatch(getTournamentMatchesFailed(error)))
     
      }
)

export { getTournamentMatches }