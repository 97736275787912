import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import { VelocityTransitionGroup } from 'velocity-react';
import { getMatchLineUp } from '../../redux/Match/Actions.Match';
import Lineup from '../lineup/lineup.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory } from "react-router-dom";
import { faTshirt } from '@fortawesome/free-solid-svg-icons'
import './MatchLineup.css';
import LoadingSpinnner from '../loadingSpinner/loadingSpinner';
require('velocity-animate');
require('velocity-animate/velocity.ui');

const LiveMatchLineup = ({matchId , getMatchLineUp ,  matchLineUp , isFetchingMatchLineUp ,matchLineUpFailure}) =>{
    const [key, setKey] = useState('home');



    useEffect(()=> {
        getMatchLineUp(matchId)
    },[])

    const history = useHistory();
    const handleRowClick = (id) => {
      history.push(`/player/${id}`);
    };


    const getMainPlayers = (param) =>  matchLineUp[param]



    const makeSideLineup= (param) => {
        const mainPlayers=getMainPlayers(param).formation.pitch
        const sideLineUp={حارس :[...mainPlayers.goal],دفاع:[...mainPlayers.defence],وسط:[...mainPlayers.midp1,...mainPlayers.midp2,...mainPlayers.midp3],هجوم:[...mainPlayers.attack]}
        return sideLineUp
    }
    



    const  animation = 'transition.swoopIn'
    const  animationLeave = 'transition.slideUpOut'


    const getSideLine = (param) => (
        
            Object.keys(makeSideLineup(param))
            .map((el,i)=> 
           
            <div className=' bg-primary-color secondary-color-light p-1 rounded mt-2'>
                <h3 className='border-bottom border-secondary m-3 pb-3 fs-xm '>{el}</h3>
                {
                    makeSideLineup(param)[el].map((el,i2)=> 
                    <VelocityTransitionGroup runOnMount enter={{animation:animation,duration:i2 ===0 ? 150 : i2*1450}} leave={{animation:animationLeave,duration:200}}>
                    <div className='d-flex justify-content-start my-3 table-clickable-row p-2' style={{position:'relative'}}  onClick={() => handleRowClick(el.player_id)}>
                
                        <FontAwesomeIcon icon={faTshirt} size="xl"/><div className='fs-xs sideline-player-tshirt-num' >{el.shirt_number}</div><div className='d-flex justify-content-start'><img src={`${process.env.REACT_APP_NEW_MEDIA_URL}${el.image}`} className='mx-2 mini-player-image' style={{width:'35px'}}/> <div className='fs-sm'>{el.player_name}</div></div>
                            
                    </div>
                    </VelocityTransitionGroup>
                    )
                }
            </div>
            )
    )



    return (
        matchLineUp.length === 0 || matchLineUpFailure != null ?
        <div className='text-center text-light mt-5'>لا يوجد تشكيله</div>
        :
        isFetchingMatchLineUp ? 
        <div className='mt-5'>
             <LoadingSpinnner fs={'md'} p={5}/>
        </div>

        :
        <div>
        <div className='d-lg-none live-match-lineup-mini mt-2'>
                <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => {
                        setKey(k)
                        }}
                    variant='tabs'
                    className='justify-content-center'
                >
                <Tab  eventKey="home" title={<div>{getMainPlayers(0).team_name} <img src={`${process.env.REACT_APP_NEW_MEDIA_URL}${getMainPlayers(0).logo}`} className='mr-1' style={{width:'25px'}}></img></div>}>
                    <Col xs={12} lg={5} className='d-flex justify-content-center'>
                        <Col className='live-match-lineup-field-mini col-11'>
                            <Lineup teamAway={getMainPlayers(1)} teamHome={getMainPlayers(0)} home={true}/>
                        </Col>
                    </Col>
                    <Col xs={12} lg={3} className='live-match-side-lineup-mini text-dark'>
                    {
                        <div>
                            {
                                getSideLine(0)
                            }
                        </div>
                    }
                    </Col>
                </Tab>
             
                <Tab eventKey="away" title={<div>{getMainPlayers(1).team_name} <img src={`${process.env.REACT_APP_NEW_MEDIA_URL}${getMainPlayers(1).logo}`} className='mr-1' style={{width:'25px'}}></img></div>}>
                    <Col xs={12} lg={5} className='d-flex justify-content-center'>
                        <Col className='live-match-lineup-field-mini col-11'>
                            <Lineup teamAway={getMainPlayers(1)} teamHome={getMainPlayers(0)} away={true}/>
                        </Col>
                    </Col>
                    <Col xs={12} lg={3}  className='live-match-side-lineup-mini text-dark'>
                    {
                        <div>
                        {   
                            getSideLine(1)
                        }
                        </div>
                    }
                    </Col>
                </Tab>
        </Tabs>
        </div>
        <Row className='live-match-lineup text-dark justify-content-between d-none d-lg-flex'>
            <Col sm={6} lg={3}>
               {
                   <div>
                       {
                           getSideLine(0)
                       }
                    </div>
               }
            </Col>
            <Col xs={12} lg={5}>
                <div className='text-center my-3'>
                <img src={process.env.REACT_APP_NEW_MEDIA_URL+getMainPlayers(0).logo} width="100px"/>
                </div>
                <Col className='live-match-lineup-field'>
                    <Lineup teamAway={getMainPlayers(1)} teamHome={getMainPlayers(0)} secondary={true}/>
                </Col>
                <div className='text-center my-3'>
                <img src={process.env.REACT_APP_NEW_MEDIA_URL+getMainPlayers(1).logo} width="100px"/>
                </div>
            </Col> 
            <Col sm={6} lg={3}>
            {
                <div>
                   {   
                       getSideLine(1)
                   }
                </div>
               }
            </Col>
        </Row>
        </div>
    )
}


const mapStateToProps = state => ({
    matchLineUp:state.matchInfo.matchLineUp,
    isFetchingMatchLineUp:state.matchInfo.isFetchingMatchLineUp,
    matchLineUpFailure:state.matchInfo.matchLineUpFailure

})

const mapDispatchToProps = dispatch =>({
    getMatchLineUp:(match_id)=> dispatch(getMatchLineUp(match_id))
})


export default connect(mapStateToProps,mapDispatchToProps)(LiveMatchLineup)