import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { getTransferHistory } from "../../redux/TransferHistory/TransferHistory.Actions";
import { getGeneralStatistics } from "../../redux/GeneralStatistics/GeneralStatistics.Actions";
import { setCurrentlyDisplayedGeneralTransferHistory } from "../../redux/GeneralStatistics/GeneralStatistics.Actions";
import { addH2Hteam } from "../../redux/TransferHistory/TransferHistory.Actions";
import { useTranslation } from "react-i18next";
import Language from "../../redux/language/language.Reducer";
import { Container } from "react-bootstrap";
import LoadingSpinnner from "../loadingSpinner/loadingSpinner";
import ErrorMsg from "../ErrorPage/ErrorMsg";

const TransferHistory = ({
  TransferHistory,
  isFetchingTransferHistory,
  TransferHistoryFailed,
  currentLanguageCode,
  getTransferHistory,
}) => {
  const id = useParams().id;

  useEffect(() => {
    getTransferHistory(id, currentLanguageCode);
  }, [id, currentLanguageCode]);

  const { t } = useTranslation();

  const history = useHistory();

  const handleRowClick = (id) => {
    history.push(`/team/${id}`);
  };

  return (
    <div>
      <div className="bg-primary-color p-4 secondary-color-light">
        {t(`Player.TransferHistory`)}
      </div>
      <div className="bg-primary-color secondary-color-light fs-xs">
        <table class="table-borderless bg-primary-color table-striped table-dark secondary-color-light col-12">
          <thead>
            <tr style={{ padding: "10px" }} className="text-center">
              <th scope="col">{t(`General.Season`)}</th>
              <th scope="col">{t(`Player.Date`)}</th>
              <th scope="col">{t(`Player.Left`)}</th>
              <th scope="col">{t(`Player.Joined`)}</th>
              <th scope="col">{t(`Player.Trans/Loan`)}</th>
              <th scope="col">{t(`Player.Fees`)}</th>
            </tr>
          </thead>
          <tbody>
            {TransferHistoryFailed !== null ? (
              <ErrorMsg p={5} fs={"md"} msg={t("Error.StatisticsError")} />
            ) : isFetchingTransferHistory ? (
              <LoadingSpinnner p={5} fs={"md"} />
            ) : (
              TransferHistory.map((trans, i) => (
                <tr key={trans.id} className="text-center p-2">
                  <td>{trans.season}</td>
                  <td>{trans.join_date}</td>
                  {trans.from_team !== null ? (
                    <td
                      onClick={() => handleRowClick(trans.from_team.id)}
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        src={
                          process.env.REACT_APP_NEW_MEDIA_URL +
                          trans.from_team.logo
                        }
                        width="30px"
                        className="mx-1"
                      />{" "}
                      <span>{trans.from_team.name}</span>
                    </td>
                  ) : (
                    <div className="mt-2">لا يوجد</div>
                  )}
                  {trans.to_team !== null ? (
                    <td
                      onClick={() => handleRowClick(trans.to_team.id)}
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        src={
                          process.env.REACT_APP_NEW_MEDIA_URL +
                          trans.to_team.logo
                        }
                        width="30px"
                        className="mx-1"
                      />{" "}
                      <span>{trans.to_team.name}</span>
                    </td>
                  ) : (
                    <div className="mt-2">لا يوجد</div>
                  )}
                  <td>{trans.contract_type}</td>
                  <td>{trans.price}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getTransferHistory: (player_id, lang) =>
    dispatch(getTransferHistory(player_id, lang)),
});

const mapStateToProps = (state) => ({
  isFetchingTransferHistory: state.transferHistory.isFetchingTransferHistory,
  TransferHistoryFailed: state.transferHistory.TransferHistoryFailed,
  TransferHistory: state.transferHistory.TransferHistory,
  currentLanguageCode: state.language.currentLanguageCode,
});

export default connect(mapStateToProps, mapDispatchToProps)(TransferHistory);
