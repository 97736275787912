import { useState } from 'react';
import { useTranslation } from "react-i18next"

import axios from 'axios';
import { getName } from '../../globalFn';



const ComparisonSearch = ({type,setCompare,setComparisonItems})=>{

    const { t } = useTranslation()

    const [firstItemSearchQuery,setFirstItemSearchQuery]=useState('')
    const [isFetchingFirstItem,setIsFetchingFirstItem]=useState(false)
    const [firstItemArray,setFirstItemArray]=useState([])
    const [firstItemError,setFirstItemError]=useState(null)
    const [secondItemSearchQuery,setSecondItemSearchQuery]=useState('')
    const [isFetchingSecondItem,setIsFetchingSecondItem]=useState(false)
    const [secondItemArray,setSecondItemArray]=useState([])
    const [secondItemError,setSecondItemError]=useState(null)

    const getSearchItems = (query,type,searchInputNum,per_page,lang) => {
        searchInputNum === 1 ? setIsFetchingFirstItem(true) :  setIsFetchingSecondItem(true)
        axios({
            method: "get",
            url: `${process.env.REACT_APP_NEW_BASE_URL}${type==='PLAYERS' ? process.env.REACT_APP_NEW_GET_SIMPLE_PLAYERS :  process.env.REACT_APP_NEW_GET_SIMPLE_TEAMS}?keyword=${query}`,
            headers: {
              "Content-Type": "application/json",
              "Accept-Language":`${lang}`
            },
            params:{
              per_page
            },
          }).then(resp => {   
            if (resp.data.code===200)
            { 
                searchInputNum === 1 ? setFirstItemArray(resp.data.data) : setSecondItemArray(resp.data.data)
                searchInputNum === 1 ? setIsFetchingFirstItem(false) : setIsFetchingSecondItem(false)
            }
            else {
                searchInputNum === 1 ? setFirstItemError(resp.data.message) : setSecondItemError(resp.data.message)
                searchInputNum === 1 ? setIsFetchingFirstItem(false) : setIsFetchingSecondItem(false)
            }}
            )
            .catch(error => {
                searchInputNum === 1 ? setFirstItemError(error) : setSecondItemError(error)
                searchInputNum === 1 ? setIsFetchingFirstItem(false) : setIsFetchingSecondItem(false)})
    }

    const activateItem = (item,arr,fun,secondFun) => {
        // first function sets the state of the first and second item array 
        // second function is a function that changes the input val for respective item
        secondFun(item.name)
       const newarr=arr.map(el=> el.id===item.id? {...el,active:true} : {...el,active:false} )
       fun(newarr)
    }
    const stopSearchReq = (fun) => {
        window.clearTimeout(fun)
    }

    let searchTimeOut;

    return (
        <div style={{height:'100%'}}>
        <div className='row justify-content-between m-1' style={{height:'90%'}}>
           <div className='col-5 col-md-4 p-0' style={{height:'100%'}}>
            <input className="form-control my-2" type="search" placeholder={t(`Search.SearchHere`)} aria-label="Search" value={firstItemSearchQuery} 
                onChange={(e)=> {
                    setFirstItemSearchQuery(e.target.value)
                    if(e.target.value==='')setFirstItemArray([])
                }}

                onKeyUp={(e)=> {
                  searchTimeOut = setTimeout(()=> { getSearchItems(e.target.value,type,1,6,'ar') },700)
                }}
                onKeyDown={(e)=>{
                    stopSearchReq(searchTimeOut)
                }}
                /> 
            <ul className={`${firstItemSearchQuery === "" ? 'd-none' : ''} text-light px-1`}>
           {
               isFetchingFirstItem ?
               <div className='text-center mt-5'> 
                    <div class="spinner-border text-secondary fs-xs p-0" role="status">
                        <span class="sr-only">Loading...</span>
                    </div> 
               </div>
               :
               firstItemArray.length === 0 ? 
               <li className='text-center mt-3 text-nowrap'>{t(`Search.NoResults`)}</li>
               :
               firstItemArray.slice(0,6).map(item => 
                type === 'PLAYERS' ?
                    <li className={`${item.active ? 'comparison-item-active' : '' } comparison-item d-flex justify-content-start align-items-center p-1 px-2 p-md-2`} onClick={()=> activateItem(item,firstItemArray,setFirstItemArray,setFirstItemSearchQuery)}>
                        <div className='comparison-search-item'>
                            <img src={process.env.REACT_APP_NEW_MEDIA_URL+item.image}/>
                            <img src={process.env.REACT_APP_NEW_MEDIA_URL+item.teamLogo}/>
                        </div>
                        <div className='mx-3 fs-sm text-nowrap'>
                            <div>{getName(item.name,12)}</div>
                            <div className='secondary-color-light'>{item.position}</div>
                        </div>
                    </li>
                    :
                    <li className={`${item.active ? 'comparison-item-active' : '' } comparison-item d-flex justify-content-between p-2 text-light fs-sm text-nowrap`} key={item.id*Math.random()} onClick={()=> activateItem(item,firstItemArray,setFirstItemArray,setFirstItemSearchQuery)}>
                        <div>{getName(item.name,12)}</div>
                        <img src={`${process.env.REACT_APP_NEW_MEDIA_URL}${item.logo}`} width="30px" height="30px">
                        </img>
                    </li>)
           }
           </ul>        
           </div>

           <div className='col-5 col-md-4 p-0' style={{height:'100%'}}>
           <input className="form-control my-2" type="search" placeholder={t(`Search.SearchHere`)} aria-label="Search" value={secondItemSearchQuery} 
                onChange={(e)=> {
                    setSecondItemSearchQuery(e.target.value)
                    if(e.target.value==='')setSecondItemArray([])
                }}

                onKeyUp={(e)=> {
                  searchTimeOut = setTimeout(()=> { getSearchItems(e.target.value,type,2,6,'ar') },700)
                }}
                onKeyDown={(e)=>{
                    stopSearchReq(searchTimeOut)
                }}
                /> 
            <ul className={`${secondItemSearchQuery === "" ? 'd-none' : ''} text-light`}>
           {
               isFetchingSecondItem ?
               <div className='text-center mt-5'> 
                    <div class="spinner-border text-secondary fs-xs p-0" role="status">
                        <span class="sr-only">Loading...</span>
                    </div> 
               </div>
               :
               secondItemArray.length === 0 ? 
               <li className='text-center mt-3 text-nowrap'>{t(`Search.NoResults`)}</li>
               :
               secondItemArray.slice(0,6).map(item => 
                type === 'PLAYERS' ?
                <li className={`${item.active ? 'comparison-item-active' : '' } comparison-item d-flex justify-content-start align-items-center p-1 px-2 p-md-2`} onClick={()=> activateItem(item,secondItemArray,setSecondItemArray,setSecondItemSearchQuery)}>
                    <div className='comparison-search-item'>
                        <img src={process.env.REACT_APP_NEW_MEDIA_URL+item.image}/>
                        <img src={process.env.REACT_APP_NEW_MEDIA_URL+item.teamLogo}/>
                    </div>
                    <div className='mx-3 fs-sm text-nowrap'>
                            <div>{getName(item.name,12)}</div>
                            <div className='secondary-color-light'>{item.position}</div>
                    </div>
                </li>
                :
                <li className={`${item.active ? 'comparison-item-active' : '' } comparison-item d-flex justify-content-between p-2 text-light  fs-sm text-nowrap`} key={item.id*Math.random()} onClick={()=> activateItem(item,secondItemArray,setSecondItemArray,setSecondItemSearchQuery)}>
                    <div>{getName(item.name,12)}</div>
                    <img src={`${process.env.REACT_APP_NEW_MEDIA_URL}${item.logo}`} width="30px" height="30px">
                    </img>
                </li>)
           }
           </ul>    
           </div>
        </div>
        <div className='vs-button text-center'>
               <button className='text-light etisalat-border bg-active-color fs-xs text-center px-5 py-2' 
               onClick={()=>{
                   const item1=firstItemArray.filter(el=> el.active===true)
                   const item2=secondItemArray.filter(el=> el.active===true)
                   setComparisonItems([...item1,...item2])
                   setCompare(true)
               }}
               disabled={firstItemArray.slice(0,8).filter(el=> el.active===true).length === 0 || secondItemArray.filter(el=> el.active===true).length === 0}>
                    {t(`Comparison.Compare`)}
                </button>
           </div>
        </div>
    )
}

export default ComparisonSearch