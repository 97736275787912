import { Col, Container, Row } from "reactstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const ClubsCarouselLoading = () => {
  return (
    <Container className=" teams_card ">
      <SkeletonTheme color="rgba(255,255,255,0.0)" highlightColor="#ecebeb">
        <Row className=" no-gutters  m-auto text-center">
          <Col xs={6} xl={3} className="my-1 my-md-3">
            <div className="mb-3 border-0  m-auto text-center w-100">
              <Skeleton height={50} width={50} className="rounded-circle" />
            </div>
          </Col>
          <Col xs={6} xl={3} className="my-1 my-md-3">
            <div className="mb-3 border-0  m-auto text-center w-100">
              <Skeleton height={50} width={50} className="rounded-circle" />
            </div>
          </Col>
        </Row>
      </SkeletonTheme>
      <style type="text/css" jsx="true">
        {`
          .teams_card {
            box-shadow: 0 3px 6px 0 rgba(164, 164, 164, 0.2);
          }

          @media (max-width: 575.98px) {
            .teams_card {
              border-radius: unset;
            }
          }
        `}
      </style>
    </Container>
  );
};

export default ClubsCarouselLoading;
