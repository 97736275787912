import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import {Col ,Row,Container} from "react-bootstrap"
const HeaderInfoComponentLoader = () => {
  return (
        <SkeletonTheme color="#323232" highlightColor="#ecebeb" >
          <Row className='d-flex bg-primary-color m-0'>
            <Col></Col>
            <Col className='d-flex flex-column justify-content-center align-items-center my-5'>      
              <Skeleton height={150} width={150} className="rounded-circle" />
              <Skeleton height={30} width={200} className="mt-4" />
            </Col>
            <Col></Col>
          </Row>
        </SkeletonTheme>
  );
};

export default HeaderInfoComponentLoader;
