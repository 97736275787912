import React,{ useEffect } from 'react'
import { useState } from 'react'
import axios from 'axios';
import { Col, Modal,ProgressBar,Row,Dropdown } from 'react-bootstrap'
import { getName } from '../../globalFn'
import { useTranslation } from "react-i18next"
import LoadingSpinnner from '../loadingSpinner/loadingSpinner';

const ComparisonTeamsResults = ({comparisonItems})=>{


    const { t } = useTranslation()

    const [isFetchingTeamStats,setIsFetchingTeamStats]=useState(false)
    const [TeamsStats,setTeamsStats]=useState([])
    const [errorTeamStats,setErrorTeamStats]=useState(null)

    const tempStatsState=[]

    const getTeamStatistics = (sport_id,season_id,tournament_id, team_id ,lang,i) => {
        axios({
                method: "get",
                url: `${process.env.REACT_APP_NEW_BASE_URL}${process.env.REACT_APP_NEW_GET_TEAM_STATISTICS}`,
                params: { sport_id,season_id,tournament_id,team_id },
                headers: {
                "Content-Type": "application/json",
                "Accept-Language":`${lang}`
                },
            }).then(resp => {   
                if (resp.data.status===200)
                { 
                //   if(TeamsStats.length >= 2){
                //       setTeamsStats(TeamsStats.map((player,i2)=> i===i2 ? resp.data.data : player))
                //   }   
                //   else {
                    tempStatsState.length < 1 ? tempStatsState.push(resp.data.data)   : setTeamsStats([...tempStatsState,resp.data.data])
                //   } 
                }
                else {
                setErrorTeamStats(resp.data.message)
                }}
                )
                .catch(error =>  {
                setErrorTeamStats(error)})
    }
    
    
    useEffect(()=> {
        comparisonItems.map(player=> 
            {   setIsFetchingTeamStats(true)
                getTeamStatistics(null,null,null,player.id,'ar')
                setIsFetchingTeamStats(false)
            }) 
    },[])

    const statisticsKeys={
        played:t(`StatsTable.Played`),
        win: t(`StatsTable.Wins`),
        lose: t(`StatsTable.Losses`),
        draw: t(`StatsTable.Draw`),
        goals_in: t(`StatsTable.GoalsAgainst`),
        goals_out: t(`StatsTable.GoalsFor`),
        goals_penalty: t(`StatsTable.GoalsPenalty`),
        clean_sheet: t(`StatsTable.CleanSheet`),
        yellow_cards: t(`StatsTable.YellowCards`),
        red_cards: t(`StatsTable.RedCards`),
        difference: t(`StatsTable.GoalsDifference`),
        point: t(`StatsTable.Points`),
        passes: t(`StatsTable.passes`),
        lost_balls: t(`StatsTable.LostBalls`),
        attempts: t(`StatsTable.Attempts`),
        chances: t(`StatsTable.Chances`),
        dribbles: t(`StatsTable.Dribbles`),
        fouls_in: t(`StatsTable.FoulsIn`),
        fouls_out: t(`StatsTable.FoulsOut`),
        ball_win: t(`StatsTable.BallWin`),
        ball_lose: t(`StatsTable.BallLose`),
    }

    const getAverage = (ObjectsArray,from,to) =>{
        const valuesArr=ObjectsArray.map((obj,i)=>  Object.values(obj).slice(from,to))
        
        const averagearr=[]
        for(let i=0 ; i< valuesArr.length; i++){
            for(let x=0 ; x< valuesArr[i].length ; x++){
                if(i===0){
                    averagearr.push(Number(valuesArr[i][x]))
                }
                else{
                    const item=averagearr[x]+Number(valuesArr[i][x])
                    averagearr.splice(x, 1, item)
                }
                
            }
        }
        
        return averagearr
    }

    const showedStats=[
        'clean_sheet',
        'difference', 
        'draw',
        'goals_in', 
        'goals_out', 
        'goals_penalty',
        'lose',
        'played', 
        'red_cards',
        'win',
        'yellow_cards',
    ]

    return(
        <div>
            <div className='row justify-content-around'>
                {
                    comparisonItems.map(team =>
                    <div className='col-4 text-center'>
                        <img src={process.env.REACT_APP_NEW_MEDIA_URL+team.logo} width={60} height={60}/>
                        <div className='fs-md'>{getName(team.name,12)}</div>
                    </div>)
                }

            </div>
            {
                isFetchingTeamStats ?
                <div className='mt-5'> 
                  <LoadingSpinnner p={5} fs={'md'}/> 
                </div>  
                :
                <Row className='d-flex justify-content-around items-stats-comparison'>
                {
                    TeamsStats.map(Team=> 
                    <Col xs={5}>
                        {
                          Object.keys(Team).slice(0,14).map((key,i)=> 
                            showedStats.includes(key)?
                            <div className='mt-3'>
                            <div className='d-flex justify-content-between'>   
                                <div className='fs-xs'>{statisticsKeys[key]}</div>
                                <div className='text-end fs-xs'>{getAverage(TeamsStats,0,14)[i]}</div>
                            </div>
                            <ProgressBar
                                now={Object.values(Team).slice(0,14)[i]}
                                label={Object.values(Team).slice(0,14)[i]}
                                max={getAverage(TeamsStats,0,14)[i]}
                            />
                            </div>
                            :
                            ''
                            )
                        }
    
                    </Col>)
                }
                </Row> 
            }
        </div>
    )
}

export default ComparisonTeamsResults