import React from 'react'
import etisalteLogo from '../../assets/etisalat-logo.png'
import { useTranslation } from "react-i18next"
import { Link } from 'react-router-dom'


const ErrorPage= ({error}) =>{

    const { t } = useTranslation()


    return (
        <div className='row p-0' style={{height:"400px",width:"100%"}}>
            <div className='col-3 p-0'></div>
            <div className='col-6 p-0 d-flex flex-column justify-content-center mt-0'  style={{height:"100%"}}>
                <img src={etisalteLogo} width="100%" height="60%"></img>
                <div className='text-center fs-lg secondary-color-light'>{error}</div>
                <div className='text-light text-center fs-lg btn-link'><Link to='/' >{t('Error.BackHome')}</Link></div>
            </div>
            <div className='col-3 p-0'></div>
        </div>
    )
}

export default ErrorPage