import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useParams } from 'react-router';
import { Container } from 'reactstrap';
import { connect } from 'react-redux';
import { getTournaments } from '../../redux/Tournaments/Tournaments.Actions';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Moment from "moment";
import { getName } from '../../globalFn'
import { convertFullDate } from '../../globalFn'
import { GoCalendar } from "react-icons/go";
import { useEffect } from 'react';
import { getMedisList } from "../../redux/MediaList/MediaList.Action";
import NewsCard from './newsCard';



// --bk-color-2

function SampleNextArrow(props) {
  const { className, style, onClick } = props;

  return (
    <button  onClick={onClick} style={{...style,fontSize: "20px", display: 'block', background: "rgba(0,0,0,.4)", borderRadius:"6px",border: "unset" ,position: "absolute", top: 0, height: "100%", right: "-14px", zIndex:"15",width:" 60px", opacity:"1", color:"white"}} >
      <i className="fa fa-chevron-right" aria-hidden="true"></i>
   </button>
    
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;

  return (
    <button  onClick={onClick} style={{...style,fontSize: "20px", display: 'block', background: "rgba(0,0,0,.4)", borderRadius:"6px", border: "unset" ,position: "absolute", top: 0, height: "100%", left: "-14px", zIndex:"15", width:" 60px", opacity:"1", color:"white"}} >
      <i className="fa fa-chevron-left" aria-hidden="true"></i>
    
    </button>
  );
}


function MostPopularVids({ isFetchingMostPopularVids , MostPopularVids ,  MostPopularVidsFailed , currentLanguageCode , getMedisList ,sport_id , season_id , items_count , media_type , tournament_id, media_id, team_id , player_id , topStory , latest , popular }) {

  const id = useParams().id;

  useEffect(()=> {
    // this is the params in order  (sport_id , season_id , items_count , Media_type, tournament_id, media_id, team_id , player_id , topStory , latest , popular , lang)
    getMedisList(sport_id , season_id , items_count ,media_type , tournament_id, media_id, team_id , player_id , topStory , latest , popular , currentLanguageCode)
  }
  ,[currentLanguageCode])

  const { REACT_APP_NEW_MEDIA_URL  } = process.env
    const settings = {
      infinite: false,
      speed: 900,
      slidesToShow: 3.8,
      

      slidesToScroll: 1,
      rtl:currentLanguageCode ==='ar'? true : false,
      accessibility: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1.1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1.1,
            slidesToScroll: 1,
          },
        },
      ],
      };

    return (
        <Container className="news-section">
        <Slider {...settings}>
            {
                isFetchingMostPopularVids ? 
                <div>...loading</div>
                :
              MostPopularVids.length === 0 ? 
              <div className='my-5 text-light'>لا يوجد اخبار</div>
              :
               MostPopularVids.map(news => 
                <div className="ml-auto mr-auto" key={news.id}>
                  <NewsCard news={news} type={2}/>
               </div>)
                     
            }
        </Slider>
        </Container>
    )
}

const mapStateToProps = state =>({
    isFetchingMostPopularVids:state.MediaList.isFetchingMostPopularVids,
    MostPopularVids:state.MediaList.MostPopularVids,
    MostPopularVidsFailed:state.MediaList.MostPopularVidsFailed,
    currentLanguageCode:state.language.currentLanguageCode
  })
  const mapDispatchToProps = (dispatch) => ({
    getMedisList: (sport_id , season_id , items_count ,Media_type, tournament_id, media_id, team_id , player_id , topStory , latest , popular , lang) =>
      dispatch(getMedisList(sport_id , season_id , items_count ,Media_type, tournament_id, media_id, team_id , player_id , topStory , latest , popular , lang)),
  });  

export default connect(mapStateToProps,mapDispatchToProps)(MostPopularVids)