import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import etisalteLogo from '../../assets/etisalat-logo.png'
import "./FooterComponent.css";

const FooterComponent = () => {
  const { t } = useTranslation();
  return (
    <Container
      fluid
      dir="ltr"
      className="footer mt-5"
      style={{
        backgroundImage: "url(/img/footer-bg.jpg)",
        backgroundSize: "cover",
        backgroundPosition:"center",
        position:"relative"
      }}
    >
      <div className='footer-overlay'></div>
      <div className="container">
        <div className="row row-centered">
          <div
            className="col-lg-4 col-xs-12 text-center"
        
          >
            <a href="https://apps.apple.com/eg/app/etisalat-sports/id1455787805">
              <img src="/img/appstore.png" width="100px" alt="App Store" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.etisalat.sports">
              <img src="/img/googleplay.png" width="100px" alt="Google Play" />
            </a>
          </div>
          <div
            className="col-lg-4 col-xs-12  text-center my-5"
            
          >
            <Link to="/">
              <img
                src={etisalteLogo}
                width="250px"
                alt="Etisalat Sports"
              />
            </Link>
          </div>
          <div
            className="col-lg-4 col-xs-12 space green  text-center"
          
          >
            <a href="https://www.youtube.com/user/EtisalatMasr">
              <i className="fa fa-youtube-play fa-lg green"></i>
            </a>
            <a href="https://www.linkedin.com/company/etisalat-misr">
              <i className="fa fa-linkedin fa-lg green"></i>
            </a>
            <a href="https://twitter.com/etisalatmisr">
              <i className="fa fa-twitter fa-lg green"></i>
            </a>
            <a href="https://plus.google.com/+etisalatmisr">
              <i className="fa fa-google-plus fa-lg green"></i>
            </a>
            <a href="https://www.instagram.com/etisalatmisr/">
              <i className="fa fa-instagram fa-lg green"></i>
            </a>
            <a href="https://www.facebook.com/etisalatmisr/">
              <i className="fa fa-facebook fa-lg green"></i>
            </a>
            <Link to="/terms-conditions">
              <p className="text-center fs-md">{t("General.TermsAndConditions")}</p>
            </Link>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default FooterComponent;
