import React from 'react'
import './sideMenu.css'


const SideMenu= ({title}) =>{
    return(
        <div className='sideMenu text-light flex-column justify-content-around align-content-center p-2 d-none d-md-flex'>
            <div className='table-clickable-row-no-animation p-1'><img src='/icons/football-ball.svg'></img></div>
            <div className='table-clickable-row-no-animation p-1'><img src='/icons/volleyball.svg'></img></div>
            <div className='table-clickable-row-no-animation p-1'> <img src='/icons/tennis.svg'></img></div>
            <div className='table-clickable-row-no-animation p-1'><img src='/icons/football.svg'></img></div>
            <div className='table-clickable-row-no-animation p-1'><img src='/icons/basketball.svg'></img></div>
        </div>
    )
}

export default SideMenu