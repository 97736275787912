import { useState } from "react";
import { Col, Modal, ProgressBar, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getName } from "../../globalFn";
import ComparisonSearch from "./ComparisonSearch";
import "./ComparisonModal.css";
import ComparisonPlayersResults from "./ComparisonPlayersResults";
import ComparisonTeamsResults from "./ComparisonTeamsResults";
import etisalteLogo from "../../assets/etisalat-logo.png";

const ComparisonPopUp = ({ show, setShow }) => {
  const [comparisonSearch, setComparisonSearch] = useState(false);
  const [compare, setCompare] = useState(false);
  const [comparisonItems, setComparisonItems] = useState([]);
  const [type, setType] = useState("");

  const { t } = useTranslation();
  const statisticsKeys = {
    played: t(`StatsTable.Played`),
    win: t(`StatsTable.Wins`),
    lose: t(`StatsTable.Losses`),
    draw: t(`StatsTable.Draw`),
    goals_in: t(`StatsTable.GoalsAgainst`),
    goals_out: t(`StatsTable.GoalsFor`),
    goals_penalty: t(`StatsTable.GoalsPenalty`),
    clean_sheet: t(`StatsTable.CleanSheet`),
    yellow_cards: t(`StatsTable.YellowCards`),
    red_cards: t(`StatsTable.RedCards`),
    difference: t(`StatsTable.GoalsDifference`),
    point: t(`StatsTable.Points`),
    passes: t(`StatsTable.passes`),
    lost_balls: t(`StatsTable.LostBalls`),
    attempts: t(`StatsTable.Attempts`),
    chances: t(`StatsTable.Chances`),
    dribbles: t(`StatsTable.Dribbles`),
    fouls_in: t(`StatsTable.FoulsIn`),
    fouls_out: t(`StatsTable.FoulsOut`),
    ball_win: t(`StatsTable.BallWin`),
    ball_lose: t(`StatsTable.BallLose`),
  };

  const getAverage = (ArrObj, from, to) => {
    const valuesArr = ArrObj.map((obj, i) =>
      Object.values(obj).slice(from, to)
    );

    const averagearr = [];
    for (let i = 0; i < valuesArr.length; i++) {
      for (let x = 0; x < valuesArr[i].length; x++) {
        if (i === 0) {
          averagearr.push(valuesArr[i][x]);
        } else {
          const item = averagearr[x] + valuesArr[i][x];
          averagearr.splice(x, 1, item);
        }
      }
    }
    console.log(averagearr);
    return averagearr;
  };

  const showedStats = [
    "goals_out",
    "goals_penalty",
    "attempts",
    "chances",
    "dribbles",
    "ball_win",
    "ball_lose",
    "fouls_out",
    "fouls_in",
  ];

  return (
    <Modal
      className="comparison-modal"
      show={show}
      onHide={() => {
        setComparisonItems([]);
        setComparisonSearch(false);
        setCompare(false);
        setShow(false);
      }}
      closeButton
    >
      <Modal.Header className="p-2" closeButton></Modal.Header>
      <Modal.Body>
        <img src={etisalteLogo} className="comparsion-logo" />
        <div
          className={`${
            comparisonSearch === true ? "d-none" : "d-flex"
          }  flex-column justify-content-center align-items-center p-5`}
        >
          <div
            className="btn bg-active-color etisalat-border px-3 py-2 px-md-5 py-md-3 text-light fs-md"
            onClick={() => {
              setType("TEAMS");
              setComparisonSearch(true);
            }}
          >
            {t("Comparison.TeamsComparison")}
          </div>
          <div
            className="btn bg-active-color etisalat-border px-3 py-2 px-md-5 py-md-3 mt-3 text-light fs-md"
            onClick={() => {
              setType("PLAYERS");
              setComparisonSearch(true);
            }}
          >
            {t("Comparison.PlayersComparison")}
          </div>
        </div>
        {comparisonSearch && !compare ? (
          <ComparisonSearch
            setCompare={setCompare}
            setComparisonItems={setComparisonItems}
            type={type}
          />
        ) : (
          ""
        )}
        {compare && type === "PLAYERS" ? (
          <ComparisonPlayersResults comparisonItems={comparisonItems} />
        ) : (
          ""
        )}
        {compare && type === "TEAMS" ? (
          <ComparisonTeamsResults comparisonItems={comparisonItems} />
        ) : (
          ""
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ComparisonPopUp;
