import React from "react";
import { Link } from "react-router-dom";
import { convertDate } from "../../globalFn";
import { GoCalendar } from "react-icons/go";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Moment from "moment";
export default function NewsCarousel(props) {
  const settings = {
    nextArrow: <span></span>,
    prevArrow: "",
    fade: true,
    infinite: false,
    autoplay: true,
    speed: 700,
    slidesToShow: 12,
    slidesToScroll: 12,
    rtl: props.currentLanguageCode === "ar" ? true : false,
    accessibility: true,
    // responsive: [
    //   {
    //     breakpoint: 1024,
    //     settings: {
    //       slidesToShow: 12,
    //       slidesToScroll: 12,
    //     },
    //   },
    //   {
    //     breakpoint: 600,
    //     settings: {
    //       slidesToShow: 7,
    //       slidesToScroll: 7,
    //     },
    //   },
    //   {
    //     breakpoint: 480,
    //     settings: {
    //       slidesToShow: 6,
    //       slidesToScroll: 6,
    //     },
    //   },
    // ],
  };
  return (
    <div>
      {props.type === 2 ? (
        <div className="play-icon">
          <i className="fa fa-play"></i>
        </div>
      ) : (
        ""
      )}
      <Slider {...settings}>
        {props.MediaList.map((news) => (
          <div key={news.id} className="news-card-carousel">
            <Link to={`/media/${news.id}`}>
              <img
                src={`${process.env.REACT_APP_NEW_MEDIA_URL}${
                  props.type === 2 ? news.video_cover : news.media
                }`}
              />
              <div
                className={`${
                  props.currentLanguageCode === "ar"
                    ? "text-right"
                    : "text-left"
                } news-card-description  text-light bg-primary-color-opacity p-2`}
              >
                <p className="text-cut-2 px-3 mb-1">{news.title}</p>
                <small className="secondary-color-light  mx-2 mb-2">
                  <GoCalendar className="mx-2" />
                  {/* {convertDate(news.createdAt)} */}
                  {Moment(news.createdAt).format("D MM yyyy")}
                </small>
              </div>
            </Link>
          </div>
        ))}
      </Slider>
    </div>
  );
}
