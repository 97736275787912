import React , { useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams } from "react-router-dom";
import { getStanding } from "../../redux/Standing/Standing.Actions";
import { getGeneralStatistics } from '../../redux/GeneralStatistics/GeneralStatistics.Actions'
import { setCurrentlyDisplayedGeneralStanding } from '../../redux/GeneralStatistics/GeneralStatistics.Actions'
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom";
import Language from '../../redux/language/language.Reducer'
import { getName } from '../../globalFn';
import './generalStatistics.css'
import ErrorMsg from '../ErrorPage/ErrorMsg';
import LoadingSpinnner from '../loadingSpinner/loadingSpinner';

const TournamentOverviewStats = ({ Standing , standingFailed , isFetchingStanding , currentLanguageCode ,  getStanding , sport_id , season_id , tournament_id ,tournamentDetails ,toLocalTab}) => {

 
    
    useEffect(()=> {
        getStanding(sport_id , season_id , tournament_id ,currentLanguageCode)
    },[sport_id , season_id , tournament_id ,currentLanguageCode])

    const { t } = useTranslation()

    const history = useHistory(); 
    const handleRowClick = (id) => {
        history.push(`/team/${id}`);
      }  

    return (
    <div className='bg-primary-color secondary-color-light fs-xs'>
        <div className='d-flex justify-content-between fs-md p-2'>
        <div>{t('TournamentDetails.Stats')}</div>
        <div  style={{direction:'rtl'}}> 
            <button className={`fa fa-chevron-${currentLanguageCode === 'ar' ? 'left':'right'} btn secondary-color-light`} aria-hidden="true" onClick={()=> toLocalTab('stats')}></button>
        </div>
       </div>
        <table class="table-borderless bg-primary-color table-striped table-dark secondary-color-light">
        <thead>
            <tr className='text-center'>
            <th scope="col">{t(`StatsTable.Position`)}</th>
            <th scope="col" style={{textAlign:'start'}}>{t(`StatsTable.Club`)}</th>
            <th scope="col">{t(`StatsTable.Goals`)}</th>       
            <th scope="col">{t(`StatsTable.Matches`)}</th>
            <th scope="col">{t(`StatsTable.YellowCards`)}</th>
            <th scope="col">{t(`StatsTable.RedCards`)}</th>
            <th scope="col" className='d-none d-md-inline'>{t(`StatsTable.Penalty`)}</th>
            </tr>
        </thead>
        <tbody>
            {
                standingFailed !== null ?
                <tr>
                    <td colspan={7} className='p-0'>
                        <ErrorMsg msg={t('Error.StatisticsError')} p={5} fs={'lg'} />
                    </td>
                </tr>
                :
                isFetchingStanding ?
                <tr>
                <td colspan={7} className='p-0'>
                    <LoadingSpinnner p={5} fs={'md'}/>
                </td>
               </tr>
                :
                Standing.length === 0 ?
                <tr>
                <td colspan={7} className='p-0'>
                     <ErrorMsg msg={t('Error.StatisticsError')} p={5} fs={'lg'} />
                </td>
               </tr>
                :
                Standing.map(
                    (team,i) =>   i <= 7 ?        
                    <tr key={team.id}  onClick={()=> handleRowClick(team.team_id)} className='table-clickable-row text-center'>
                    <td style={{width:'20px'}}>{i+1}</td>
                    <th scope="row" className={window.document.body.dir === 'rtl' ? 'text-right team-name': 'text-left team-name'}><img src={process.env.REACT_APP_NEW_MEDIA_URL+team.team_logo} style={{width:'20px'}}/><span className="mx-2 fs-xxs ">{team.team_name}</span></th>
                    <td>{team.goals_in}</td>
                    <td>{team.played}</td>
                    <td>{team.yellow_cards}</td>
                    <td>{team.red_cards}</td>
                    <td className='d-none d-md-inline-block'>{team.goals_penalty}</td>
                    </tr>
                    :
                    ''
                    )
            }

        </tbody>
        </table>
    </div>
    )
}


const mapDispatchToProps = (dispatch) => ({
    getStanding: (sport_id , season_id ,tournament_id ,lang) => dispatch(getStanding(sport_id , season_id  ,tournament_id ,lang)),
  });
  
  const mapStateToProps = (state) => ({
    isFetchingStanding: state.standing.isFetchingStanding,
    standingFailed:state.standing.StandingFailed,
    Standing: state.standing.Standing,
    currentLanguageCode:state.language.currentLanguageCode,
    tournamentDetails: state.standing.TournamentDetails,
  });
  
  

export default connect(mapStateToProps,mapDispatchToProps)(TournamentOverviewStats)
