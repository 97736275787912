import React,{ useEffect } from 'react';
import { useTranslation } from "react-i18next";
import ErrorMsg from '../ErrorPage/ErrorMsg';
import LoadingSpinnner from '../loadingSpinner/loadingSpinner';


const TeamInfo = ({ TeamDetails , isFetchingTeamDetails , TeamDetailsFailed})=>{

    const { t } = useTranslation()



    return (
        <div className='container'>
            {
                TeamDetailsFailed !== null ?
                <ErrorMsg p={5} fs={'lg'} msg={'Error.GeneralError'}/>
                :
                isFetchingTeamDetails ? 
                <LoadingSpinnner p={5} fs={'md'}/>
                :
                <div>
                    <div className='bg-primary-color p-2 p-md-4'>
                        <div className='d-flex justify-content-center text-center text-light fs-xxs'>
                            <div className='px-md-5 px-2 '>
                                    <img src='/icons/founded.svg'/>
                                    <div className='secondary-color-light mt-1'>{t(`Team.Founded`)}</div>
                                    <div className='text-light mt-1'>{TeamDetails.founded}</div>
                            </div>
                            <div className='border-right border-secondary px-md-5 px-2'>
                                    <img src='/icons/stadium.svg'/>
                                    <div className='secondary-color-light mt-1'>{t(`Team.Stadium`)}</div>
                                    <div className='text-light mt-1'>{TeamDetails.stadium}</div>
                            </div>
                            <div className='border-right border-secondary px-md-5 px-2'>
                                    <img src='/icons/manager.svg'/>
                                    <div className='secondary-color-light mt-1'>{t(`Team.Manager`)}</div>
                                    <div className='text-light mt-1'>{TeamDetails.manager}</div>
                            </div>
                            <div className='border-right border-secondary px-md-5 px-2'>
                                    <img src='/icons/speaker.svg'/>
                                    <div className='secondary-color-light mt-1'>{t(`Team.Chairman`)}</div>
                                    <div className='text-light mt-1'>{TeamDetails.chairman.ar}</div>
                            </div>
                            <div className='border-right border-secondary px-md-5 px-2'>
                                    <img src='/icons/player.svg'/>
                                    <div className='secondary-color-light mt-1'>{t(`Team.LatestTransfer`)}</div>
                                    <div className='text-light mt-1'></div>
                            </div>
                            <div className='border-right border-secondary px-md-5 px-2'>
                                    <img src='/icons/colour.svg'/>
                                    <div className='secondary-color-light mt-1 '>{t(`Team.colors`)}</div>
                                    <div className='text-light mt-1'>الاحمر</div>
                            </div>
                        </div>         
                    </div>
                    <div className='row d-flex justify-content-between m-1 mt-4'>
                        {
                        TeamDetails.champions.map(prize=> 
                        <div className='col-12 col-md-4 bg-primary-color p-3 border border-dark' height="200px">
                            <div className='text-light mb-3 p-2'>x{prize.count} {prize.title}</div>
                            <div className='d-flex flex-row justify-content-around align-items-center'>
                                <img src={process.env.REACT_APP_NEW_MEDIA_URL+prize.logo} width="70px" height="70px"/>
                                <div className='secondary-color-light text-center'>{prize.seasons}</div>
                            </div>
                        </div>)
                        }
                    </div>
                </div>
            }
        </div>

    )
}

export default TeamInfo