import React, { useEffect } from 'react'
import { getTopScorers } from '../../redux/topScorers/topScorers.Actions'
import { useTranslation } from "react-i18next"
import { connect } from 'react-redux'
import { useHistory } from "react-router-dom";
import { PieChart } from 'react-minimal-pie-chart';
import ErrorMsg from '../ErrorPage/ErrorMsg';
import LoadingSpinnner from '../loadingSpinner/loadingSpinner';

const TopScorers =({ getTopScorers, topScorers , isFetchingTopScorers , TopScorersFailed , currentLanguageCode ,sport_id , season_id , tournament_id, team_id}) =>{


    useEffect(()=> 
    getTopScorers(sport_id , season_id , tournament_id, team_id ,currentLanguageCode)
    ,[sport_id , season_id , tournament_id ,team_id, currentLanguageCode])

    const { t } = useTranslation()
    const history = useHistory(); 
    const handleRowClick = (id) => {
        history.push(`/player/${id}`);
      }   



    const goalsData=(Arr)=>{
        const newData=Arr[0]
        console.log(newData.played)
        return [{value:newData.goals,color:'#9EC41B'}]
    }
    const ratioData=(Arr)=>{
        const newData=Arr[0]
        return [{value:newData.ratio,color:'#9EC41B'}]
    }

    return (
        <div className='bg-primary-color secondary-color-light fs-xs  mb-2' onClick={()=> handleRowClick(topScorers[0].id)} style={{cursor:"pointer"}}>
            <div className="p-2 fs-md">{t('General.topScorer')}</div>
            {
            TopScorersFailed !== null?
                <ErrorMsg msg={t('Error.GeneralError')} p={5} fs={'md'} />
                :
                isFetchingTopScorers? 
                <LoadingSpinnner p={4} fs={"sm"}/>
                :
                topScorers.length === 0 ?
                <ErrorMsg msg={t('Error.GeneralError')} p={5} fs={'md'} />
                :
            <div className='p-2'>        
            <div className='my-2 d-flex flex-column align-items-center'>
                <img src={process.env.REACT_APP_NEW_MEDIA_URL+topScorers[0].image} style={{width:'50px',borderRadius:'50%'}}/>
                <div className='mt-2'>{topScorers[0].name}</div>
            </div>
            <div className='d-flex justify-content-center text-center'>
                <div className='d-flex flex-column align-items-center  mx-4'>
                    <div className='mb-1'>{t('StatsTable.Played')}</div>
                    <div style={{border:'3.71px solid #9EC41B',borderRadius:'50%',height:'39px',width:'39px'}} className='pt-2'> {topScorers[0].played} </div>
                </div>
                <div style={{width:'100px',overflow:'hidden',position:'relative'} }>
                    <div  className='mb-1'>{t('StatsTable.Goals')}</div>
                    <div style={{position:'absolute',top:'47.5%',right:'47%'}}>{topScorers[0].played}</div>
                    <PieChart data={goalsData(topScorers)} lineWidth={16} radius={19} viewBoxSize={[100,50]} center={[50,10]} totalValue={topScorers[0].played} background={'#A4A4A4'}/>
                </div>
                <div style={{width:'100px',overflow:'hidden',position:'relative'} }>
                    <div  className='mb-1'>{t('General.ratio')}</div>
                    <div style={{position:'absolute',top:'47.5%',right:'40%'}} className='fs-xs'>{topScorers[0].ratio}%</div>
                    <PieChart data={ratioData(topScorers)}  lineWidth={16} radius={19} viewBoxSize={[100,50]} center={[50,10]}  totalValue={100} background={'#A4A4A4'}/>
                </div>
            </div>
            </div>
            }
        </div>
    )
}

const mapStateToProps = state => ({
    topScorers:state.topScorers.TopScorers,
    isFetchingTopScorers:state.topScorers.isFetchingTopScorers,
    TopScorersFailed:state.topScorers.TopScorersFailed,
    currentLanguageCode:state.language.currentLanguageCode,
})

const mapDispatchToProps = dispatch =>({
    getTopScorers:(sport_id , season_id , tournament_id , team_id , lang)=> dispatch(getTopScorers(sport_id , season_id , tournament_id, team_id , lang))
})

export default connect(mapStateToProps,mapDispatchToProps)(TopScorers)