import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const matchesCalendarLoader = () =>{
    return (
        <SkeletonTheme color="#323232" highlightColor="#ecebeb">
        <div className='d-flex p-4 bg-secondary-color'>    
            <div className='row text-light mx-auto '>
                <div className='col-3 '>
                    <Skeleton height={50} width={50} className="rounded-circle" />
                </div>
                <div className='col-6'>
                    <Skeleton height={50} width={150} />
                </div>
                <div className='col-3'>
                    <Skeleton height={50} width={50} className="rounded-circle" />
                </div>   
            </div>
            <div className='row text-light mx-4 d-none d-md-flex'>
                <div className='col-3 '>
                    <Skeleton height={50} width={50} className="rounded-circle" />
                </div>
                <div className='col-6'>
                    <Skeleton height={50} width={150} />
                </div>
                <div className='col-3'>
                    <Skeleton height={50} width={50} className="rounded-circle" />
                </div>   
            </div>
            <div className='row text-light d-none d-md-flex'>
                <div className='col-3 '>
                    <Skeleton height={50} width={50} className="rounded-circle" />
                </div>
                <div className='col-6'>
                    <Skeleton height={50} width={150} />
                </div>
                <div className='col-3'>
                    <Skeleton height={50} width={50} className="rounded-circle" />
                </div>   
            </div>
        </div>
        </SkeletonTheme>
    )
}

export default matchesCalendarLoader

