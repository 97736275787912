import React from 'react'
import { Row,Col, NavItem } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { getName } from '../../globalFn'
import './matchCard.css'

const MatchCard =({el}) => {

    const getTime = (el) =>{
        if(el.date === null ){
            return <div>وقت غير محدد</div>
        }
        else {
            return  el.date.split(' ')[1].split(':').slice(0,2).join(':')
        }
    }
    return (
        <Link to={`/match/${el.id}`}>
        <Col className="secondary-color-light border-bottom border-secondary bg-primary-color py-3 px-1" >
        <Row className='ms-0'>
            <Col   xs={4} md={3} lg={3} className=" my-auto d-flex justify-content-start align-items-center ">
                <div className='match-carousel-rounded-white mx-2'>  
                <img src={`${process.env.REACT_APP_NEW_MEDIA_URL}${el.team1.logo}`} width="30px"/>
                </div>   
                <div className="text-nowrap fs-sm ">{getName(el.team1.title,12)}</div>
            </Col>
            <Col  xs={4}md={6} lg={6} className=" d-flex flex-column align-items-center">
        
            <div className="bg-active-color px-4 px-md-5 py-1 py-md-2 text-nowrap text-light fs-xxs"> { getTime(el) }</div>
            <div className='row d-flex flex-row justify-content-center'>
                <div className='col-1'>{el.team1.score}</div>
                <div className='col-3'/>
                <div className='col-1'>{el.team2.score}</div>
            </div>
            <div className='fs-xxs text-nowrap'>{el.tournament.title}</div>
            </Col>
            <Col  xs={4} md={3} lg={3} className="my-auto d-flex justify-content-end align-items-center">
                <div className=" text-nowrap fs-sm">{getName(el.team2.title,12)}</div>
                <div className='match-carousel-rounded-white mx-2'>
                <img src={`${process.env.REACT_APP_NEW_MEDIA_URL}${el.team2.logo}`} width="30px" />
                </div>
            </Col>
        </Row>
    </Col>
    </Link>
    )
}

export default MatchCard