import React, { useEffect } from "react";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TeamsCarouselLoading from './TeamsCarouselLoading';
import { Container } from "react-bootstrap";
import { connect } from 'react-redux';
import "./ClubsComponent.css";
import { getAllTeams } from "../../redux/AllTeams/AllTeams.Actions";
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button
      onClick={onClick}
      style={{
        ...style,
        fontSize: "20px",
        display: "block",
        background: "var(--primary-color)",
        border: "unset",
        position: "absolute",
        top: -5,
        height: "120%",
        right: 0,
        zIndex: "15",
        width: " 22px",
        opacity: "1",
        color: "var(--tertiary-color)",
      }}
    >
      <i className="fa fa-chevron-right" aria-hidden="true"></i>
    </button>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button
      onClick={onClick}
      style={{
        ...style,
        fontSize: "20px",
        display: "block",
        background: "var(--primary-color)",
        border: "unset",
        position: "absolute",
        top: -5,
        height: "120%",
        left: 0,
        zIndex: "15",
        width: " 22px",
        opacity: "1",
        color: "var(--tertiary-color)",
      }}
    >
      <i className="fa fa-chevron-left" aria-hidden="true"></i>
    </button>
  );
}

function TeamsCarousel({ AllTeams ,  isLoadingAllTeam , activeTeamTab , getAllTeams}) {

  useEffect(() => {
    getAllTeams()
  },[])

  const settings = {
    infinite: false,
    speed: 900,
    slidesToShow: 24,
    slidesToScroll: 12,
    rtl: false,
    accessibility: true,
    nextArrow:"",
    prevArrow:"",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 15,
          slidesToScroll: 12,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 10,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 8,
          slidesToScroll: 4,
        },
      },
    ],
  };
  return (
    <Container fluid className="px-0">
      {
        isLoadingAllTeam ? 
        <TeamsCarouselLoading/>
        :
        <div className="slick-carousel">
        <Slider {...settings} arrows={false}>
          {AllTeams.map((team) => (
            <div key={team.id}>
              <Link to={`/team/${team.id}/${activeTeamTab}`}>
                <div>
                  <img
                    className="mt-1"
                    src={process.env.REACT_APP_NEW_MEDIA_URL + team.logo}
                    style={{ width: "50px" }}
                  />
                </div>
              </Link>
            </div>
          ))}
        </Slider>
      </div>
      }
    </Container>
  );
}

const mapStateToProps = state => ({
  AllTeams: state.AllTeams.AllTeams,
  isLoadingAllTeam: state.AllTeams.isFetchingAllTeams,
  activeTeamTab:state.teamDetails.activeTab
})

const mapDispatchToProps = (dispatch) => ({
  getAllTeams: () => dispatch(getAllTeams()),
});

export default connect(mapStateToProps,mapDispatchToProps)(TeamsCarousel);