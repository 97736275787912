import React from "react";
import comnt from "../../assets/events/comment.svg";
import gol from "../../assets/events/gol.svg";
import cr1 from "../../assets/events/cr1.svg";
import cr2 from "../../assets/events/cr2.svg";
import ar from "../../assets/events/var.jpg";
import sla from "../../assets/events/sla.jpg";
import lost from "../../assets/events/lost.jfif";
import vl from "../../assets/events/vl.png";

function MinBymin() {
  return (
    <div className="container bg-primary-color secondary-color-light">
      <div className="m-3 p-3">
        <div>
          <small>12:30</small>
          <img src={gol} className="m-1"></img>
        </div>
        <img></img>
        <div>
          <div>بداية المباراه</div>
          <div className="text-light">
            <img src={comnt} className="m-3"></img>
            'تبدا مبارة ليفربول مع تشلسي في تصفيلت دوري ابطال اوربا'
          </div>
        </div>
      </div>
      <div className="m-3 p-3">
        <div>
          <small>ق5</small>
          <img></img>
        </div>
        <img></img>
        <div>
          <div>هجمة خطيرة</div>
          <img src={lost} height={300}></img>
          <div className="text-light">
            <img src={comnt} className="m-3"></img>
            'صلاح يخترق مبكرا ويهدد مرمى باريس سان جيرمان'
          </div>
        </div>
      </div>
      <div className="m-3 p-3">
        <div>
          <small>ق21</small>
          <img></img>
        </div>
        <img></img>
        <div>
          <div>
            VAR check <img src={vl} height={30}></img>
          </div>
          <img src={ar} height={300}></img>
          <div className="text-light">
            <img src={comnt} className="m-3"></img>
            'الحكم يوقف المباراة لاشتباه في حالة طرد بعد تدخل قوي من سيلفا على
            فيرمينو'
          </div>
        </div>
      </div>
      <div className="m-3 p-3">
        <div>
          <small>ق22</small>
          <img></img>
        </div>
        <img></img>
        <div>
          <div>
            انذار <img src={cr1}></img>
          </div>
          <div className="text-light">
            <img src={comnt} className="m-3"></img>
            'الحكم يقرر استئناف اللعب ويكتفي ببطاقة صفراء ضد سيلفا'
          </div>
        </div>
      </div>
      <div className="m-3 p-3">
        <div>
          <small>ق30</small>
          <img></img>
        </div>
        <img></img>
        <div>
          <div>
            جوووووول <img src={gol}></img>
          </div>
          <img src={sla} height={300}></img>
          <div className="text-light">
            <img src={comnt} className="m-3"></img>
            'هدف رائع من صلاح في شباك دوناروما بعد مراوغة خط الدفاع وأسكن الكرة
            بيساره في الشباك'
          </div>
        </div>
      </div>
    </div>
  );
}

export default MinBymin;
