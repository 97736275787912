import React, { useEffect } from 'react'
import { getTopScorers } from '../../redux/topScorers/topScorers.Actions'
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next"
import { connect } from 'react-redux'
import ErrorMsg from '../ErrorPage/ErrorMsg';
import LoadingSpinnner from '../loadingSpinner/loadingSpinner';

const TopScorers =({ getTopScorers, topScorers , isFetchingTopScorers , TopScorersFailed , currentLanguageCode ,sport_id , season_id , tournament_id , team_id}) =>{

    const history = useHistory();

    const handleRowClick = (id) => {
      history.push(`/player/${id}`);
    }  

    const { t } = useTranslation() 


    useEffect(()=> 
    getTopScorers(sport_id , season_id , tournament_id, team_id,currentLanguageCode)
    ,[sport_id , season_id , tournament_id , team_id, currentLanguageCode])

    return (
        <div className='bg-primary-color secondary-color-light fs-xs mt-2'>
            <div className='d-flex justify-content-between fs-md p-2'>
              <div>{t('General.topScorers')}</div>
            </div>
            <table class="table-borderless bg-primary-color table-dark secondary-color-light">
            <thead>
                <tr className='text-center'>
                <th scope="col"></th>
                <th scope="col">{t(`StatsTable.Played`)}</th>
                <th scope="col">{t(`StatsTable.Goals`)}</th>
                </tr>
            </thead>
            <tbody>
                {
                    TopScorersFailed !== null?
                    <tr>
                        <td colspan={3} className='p-0'>
                            <ErrorMsg msg={t('Error.GeneralError')} p={5} fs={'md'} />
                        </td>
                    </tr>
                    :
                    isFetchingTopScorers ? 
                    <tr>
                       <td colspan={7} className='p-0'>
                       <LoadingSpinnner p={4} fs={"sm"}/>
                       </td>
                    </tr>
                    :
                    topScorers.length === 0 ?
                    <tr>
                        <td colspan={3} className='p-0'>
                            <ErrorMsg msg={t('Error.GeneralError')} p={5} fs={'md'} />
                        </td>
                    </tr>
                    :
                    topScorers.map(
                        (player,i) =>   i <= 4 ?        
                        <tr key={player.id} onClick={()=> handleRowClick(player.id)} style={{cursor:"pointer"}} className='table-clickable-row'>
                        <th scope="row"><img src={process.env.REACT_APP_NEW_MEDIA_URL+player.image} width='30px' height="30px" className='mx-2' style={{borderRadius:'50%'}}/><span>{player.name}</span></th>
                        <td className='text-center'>{player.played}</td>
                        <td className='text-center'>{player.goals}</td>
                        </tr>
                        :
                        ''
                        )
                }

            </tbody>
            </table>
        </div>
    )
}

const mapStateToProps = state => ({
    topScorers:state.topScorers.TopScorers,
    isFetchingTopScorers:state.topScorers.isFetchingTopScorers,
    TopScorersFailed:state.topScorers.TopScorersFailed,
    currentLanguageCode:state.language.currentLanguageCode,
})

const mapDispatchToProps = dispatch =>({
    getTopScorers:(sport_id , season_id , tournament_id , team_id , lang)=> dispatch(getTopScorers(sport_id , season_id , tournament_id , team_id, lang))
})

export default connect(mapStateToProps,mapDispatchToProps)(TopScorers)