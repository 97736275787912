import { useState } from "react";
import { useTranslation } from "react-i18next";
import ComparisonPopUp from './ComparisonPopUp'




const Comparison =()=>{


    const { t } = useTranslation()
    const [show,setShow] = useState(false)

    return (
    <div className='bg-primary-color secondary-color-light fs-xs p-3  text-center'  style={{height:'300px'}}>
        <h5>{t(`Comparison.Comparison`)}</h5>
        <div className='mt-4'>
          <strong>
          {t(`Comparison.Description`)}
        </strong>
        </div>
        <button className='btn bg-active-color etisalat-border text-light px-4 mt-3' onClick={()=> setShow(true)}>{t(`Comparison.Compare`)}</button>
        <ComparisonPopUp show={show} setShow={setShow}/>
    </div>
    )
}

export default Comparison 