import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useParams } from "react-router";
import { Container } from "reactstrap";
import { connect } from "react-redux";
import { getTournaments } from "../../redux/Tournaments/Tournaments.Actions";
import Select from "react-select";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getName } from "../../globalFn";
import { convertFullDate } from "../../globalFn";
import allCompetitionsImg from "../../assets/allCompetions.png";
import { useTranslation } from "react-i18next";
import "./Competition.css";
import { useEffect } from "react";
import CompetitionLoader from "./CompetitionLoader";
import ErrorMsg from "../../components/ErrorPage/ErrorMsg";
import ErrorPage from "../../components/ErrorPage/ErrorPage";
import dorAdd from "../../assets/adds/doritous add.jpg";

// --bk-color-2

function TournamentsPage({
  tournaments,
  getTournaments,
  isFetchingTournaments,
  TournamentsFailed,
  currentLanguageCode,
}) {
  const id = useParams().id;

  useEffect(() => {
    //the parameters is as follows getTournaments(sport_id,season_id,currentLang)
    getTournaments(1, null, currentLanguageCode);
  }, [currentLanguageCode]);

  const { REACT_APP_NEW_MEDIA_URL } = process.env;

  const { t } = useTranslation();

  return (
    <div>
      <h3 className="all-competions-header text-center p-4 text-light">
        <strong>{t("mainNavBar.Competition")}</strong>
        <span className="competitions-overlay"></span>
      </h3>

      <Container>
        {TournamentsFailed ? (
          <ErrorPage error={t(`Error.TournamentsError`)} />
        ) : isFetchingTournaments ? (
          <CompetitionLoader />
        ) : tournaments.length === 0 ? (
          <ErrorMsg p={5} fs={"lg"} msg={t(`Error.TournamentsError`)} />
        ) : (
          <div className="row all-competitions mr-auto ml-auto justify-content-between">
            {tournaments.map((el) => (
              <Link
                to={`tournament/${el.id}`}
                className="text-light competition-card d-flex flex-column col-5 col-md-2 border-radius-10 bg-primary-color align-items-center justify-content-between mt-3 mx-2 py-3"
                key={el.id}
              >
                <div className="img-rounded-circle text-center">
                  <img
                    src={REACT_APP_NEW_MEDIA_URL + el.logo}
                    className="mt-3"
                  />
                </div>
                <div className="mt-2 text-center fs-xs">{el.title}</div>
              </Link>
            ))}
          </div>
        )}
      </Container>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isFetchingTournaments: state.tournaments.isFetchingTournaments,
  tournaments: state.tournaments.Tournaments,
  TournamentsFailed: state.tournaments.TournamentsFailed,
  currentLanguageCode: state.language.currentLanguageCode,
});
const mapDispatchToProps = (dispatch) => ({
  getTournaments: (sport_id, season_id, currentLanguageCode) =>
    dispatch(getTournaments(sport_id, season_id, currentLanguageCode)),
});
export default connect(mapStateToProps, mapDispatchToProps)(TournamentsPage);
