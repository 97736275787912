import Pusher from "pusher-js";
import React, { useEffect, useState } from "react";
import { Container, Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ErrorPage from "../../components/ErrorPage/ErrorPage";
import MatchEvents from "../../components/MatchEvents/MatchEvents";
import MatchHeader from "../../components/MatchHeader/MatchHeader";
import MatchLineup from "../../components/MatchLineup/MatchLineup.component";
import MatchStatistics from "../../components/MatchStatistics/MatchStatistics";
import {
  DeleteMatchLiveEventSuccess,
  getMatchLiveGoalEventSuccess,
  getMatchLiveRoundEventSuccess,
  getMatchLiveStartEventSuccess,
  getMatchOtherEventSuccess,
} from "../../redux/Match/Actions.Match";
import "./MatchPage.css";
import MinBymin from "./minBymin";

const LiveMatchPage = ({
  match,
  getMatchLiveStartEventSuccess,
  getMatchLiveRoundEventSuccess,
  getMatchOtherEventSuccess,
  getMatchLiveGoalEventSuccess,
  DeleteMatchLiveEventSuccess,
  matchStatusFailure,
}) => {
  const [key, setKey] = useState("lineup");

  const eventsWithRound = [1, 2, 3, 4, 5, 6, 7, 8, 10, 11];
  const eventsWithGoal = [20, 21, 22, 23, 24, 25];

  useEffect(() => {
    const key = "53ef86890b8f6921a455";
    const cluster = "mt1";
    const pusher = new Pusher(key, {
      cluster: cluster,
    });
    const channel = pusher.subscribe(`match_events.${match.params.id}`);

    channel.bind("MatchEventCreated", function (data) {
      if (data.data.event_id === 1) {
        getMatchLiveStartEventSuccess();
        getMatchLiveRoundEventSuccess(data.data);
      } else if (eventsWithRound.includes(data.data.event_id)) {
        getMatchLiveRoundEventSuccess(data.data);
      } else if (eventsWithGoal.includes(data.data.event_id)) {
        getMatchLiveGoalEventSuccess(data.data);
      } else {
        getMatchOtherEventSuccess(data.data);
      }
    });
    channel.bind("MatchEventDeleted", function (data) {
      DeleteMatchLiveEventSuccess(data.data);
    });
  }, []);

  return matchStatusFailure != null ? (
    <ErrorPage />
  ) : (
    <div>
      <div className="match-page font-light-ar">
        <MatchHeader matchId={match.params.id} />

        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          variant="tabs"
          className="d-flex justify-content-center"
        >
          <Tab eventKey="lineup" title="التشكيل">
            <Container>
              <MatchLineup matchId={match.params.id} />
            </Container>
          </Tab>
          <Tab eventKey="live" title="مباشر">
            <MatchEvents matchId={match.params.id} />
          </Tab>
          <Tab eventKey="min by min" title="دقيقة بـ دقيقة">
            <MinBymin />
          </Tab>
          <Tab eventKey="stats" title="الاحصائيات">
            <MatchStatistics matchId={match.params.id} />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getMatchLiveRoundEventSuccess: (event) =>
    dispatch(getMatchLiveRoundEventSuccess(event)),
  getMatchLiveGoalEventSuccess: (event) =>
    dispatch(getMatchLiveGoalEventSuccess(event)),
  DeleteMatchLiveEventSuccess: (event) =>
    dispatch(DeleteMatchLiveEventSuccess(event)),
  getMatchLiveStartEventSuccess: (event) =>
    dispatch(getMatchLiveStartEventSuccess(event)),
  getMatchOtherEventSuccess: (event) =>
    dispatch(getMatchOtherEventSuccess(event)),
});

const mapStateToProps = (state) => ({
  matchStatusFailure: state.matchInfo.matchStatusFailure,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LiveMatchPage)
);
