import SeasonsTypes from "./Seasons.Types";



const INITIAL_STATE = {
    Seasons:[''],
    activeSeason:{id:null},
    isFetchingSeasons:true,
    SeasonsFailed:null
   };
   
   const Seasons = (state = INITIAL_STATE, action) => {
     switch (action.type) {
      case SeasonsTypes.GET_SEASONS_START:
        return {
          ...state,
          isFetchingSeasons:true
        }
      case SeasonsTypes.GET_SEASONS_SUCCESS:
        return {
          ...state,
          Seasons:action.payload.data,
          activeSeason:action.payload.lastSeason,
          isFetchingSeasons:false,
          SeasonsFailed:null
        }
      case SeasonsTypes.SET_ACTIVE_SEASON:
            return {
                ...state,
                activeSeason:action.payload,
                isFetchingSeasons:false,
                SeasonsFailed:null
            }
      case SeasonsTypes.GET_SEASONS_FAILED:
        return {
          ...state,
          SeasonsFailed:action.payload,
          isFetchingSeasons:false
        }
       default:
         return state;
     }
   };
 
   export default Seasons