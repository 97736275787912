import getMediaListTypes from "./MediaList.Types";
import axios from "axios";

const { REACT_APP_NEW_BASE_URL, REACT_APP_NEW_GET_NEWS } = process.env;

const getMedisListStart = (media_type,topStory,latest,popular) => ({
  type: getMediaListTypes.GET_MEDIA_LIST_START,
  media_type,
  topStory,
  latest,
  popular
});

const getMedisListSuccess = (info, meta , type , topStory , latest , popular) => ({
  type: getMediaListTypes.GET_MEDIA_LIST_SUCCESS,
  payload: info,
  meta,
  media_type: type,
  topStory,
  latest,
  popular
});

const addMedisListSuccess = (info, meta , type , topStory , latest , popular) => ({
  type: getMediaListTypes.ADD_MEDIA_LIST_SUCCESS,
  payload: info,
  meta,
  media_type: type,
  topStory,
  latest,
  popular
});



const getMedisListFailed = (error,type, topStory , latest , popular) => ({
  type: getMediaListTypes.GET_MEDIA_LIST_FAILED,
  payload:error,
  media_type:type,
  topStory ,
  latest ,
  popular
});


const addMedisListStart = (media_type,topStory,latest,popular) => ({
  type: getMediaListTypes.ADD_MEDIA_LIST_START,
  media_type,
  topStory,
  latest,
  popular
});

const getMedisList =
  (sport_id , season_id , items_count , Media_type, tournament_id, media_id, team_id , player_id , topStory , latest , popular , lang) => (dispatch) => {
    dispatch(getMedisListStart(Media_type , topStory , latest , popular));
    axios({
      method: "get",
      url: `${REACT_APP_NEW_BASE_URL}${REACT_APP_NEW_GET_NEWS}`,
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": `${lang}`,
      },
      params: {
        sport_id,
        season_id,
        items_count,
        type: Media_type,
        tournament_id: tournament_id,
        media_id: media_id,
        team_id: team_id,
        player_id,
        topStory:topStory,
        latest:latest ,
        popular:popular,

      },
    })
      .then((resp) => {
        if (resp.data.status === 200) {
          // dispatch(type=== 1 ? getMedisListSuccess(resp.data.data));
          dispatch(getMedisListSuccess(resp.data.data, resp.data.meta , Media_type ,  topStory , latest , popular));
        } else {
          dispatch(getMedisListFailed(resp.data.message , Media_type ,topStory , latest , popular));
        }
      })
      .catch((error) => dispatch(getMedisListFailed(error , Media_type , topStory , latest , popular)));
 };



  const addMedisList =
  (sport_id , season_id , items_count , Media_type, tournament_id, media_id, team_id , player_id , topStory , latest , popular , page , lang) => (dispatch) => {
    dispatch(addMedisListStart(Media_type , topStory , latest , popular));
    axios({
      method: "get",
      url: `${REACT_APP_NEW_BASE_URL}${REACT_APP_NEW_GET_NEWS}`,
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": `${lang}`,
      },
      params: {
        sport_id,
        season_id,
        items_count,
        type: Media_type,
        tournament_id: tournament_id,
        media_id: media_id,
        team_id: team_id,
        player_id,
        topStory:topStory,
        latest:latest ,
        popular:popular,
        page

      },
    })
      .then((resp) => {
        if (resp.data.status === 200) {
          // dispatch(type=== 1 ? getMedisListSuccess(resp.data.data));
          dispatch(addMedisListSuccess(resp.data.data, resp.data.meta , Media_type ,  topStory , latest , popular));
        } else {
          dispatch(getMedisListFailed(resp.data.message , Media_type ,topStory , latest , popular));
        }
      })
      .catch((error) => dispatch(getMedisListFailed(error , Media_type , topStory , latest , popular)));
  };
export { getMedisList , addMedisList };
