import React,{ useEffect } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from "react-i18next"
import { getPlayerSeasonStatistics } from '../../redux/PlayerSeasonStatistics/PlayerSeasonStatistics.Actions'
import { setCurrentTeam,setCurrentTournament } from '../../redux/PlayerDetails/PlayerDetails.Actions'
import LoadingSpinnner from '../loadingSpinner/loadingSpinner';
import { Dropdown,Row } from 'react-bootstrap'
import ErrorMsg from '../ErrorPage/ErrorMsg'

const PlayerStatsTable =({PlayerSeasonStatistics, getPlayerSeasonStatistics,  isFetchingPlayerSeasonStatistics , PlayerSeasonStatisticsFailed  , currentTeam , teams , currentTeamActiveTournament , currentTeamTournaments ,  setCurrentTeam , setCurrentTournament , player_id ,  currentLanguageCode})=> {
    const { t } = useTranslation()
    const statisticsKeys = {
        played:t(`StatsTable.Played`),
        win: t(`StatsTable.Wins`),
        lose: t(`StatsTable.Losses`),
        draw: t(`StatsTable.Draw`),
        goals_in: t(`StatsTable.GoalsAgainst`),
        goals_out: t(`StatsTable.GoalsFor`),
        goals_penalty: t(`StatsTable.GoalsPenalty`),
        clean_sheet: t(`StatsTable.CleanSheet`),
        yellow_cards: t(`StatsTable.YellowCards`),
        red_cards: t(`StatsTable.RedCards`),
        difference: t(`StatsTable.GoalsDifference`),
        point: t(`StatsTable.Points`),
        passes: t(`StatsTable.passes`),
        lost_balls: t(`StatsTable.LostBalls`),
        attempts: t(`StatsTable.Attempts`),
        chances: t(`StatsTable.Chances`),
        dribbles: t(`StatsTable.Dribbles`),
        fouls_in: t(`StatsTable.FoulsIn`),
        fouls_out: t(`StatsTable.FoulsOut`),
        ball_win: t(`StatsTable.BallWin`),
        ball_lose: t(`StatsTable.BallLose`),
        appearance:t(`Player.Appearance`),
        missed_penalty:t(`Player.MissedPenalty`),
        played_minutes:t(`Player.MinutesPlayed`),
        assisst:t(`Player.Assists`),
        reversed_goals:t(`Player.ReversedGoals`),
        goals:t(`Player.Goals`)
    }
    
    useEffect(()=>  
    getPlayerSeasonStatistics(1,currentTeamActiveTournament.id,currentTeam.id,player_id, currentLanguageCode)

    ,[1,currentTeamActiveTournament,currentTeam,player_id, currentLanguageCode])


    
    return (
        PlayerSeasonStatisticsFailed !==null ?
        <div className='mt-3'>
           <ErrorMsg p={5} fs={'md'} msg={t(`Error.StatisticsError`)}/>
        </div>
        :
        isFetchingPlayerSeasonStatistics ?
        <div>
            <Row className='mt-3 d-flex justify-content-start'>
                <Dropdown className='col-1'>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                        ...Loading
                    </Dropdown.Toggle>
                </Dropdown>
                <Dropdown className='mx-4 col-1'>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                        ...Loading
                    </Dropdown.Toggle>
                </Dropdown>
            </Row>
            <div className='mt-3'>
                <div className='bg-primary-color p-3'>{t(`StatsTable.Stats`)}</div>
                <LoadingSpinnner p={5} fs={'md'}/>
            </div>
        </div>
        :
    <div className='mt-3'>
      <Row className='d-flex  justify-content-start'>
            <Dropdown className='col-1'>
                <Dropdown.Toggle variant="secondary" id={`dropdown-button-drop-down`} drop='down'>
                        {currentTeam.name}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {
                        teams.map(team => <div ><Dropdown.Item className='text-light' onClick={()=> setCurrentTeam(team)}>{team.name}</Dropdown.Item></div>)
                    }
                </Dropdown.Menu>
            </Dropdown>
            <Dropdown className='mx-5 mx-md-2 col'>
                        <Dropdown.Toggle variant="secondary" id={`dropdown-button`} drop='down'>
                                {currentTeamActiveTournament.title}
                        </Dropdown.Toggle>
        
                        <Dropdown.Menu>
                            {
                                 currentTeamTournaments.map(tour => <div key={tour.id}><Dropdown.Item className='text-light' onClick={()=> setCurrentTournament(tour)} >{tour.title}</Dropdown.Item></div>)
                            }
                        </Dropdown.Menu>
            </Dropdown>
      </Row>
              <table class="table-borderless bg-primary-color table-striped table-dark secondary-color-light mt-3">
              <thead>
                  <tr>
                      <th style={{padding:'20px'}}>{t(`StatsTable.Stats`)}</th>
                      {
                          Object.keys(PlayerSeasonStatistics).map(season => <th scope="col"  style={{padding:'20px'}} className='text-center'>{season}</th>)
                      }
                  </tr>
              </thead>
              <tbody>
                  {
                 Object.keys(PlayerSeasonStatistics[Object.keys(PlayerSeasonStatistics)[0]]).map(el => 
                    <tr className='fs-sm'>
                        <td>{statisticsKeys[el]}</td>
                        {
                           Object.keys(PlayerSeasonStatistics).map(key=> 
                            <td className='text-center'>{PlayerSeasonStatistics[key][el]}</td>)
                        }
                    </tr>)
                  }
              </tbody>
              </table>
    </div>  
    )
}

const mapStateToProps = state =>({
    isFetchingPlayerSeasonStatistics:state.playerSeasonStatistics.isFetchingPlayerSeasonStatistics,
    PlayerSeasonStatistics:state.playerSeasonStatistics. PlayerSeasonStatistics,
    PlayerSeasonStatisticsFailed:state.playerSeasonStatistics.PlayerSeasonStatisticsFailed,
    currentTeam: state.playerDetails.currentTeam,
    teams:state.playerDetails.teams,
    currentTeamActiveTournament:state.playerDetails.currentTeamActiveTournament,
    currentTeamTournaments:state.playerDetails.currentTeamTournaments,
    currentLanguageCode:state.language.currentLanguageCode,
})

const mapDispatchToProps = dispatch => ({
    getPlayerSeasonStatistics:(sport_id,tournament_id, team_id ,player_id,lang)=> dispatch(getPlayerSeasonStatistics(sport_id,tournament_id, team_id ,player_id,lang)),
    setCurrentTeam:(info)=> dispatch(setCurrentTeam(info)),
    setCurrentTournament:(info)=> dispatch(setCurrentTournament(info))

})


export default connect(mapStateToProps,mapDispatchToProps)(PlayerStatsTable)

