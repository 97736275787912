import { act } from "react-dom/test-utils";
import MediaListTypes from "./MediaList.Types";

const INITIAL_STATE = {
  TopStories: [],
  isFetchingTopStories:true,
  TopStoriesFailed:null,
  LatestNews:[],
  addingLatestNews:false,
  LatestNews_CurrentPage:1,
  LatestNews_LastPage:null,
  isFetchingLatestNews:true,
  LatestNewsFailed:null,
  MostPopularNews:[],
  isFetchingMostPopularNews:true,
  MostPopularNewsFailed:null,
  AllNews:[],
  addingAllNews:false,
  AllNews_CurrentPage:1,
  AllNews_LastPage:null,
  isFetchingAllNews:true,
  AllNewsFailed:null,
  Featured: [],
  isFetchingFeatured:true,
  FeaturedFailed:null,
  LatestVids:[],
  addingLatestVids:false,
  LatestVids_CurrentPage:1,
  LatestVids_LastPage:null,
  isFetchingLatestVids:true,
  LatestVidsFailed:null,
  MostPopularVids:[],
  isFetchingMostPopularVids:true,
  MostPopularVidsFailed:null,
  AllVids:[],
  addingAllVids:false,
  AllVids_CurrentPage:1,
  AllVids_LastPage:null,
  isFetchingAllVids:true,
  AllVidsFailed:null,
};

const MediaList = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MediaListTypes.GET_MEDIA_LIST_START:
      if(action.media_type === 1){
        if(action.topStory){
          return {
            ...state,
            isFetchingTopStories:true,
            TopStoriesFailed:null
          }
        }
        else if(action.latest){
          return {
            ...state,
            isFetchingLatestNews:true,
            LatestNewsFailed:null
          }
        }
        else if(action.popular){
          return {
            ...state,
            isFetchingMostPopularNews:true,
            MostPopularNewsFailed:null
          }
        }
        else {
          return {
           ...state,
           isFetchingAllNews:true,
           AllNewsFailed:null
          }
        }  
      }
      if(action.media_type === 2){
        if(action.topStory){
          return {
            ...state,
            isFetchingFeatured:true,
            FeaturedFailed:null
          }
        }
        else if(action.latest){
          return {
            ...state,
            isFetchingLatestVids:true,
            LatestVidsFailed:null
          }
        }
        else if(action.popular){
          return {
            ...state,
            isFetchingMostPopularVids:true,
            MostPopularVidsFailed:null
          }
        }
        else {
          return {
           ...state,
           isFetchingAllVids:true,
           AllVidsFailed:null
          }
        }  
      }
    // this is the params in order  (Media_type, tournament_id, media_id, team_id , topStory , latest , popular , lang)
    case MediaListTypes.GET_MEDIA_LIST_SUCCESS:
      if(action.media_type === 1){
        if(action.topStory){
          return {
            ...state,
            TopStories:action.payload,
            isFetchingTopStories:false,
            TopStoriesFailed:null,
          }
        }
        else if(action.latest){
          return {
            ...state,
            LatestNews:action.payload,
            LatestNews_CurrentPage:action.meta.current_page,
            LatestNews_LastPage:action.meta.last_page,
            isFetchingLatestNews:false,
            LatestNewsFailed:null
          }
        }
        else if(action.popular){
          return {
            ...state,
            MostPopularNews:action.payload,
            isFetchingMostPopularNews:false,
            MostPopularNewsFailed:null
          }
        }
        else {
          return {
           ...state,
           AllNews:action.payload,
           AllNews_CurrentPage:action.meta.current_page,
           AllNews_LastPage:action.meta.last_page,
           isFetchingAllNews:false,
           AllNewsFailed:null
          }
        }  
      }
      if(action.media_type === 2){
        if(action.topStory){
          return {
            ...state,
            Featured:action.payload,
            isFetchingFeatured:false,
            FeaturedFailed:null
          }
        }
        else if(action.latest){
          return {
            ...state,
            LatestVids:action.payload,
            LatestVids_CurrentPage:action.meta.current_page,
            LatestVids_LastPage:action.meta.last_page,
            isFetchingLatestVids:false,
            LatestVidsFailed:null
          }
        }
        else if(action.popular){
          return {
            ...state,
            MostPopularVids:action.payload,
            isFetchingMostPopularVids:false,
            MostPopularVidsFailed:null
          }
        }
        else {
          return {
           ...state,
           AllVids:action.payload,
           AllVids_CurrentPage:action.meta.current_page,
           AllVids_LastPage:action.meta.last_page,
           isFetchingAllVids:false,
           AllVidsFailed:null
          }
        }  
      }
    case MediaListTypes.GET_MEDIA_LIST_FAILED:
      if(action.media_type === 1){
        if(action.topStory){
          return {
            ...state,
            isFetchingTopStories:false,
            TopStoriesFailed:action.payload,
          }
        }
        else if(action.latest){
          return {
            ...state,
            isFetchingLatestNews:false,
            LatestNewsFailed:action.payload
          }
        }
        else if(action.popular){
          return {
            ...state,
            isFetchingMostPopularNews:false,
            MostPopularNewsFailed:action.payload
          }
        }
        else {
          return {
           ...state,
           isFetchingAllNews:false,
           AllNewsFailed:action.payload
          }
        }  
      }
      if(action.media_type === 2){
        if(action.topStory){
          return {
            ...state,
            isFetchingFeatured:false,
            FeaturedFailed:action.payload
          }
        }
        else if(action.latest){
          return {
            ...state,
            isFetchingLatestVids:false,
            LatestVidsFailed:action.payload
          }
        }
        else if(action.popular){
          return {
            ...state,
            isFetchingMostPopularVids:false,
            MostPopularVidsFailed:action.payload
          }
        }
        else {
          return {
           ...state,
           isFetchingAllVids:false,
           AllVidsFailed:action.payload
          }
        }  
      }
    case MediaListTypes.ADD_MEDIA_LIST_START:
        if(action.media_type === 1){
          if(action.topStory){
          }
          else if(action.latest){
            return {
              ...state,
              addingLatestNews:true,
            }
          }
          else if(action.popular){
          }
          else {
            return {
             ...state,
             addingAllNews:true,
            }
          }  
        }
        if(action.media_type === 2){
          if(action.topStory){
          }
          else if(action.latest){
            return {
              ...state,
              addingLatestVids:true,
            }
          }
          else if(action.popular){
          }
          else {
            return {
             ...state,
             addingAllVids:true,
            }
          }  
        }
      case MediaListTypes.ADD_MEDIA_LIST_SUCCESS:
        if(action.media_type === 1){
          if(action.topStory){
          }
          else if(action.latest){
            return {
              ...state,
              LatestNews:[...state.LatestNews,...action.payload],
              addingLatestNews:false,
              LatestNews_CurrentPage:action.meta.current_page,
              LatestNews_LastPage:action.meta.last_page,
              isFetchingLatestNews:false,
              LatestNewsFailed:null
            }
          }
          else if(action.popular){
          }
          else {
            return {
             ...state,
             AllNews:[...state.AllNews,...action.payload],
             addingAllNews:false,
             AllNews_CurrentPage:action.meta.current_page,
             AllNews_LastPage:action.meta.last_page,
             isFetchingAllNews:false,
             AllNewsFailed:null
            }
          }  
        }
        if(action.media_type === 2){
          if(action.topStory){
          }
          else if(action.latest){
            return {
              ...state,
              LatestVids:[...state.LatestVids,...action.payload],
              addingLatestVids:false,
              LatestVids_CurrentPage:action.meta.current_page,
              LatestVids_LastPage:action.meta.last_page,
              isFetchingLatestVids:false,
              LatestVidsFailed:null
            }
          }
          else if(action.popular){
          }
          else {
            return {
             ...state,
             AllVids:[...state.AllVids,...action.payload],
             addingAllVids:false,
             AllVids_CurrentPage:action.meta.current_page,
             AllVids_LastPage:action.meta.last_page,
             isFetchingAllVids:false,
             AllVidsFailed:null
            }
          }  
        }
    default:
      return state;
  }
};

export default MediaList;
