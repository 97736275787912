
import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap/dist/js/bootstrap.js';
import 'flag-icon-css/css/flag-icon.min.css';
import "font-awesome/css/font-awesome.min.css";
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import "normalize.css";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { initReactI18next } from "react-i18next";
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from "./App";
import './assets/css/colors.css';
import './assets/css/fonts.css';
import FallBack from "./components/fallBack/FallBack";
import "./index.css";
import store from './redux/store';

i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['en', 'ar', 'fr'],
    fallbackLng: 'ar',
    debug: false,
    // Options for language detector
    detection: {
      order: ['path', 'cookie', 'htmlTag'],
      caches: ['cookie'],
    },
    // react: { useSuspense: false },
    backend: {
      loadPath: '/assets/locales/{{lng}}/translation.json',
    },
  })


ReactDOM.render(
  <Provider store={store}> 
  <Suspense fallback={<FallBack />}>
   <BrowserRouter>
      <App /> 
   </BrowserRouter>
  </Suspense> 
  </Provider>,
  document.getElementById('root')
);

