import React,{ useEffect } from 'react'
import { BarChart, Bar ,Cell, ResponsiveContainer} from "recharts";
import { connect } from 'react-redux'
import { getPlayerStatistics } from '../../redux/PlayerStatistics/PlayerStatistics.Actions';
import { useTranslation } from "react-i18next"
import {  Dropdown,Row,Col } from 'react-bootstrap';
import PlayerStatsChart from './PlayerStatsChart';
import PlayerStatsTable from './PlayerStatsTable';
import LoadingSpinnner from '../loadingSpinner/loadingSpinner';
import Comparison from '../Comparison/Comparison';



const PlayerStatistics = ({ PlayerStatistics , isFetchingPlayerStatistics , PlayerStatisticsFailed , sport_id,season_id,tournament_id, team_id, player_id, currentLanguageCode,getPlayerStatistics 
    ,  PlayerDetailsFailed , isFetchingPlayerDetails , currentTeam , teams , currentTeamActiveTournament , currentTeamTournaments}) => {


    const { t } = useTranslation()

    return (
        <div className='container secondary-color-light mt-2'>
        <Row>
            <Col xs={12} md={8} className=' p-0'>
                <PlayerStatsChart sport_id={sport_id} season_id={season_id} tournament_id={tournament_id} player_id={player_id}/>
            </Col>
            <Col xs={12} md={4}>
                <Comparison/>
            </Col>

        </Row>
            {
                isFetchingPlayerDetails ?
                <div>
                    <Row className='mt-3 d-flex justify-content-start'>
                        <Dropdown className='col-1'>
                            <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                ...Loading
                            </Dropdown.Toggle>
                        </Dropdown>
                        <Dropdown className='mx-4 col-1'>
                            <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                ...Loading
                            </Dropdown.Toggle>
                        </Dropdown>
                    </Row>
                    <div className='mt-3'>
                        <div className='bg-primary-color p-3'>{t(`StatsTable.Stats`)}</div>
                        <LoadingSpinnner p={5} fs={'md'}/>
                    </div>
                </div>
                :
                <PlayerStatsTable player_id={player_id}/>
            }
        </div>
    )
}

const mapStateToProps = state =>({
    PlayerDetailsFailed:state.playerDetails.PlayerDetailsFailed,
    isFetchingPlayerDetails:state.playerDetails.isFetchingPlayerDetails,
    currentTeam: state.playerDetails.currentTeam,
    teams:state.playerDetails.teams,
    currentTeamActiveTournament:state.playerDetails.currentTeamActiveTournament,
    currentTeamTournaments:state.playerDetails.currentTeamTournaments,
    currentLanguageCode:state.language.currentLanguageCode,
})

const mapDispatchToProps = dispatch =>({
    getPlayerStatistics:(sport_id , season_id , tournament_id , team_id , player_id ,lang)=> dispatch(getPlayerStatistics(sport_id , season_id , tournament_id , team_id , player_id ,lang))
})

export default connect(mapStateToProps,mapDispatchToProps)(PlayerStatistics)

