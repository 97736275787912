
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShirt } from '@fortawesome/free-regular-svg-icons'
import './lineupPlayer.css'
import { useHistory } from "react-router-dom";
import { faTshirt } from '@fortawesome/free-solid-svg-icons';


const LineupPlayer = ({home,away,teamHome,el,secondary}) => {

    const [playerModel,setPlayerModel]=useState(false)

    const history = useHistory();
    const handleRowClick = (id) => {
      history.push(`/player/${id}`);
    };

    const getPlayerInfo=()=>{
        setPlayerModel(true)
    }
    
    const getName = (title) => {
        const titleArr=title.split(" ")
        const newTitle=[]
        let temp=0
        for( let i=0; i<titleArr.length ; i++){
            const newtemp=titleArr[i].length+temp
            if(newtemp < 9){
               newTitle.push(titleArr[i])
               temp=newtemp
            } 
        }
        return `${newTitle.join(' ')}`
    } 


    return (
        <div className={`text-center fs-xxs line-up-player-item`} onClick={() => handleRowClick(el.player_id)} style={{cursor:"pointer"}}>
            
                <img src={`${process.env.REACT_APP_NEW_MEDIA_URL}${el.image}`} width="30px" height="30px" style={{borderRadius:'50%'}}></img>
                <div style={{fontFamily:'sans-serif'}}>{el.shirt_number} . {getName(el.player_name)}</div>
        </div>
    ) 
}

export default LineupPlayer 