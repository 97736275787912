import MatchActionTypes from './Types.Match'
import { arrangebySort, deleteScoreCounter } from './Utils.Match';

const INITIAL_STATE = {
    matchStatus:[''],
    matchRoundInfo:[],
    isFetchingMatchStatus:true,
    matchStatusFailure:null,
    matchEvents:[''],
    isFetchingMatchEvents:true,
    matchEventsFailure:null,
    matchLineUp:[''],
    isFetchingMatchLineUp:true,
    matchLineUpFailure:null,
    matchStatistics:{},
    isFetchingMatchStatistics:true,
    matchStatisticsFailure:null
   };
   
   const matchInfo = (state = INITIAL_STATE, action) => {
     switch (action.type) {
       case MatchActionTypes.GET_MATCH_INFO_START:
         return {
           ...state,
           isFetchingMatchStatus:true
         }
       case  MatchActionTypes.GET_MATCH_INFO_SUCCESS:
         return {
             ...state,
             isFetchingMatchStatus:false,
             matchStatusFailure:null,
             matchStatus:action.payload,
             matchRoundInfo:action.payload[0].matchRoundInfo
         }
         case MatchActionTypes.GET_MATCH_INFO_FAILURE:
         return {
             ...state,
             isFetchingMatchStatus:true,
             matchStatusFailure:action.payload
         }
         case MatchActionTypes.GET_MATCH_EVENTS_START:
          return {
            ...state,
            isFetchingMatchEvents:true
          }
        case  MatchActionTypes.GET_MATCH_EVENTS_SUCCESS:
          return {
              ...state,
              isFetchingMatchEvents:false,
              matchEventsFailure:null,
              matchEvents:action.payload
          }
          case MatchActionTypes.GET_MATCH_EVENTS_FAILURE:
          return {
              ...state,
              isFetchingMatchEvents:false,
              matchEventsFailure:action.payload
          }
          case MatchActionTypes.GET_MATCH_LINEUP_START:
            return {
              ...state,
              isFetchingMatchLineUp:true
            }
          case  MatchActionTypes.GET_MATCH_LINEUP_SUCCESS:
            return {
                ...state,
                isFetchingMatchLineUp:false,
                matchLineUpFailure:null,
                matchLineUp:action.payload
            }
            case MatchActionTypes.GET_MATCH_LINEUP_FAILURE:
            return {
                ...state,
                isFetchingMatchLineUp:false,
                matchLineUpFailure:action.payload
            }
            case MatchActionTypes.GET_MATCH_STATISTICS_START:
              return {
                ...state,
                isFetchingMatchStatistics:true
              }
            case  MatchActionTypes.GET_MATCH_STATISTICS_SUCCESS:
              return {
                  ...state,
                  isFetchingMatchStatistics:false,
                  matchStatisticsFailure:null,
                  matchStatistics:action.payload
              }
              case MatchActionTypes.GET_MATCH_STATISTICS_FAILURE:
              return {
                  ...state,
                  isFetchingMatchStatistics:false,
                  matchStatisticsFailure:action.payload
              }
            case MatchActionTypes.GET_MATCH_LIVE_START_EVENT_SUCCESS:
              return {
                ...state,
                matchStatus:[{...state.matchStatus[0],matchStatus:2}]
              }
            case  MatchActionTypes.GET_MATCH_LIVE_ROUND_EVENT_SUCCESS:
              return {
                  ...state,
                  matchRoundInfo:action.payload,
                  matchEvents:arrangebySort(state.matchEvents,action.payload)
              }
              case  MatchActionTypes.GET_MATCH_LIVE_OTHER_EVENT_SUCCESS:
                return {
                    ...state,
                    matchEvents:arrangebySort(state.matchEvents,action.payload)
                }
            case  MatchActionTypes.GET_MATCH_LIVE_GOAL_EVENT_SUCCESS:
              return {
                  ...state,
                  matchStatus:action.payload.is_home===1 ? 
                  [{...state.matchStatus[0],team1:{...state.matchStatus[0].team1,score:state.matchStatus[0].team1.score+1}}] 
                  :
                  [{...state.matchStatus[0],team2:{...state.matchStatus[0].team2,score:state.matchStatus[0].team2.score+1}}],
                  matchEvents:arrangebySort(state.matchEvents,action.payload)
              }  
              case  MatchActionTypes.DELETE_MATCH_LIVE_EVENT_SUCCESS:
                return {
                    ...state,
                    matchRoundInfo:action.payload.matchRoundInfo,
                    matchEvents:state.matchEvents.filter(el => el.id !== action.payload.id),
                    matchStatus:
                    [{...state.matchStatus[0],team1:{...state.matchStatus[0].team1,score:deleteScoreCounter(state.matchEvents.filter(el => el.id !== action.payload.id),state.matchStatus[0].team1.id)},team2:{...state.matchStatus[0].team2,score:deleteScoreCounter(state.matchEvents.filter(el => el.id != action.payload.id),state.matchStatus[0].team2.id)}}],

                }  
       default:
         return state;
     }
   };
 
   export default matchInfo