import { Link } from "react-router-dom";
import React from "react";
import { Col, Container, Row } from "reactstrap";
import NewsLoading from "./NewsLoader";
import { convertDate } from "../../globalFn";
import { connect } from 'react-redux';
import { getMedisList , addMedisList} from "../../redux/MediaList/MediaList.Action";
import { GoCalendar } from "react-icons/go";
import { useTranslation } from "react-i18next"
import './newsSection.css'
import Moment from "moment";
import { useEffect } from "react";
import NewsCard from "./newsCard";


function AllVids({  isFetchingAllVids , AllVids , AllVids_CurrentPage , AllVids_LastPage,  AllVidsFailed , currentLanguageCode , getMedisList ,  addMedisList , addingAllVids ,type,sport_id , season_id , items_count ,media_type , tournament_id, media_id, team_id , player_id , topStory , latest , popular}) {

  useEffect(()=> {
    // this is the params in order  (sport_id , season_id , items_count , Media_type, tournament_id, media_id, team_id , topStory , latest , popular , lang)
    getMedisList(sport_id , season_id , items_count ,media_type , tournament_id, media_id, team_id , player_id , topStory , latest , popular , currentLanguageCode)
  }
  ,[currentLanguageCode])

  const { t } = useTranslation()

    return (
      AllVidsFailed !== null ?
      <div className='p-5 bg-secondary-color fs-lg text-light text-center'>{t(`Error.NewsError`)}</div>
      :
      isFetchingAllVids ? 
      <NewsLoading /> 
      :
      AllVids.length === 0 ?
      <div className='p-5 bg-secondary-color fs-lg text-light text-center'>{t(`Error.NewsError`)}</div>
      : 
        <Container fluid="md">
          <div className="news-section">
            <div className='row'>
              {
                  AllVids.map(news=> 
                    <div className='col-4 col-md-3 p-1'>
                        <NewsCard news={news} type={2}/>
                    </div>)
              }
            </div>
            <div className='text-center mt-3'>
                <button className='btn bg-active-color text-light fs-sm' onClick={()=> addMedisList(sport_id , season_id , items_count ,media_type , tournament_id, media_id, team_id , player_id , topStory , latest , popular , AllVids_CurrentPage+1 , currentLanguageCode)} disabled={AllVids_CurrentPage >= AllVids_LastPage}>
                  {
                    addingAllVids ? 
                      <div class="spinner-border text-light fs-xs p-0" role="status">
                          <span class="sr-only">Loading...</span>
                      </div> 
                      :
                      t(`News.LoadMore`) 
                  }
                </button>
            </div>
          </div>
        </Container>
    );
}

const mapStateToProps = state =>({
  isFetchingAllVids:state.MediaList.isFetchingAllVids,
  addingAllVids:state.MediaList.addingAllVids,
  AllVids_CurrentPage:state.MediaList.AllVids_CurrentPage,
  AllVids_LastPage:state.MediaList.AllVids_LastPage,
  AllVids:state.MediaList.AllVids,
  AllVidsFailed:state.MediaList.AllVidsFailed,
  currentLanguageCode:state.language.currentLanguageCode
})

const mapDispatchToProps = (dispatch) => ({
  getMedisList: (sport_id , season_id , items_count ,Media_type, tournament_id, media_id, team_id , player_id, topStory , latest , popular , lang) =>
    dispatch(getMedisList(sport_id , season_id , items_count ,Media_type, tournament_id, media_id, team_id , player_id, topStory , latest , popular , lang)),

  addMedisList: (sport_id , season_id , items_count ,Media_type, tournament_id, media_id, team_id , player_id , topStory , latest , popular , page , lang) =>
  dispatch(addMedisList(sport_id , season_id , items_count ,Media_type, tournament_id, media_id, team_id , player_id , topStory , latest , popular , page , lang))
});

export default connect(mapStateToProps,mapDispatchToProps)(AllVids);