import React from "react";
import { useTranslation } from "react-i18next";
import AllVids from "../../components/News/AllVids";
import LatestVids from "../../components/News/LatestVids";
import MostPopularVids from "../../components/News/MostPopularVids";
import Featured from "../../components/News/Featured";
import SportsSectionHeader from "../../components/sportsSectionHeader/sportsSectionHeader.component";
import pepsiAdd from "../../assets/adds/pepsi2 add.jpg";
import IphoneAdd from "../../assets/adds/iphone add.webp";

const Vids = (props) => {
  const { t } = useTranslation();

  return (
    <div className="container home-page">
      <div className="d-flex justify-content-center row">
        <div className="col-12">
          <img src={IphoneAdd} height={200} width={"100%"} />
        </div>
      </div>
      <SportsSectionHeader title={t(`News.Featured`)} />
      <Featured
        sport_id={1}
        season_id={1}
        items_count={4}
        media_type={2}
        tournament_id={null}
        media_id={null}
        team_id={null}
        player_id={null}
        topStory={true}
        latest={null}
        popular={null}
      />
      <div className="d-flex justify-content-center mt-4">
        <img src={pepsiAdd} width={"100%"} height={200} />
      </div>
      <SportsSectionHeader title={t(`News.latestVids`)} />
      <LatestVids
        sport_id={1}
        season_id={1}
        items_count={9}
        media_type={2}
        tournament_id={null}
        media_id={null}
        team_id={null}
        player_id={null}
        topStory={null}
        latest={true}
        popular={null}
      />
      <hr />
      <SportsSectionHeader title={t(`News.MostPopular`)} />
      <MostPopularVids
        sport_id={1}
        season_id={1}
        items_count={12}
        media_type={2}
        tournament_id={null}
        media_id={null}
        team_id={null}
        player_id={null}
        topStory={null}
        latest={null}
        popular={true}
      />
      <hr />
      <SportsSectionHeader title={t(`News.AllVids`)} />
      <AllVids
        sport_id={1}
        season_id={1}
        items_count={12}
        media_type={2}
        tournament_id={null}
        media_id={null}
        team_id={null}
        player_id={null}
        topStory={null}
        latest={null}
        popular={null}
      />
    </div>
  );
};

export default Vids;
