import PlayerStatisticsTypes from "./PlayerStatistics.Types"; 
import axios from 'axios'

const { REACT_APP_NEW_BASE_URL , REACT_APP_NEW_GET_PLAYER_STATISTICS   } = process.env

const getPlayerStatisticsStart = () => ({
    type:PlayerStatisticsTypes.GET_PLAYER_STATISTICS_START
})

const getPlayerStatisticsSuccess = (info) => ({
    type:PlayerStatisticsTypes.GET_PLAYER_STATISTICS_SUCCESS,
    payload:info
})

const getPlayerStatisticsFailed = (info) => ({
    type:PlayerStatisticsTypes.GET_PLAYER_STATISTICS_FAILED,
    payload:info
})


const getPlayerStatistics = (sport_id,season_id,tournament_id, team_id ,player_id,lang) => (
    dispatch => {
        dispatch(getPlayerStatisticsStart())
        axios({
              method: "get",
              url: `${REACT_APP_NEW_BASE_URL}${REACT_APP_NEW_GET_PLAYER_STATISTICS}`,
              params: { sport_id,season_id,tournament_id, team_id,player_id },
              headers: {
                "Content-Type": "application/json",
                "Accept-Language":`${lang}`
              },
            }).then(resp => {   
              if (resp.data.status===200)
              { 
                dispatch(getPlayerStatisticsSuccess(resp.data.data))
              }
              else {
                dispatch(getPlayerStatisticsFailed(resp.data.message))
              }}
              )
              .catch(error => dispatch(getPlayerStatisticsFailed(error)))
     
      }
)

export { getPlayerStatistics }