import TournamentMatchesTypes from "./TournamentMatches.Types";



const INITIAL_STATE = {
    Fixtures:[],
    Results:[],
    isFetchingTournamentMatches:true,
    TournamentMatchesFailed:null
   };
   
   const TournamentMatches = (state = INITIAL_STATE, action) => {
     switch (action.type) {
      case TournamentMatchesTypes.GET_TOURNAMENT_MATCHES_START:
        return {
          ...state,
          isFetchingTournamentMatches:true
        }
      case TournamentMatchesTypes.GET_TOURNAMENT_MATCHES_SUCCESS:
        return {
          ...state,
          Fixtures:action.payload.filter(match => match.matchStatus !== 5 ),
          Results:action.payload.filter(match => match.matchStatus === 5),
          isFetchingTournamentMatches:false,
          TournamentMatchesFailed:null
        }
      case TournamentMatchesTypes.GET_TOURNAMENT_MATCHES_FAILED:
        return {
          ...state,
          TournamentMatchesFailed:action.payload,
          isFetchingTournamentMatches:false
        }
       default:
         return state;
     }
   };
 
   export default TournamentMatches