import { Link } from "react-router-dom";
import React from "react";
import { Col, Container, Row } from "reactstrap";
import NewsLoading from "./NewsLoader";
import { convertDate } from "../../globalFn";
import { connect } from 'react-redux';
import { getMedisList } from "../../redux/MediaList/MediaList.Action";
import { GoCalendar } from "react-icons/go";
import NewsCarousel from "./NewsCarousel";
import './newsSection.css'
import Moment from "moment";
import { useTranslation } from "react-i18next"
import { useEffect } from "react";
import NewsCard from "./newsCard";
import ErrorMsg from "../ErrorPage/ErrorMsg";


function TopStories({  isFetchingTopStories , TopStories ,  TopStoriesFailed , currentLanguageCode , getMedisList ,type, sport_id , season_id , items_count, media_type , tournament_id, media_id, team_id , player_id , topStory , latest , popular}) {

  useEffect(()=> {
    // this is the params in order  (sport_id , season_id , items_count ,Media_type, tournament_id, media_id, team_id , topStory , latest , popular , lang)
    getMedisList(sport_id , season_id , items_count ,media_type , tournament_id, media_id, team_id , player_id , topStory , latest , popular , currentLanguageCode)
  }
  ,[currentLanguageCode])

  const { t } = useTranslation()

    return (
      isFetchingTopStories ||  TopStoriesFailed !== null? 
       <NewsLoading /> 
       :
       TopStories.length === 0 ?
       <ErrorMsg p={5} fs={'lg'} msg={t(`Error.NewsError`)}/>
       : 
        <Container fluid="md">
          <div className="news-section">
            <div className='row'>
              
                <div className='col-md-6 col-12 p-0 mt-2'>
                  <NewsCarousel
                    isLoading={isFetchingTopStories}
                    MediaList={TopStories.slice(0,4)}
                    type={1}
                    currentLanguageCode={currentLanguageCode}
                  />
                </div>
                <div className='col-md-6 col-12'>
                <div className='row'>
                {TopStories.slice(0,4).map((news) => (
                  <div className='col-md-6 col-6 p-1' key={news.id}>
                    <NewsCard news={news} type={1}/>
                  </div>
                ))}
               </div>
               </div>
            </div>
          </div>
        </Container>

    );
}

const mapStateToProps = state =>({
  isFetchingTopStories:state.MediaList.isFetchingTopStories,
  TopStories:state.MediaList.TopStories,
  TopStoriesFailed:state.MediaList.TopStoriesFailed,
  currentLanguageCode:state.language.currentLanguageCode
})

const mapDispatchToProps = (dispatch) => ({
  getMedisList: (sport_id , season_id , items_count ,Media_type, tournament_id, media_id, team_id , player_id , topStory , latest , popular , lang) =>
    dispatch(getMedisList(sport_id , season_id , items_count ,Media_type, tournament_id, media_id, team_id, player_id  , topStory , latest , popular , lang)),
});

export default connect(mapStateToProps,mapDispatchToProps)(TopStories);

