import TournamentsTypes from "./Tournaments.Types"; 
import axios from 'axios'

const { REACT_APP_NEW_BASE_URL , REACT_APP_NEW_GET_TOURNAMENTS } = process.env

const getTournamentsStart = () => ({
    type:TournamentsTypes.GET_TOURNAMENTS_START
})

const getTournamentsSuccess = (info) => ({
    type:TournamentsTypes.GET_TOURNAMENTS_SUCCESS,
    payload:info
})

const getTournamentsFailed = (error) => ({
    type:TournamentsTypes.GET_TOURNAMENTS_FAILED,
    payload:error
})


const getTournaments = (sport_id , season_id , lang) => (
    dispatch => {
        dispatch(getTournamentsStart())
        axios({
              method: "get",
              url: `${REACT_APP_NEW_BASE_URL}${REACT_APP_NEW_GET_TOURNAMENTS}`,
              params: { sport_id , season_id  },
              headers: {
                "Content-Type": "application/json",
                "Accept-Language":`${lang}`
              },
            }).then(resp => {   
              if (resp.data.status===200)
              { 
                dispatch(getTournamentsSuccess(resp.data.data))
              }
              else {
                dispatch(getTournamentsFailed(resp.data.message))
              }}
              )
              .catch(error => dispatch(getTournamentsFailed(error)))
     
      }
)

export { getTournaments }

