import CalendarTypes from "./Calendar.Types";
import axios from "axios";

const { REACT_APP_NEW_BASE_URL, REACT_APP_NEW_GET_CALENDAR } = process.env;

const getCalendarStart = () => ({
  type: CalendarTypes.GET_CALENDAR_START,
});

const getCalendarSuccess = (info) => ({
  type: CalendarTypes.GET_CALENDAR_SUCCESS,
  payload: info,
});

const getCalendarFailed = (error) => ({
  type: CalendarTypes.GET_CALENDAR_FAILED,
  payload:error
});

const getCalendar = (sport_id, season_id, tournament_id, team_id , lang) => (dispatch) => {
  dispatch(getCalendarStart());
  axios({
    method: "get",
    url: `${REACT_APP_NEW_BASE_URL}${REACT_APP_NEW_GET_CALENDAR}`,
    // ${
    //   tournament_id || team_id ? "?" : ""
    // }${tournament_id ? `tournament_id=${tournament_id}` : ""}${
    //   tournament_id && team_id ? "&" : ""
    // }${team_id ? `team_id=${team_id}` : ""}`,
    params: { tournament_id , team_id, season_id ,  sport_id},
    headers: {
      "Content-Type": "application/json",
      "Accept-Language": `${lang}`,
    },
  })
    .then((resp) => {
      if (resp.data.status === 200) {
        dispatch(getCalendarSuccess(resp.data.data));
      } else {
        dispatch(getCalendarFailed(resp.data.message));
      }
    })
    .catch((error) => dispatch(getCalendarFailed(error)));
};

export { getCalendar };
