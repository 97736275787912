import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const PlayerInfoBar = ({ playerDetails }) => {
  const { t } = useTranslation();

  return (
    <div className="bg-primary-color p-2 p-md-4 ">
      {
        <div className="d-flex justify-content-center text-center text-light fs-xxs">
          <div className="px-md-5 px-2 ">
            <div className="secondary-color-light">{t(`Player.Nat`)}</div>
            <div className="d-flex text-center">
              <img
                src={
                  process.env.REACT_APP_NEW_MEDIA_URL +
                  playerDetails.nationality.flag
                }
                width={30}
              />
              <div className="m-2">{playerDetails.nationality.title}</div>
            </div>
          </div>
          <div className="border-right border-secondary px-md-5 px-2">
            <div className="secondary-color-light">
              {t(`Player.DateOfBirth`)}
            </div>
            <div>{playerDetails.birth_date}</div>
          </div>
          <div className="border-right border-secondary px-md-5 px-2">
            <div className="secondary-color-light">{t(`Player.Height`)}</div>
            <div>{playerDetails.height}</div>
          </div>
          <div className="border-right border-secondary px-md-5 px-2">
            <div className="secondary-color-light">{t(`Player.Weight`)}</div>
            <div>{playerDetails.weight}</div>
          </div>
          <div className="border-right border-secondary px-md-5 px-2">
            <div className="secondary-color-light">
              {t(`Player.CurrentLeague`)}
            </div>
            <div>{playerDetails.current_team.tournaments[0].title}</div>
          </div>
        </div>
      }
    </div>
  );
};

export default PlayerInfoBar;
