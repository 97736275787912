import React,{ useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { getTeamSquad } from '../../redux/TeamSquad/TeamSquad.Actions';
import { useTranslation } from "react-i18next"
import { Container, Row } from 'reactstrap';
import ErrorMsg from '../ErrorPage/ErrorMsg';



const TeamSquad = ({  TeamSquad , isFetchingTeamSquad , TeamSquadFailed , sport_id,season_id,tournament_id,team_id, currentLanguageCode, getTeamSquad ,toLocalTab}) => {


    const { t } = useTranslation()
    const history = useHistory(); 
    const handleRowClick = (id) => {
        history.push(`/player/${id}`);
      }  

   useEffect(()=> {
    getTeamSquad(sport_id,season_id,tournament_id,team_id,currentLanguageCode)
   },[sport_id,season_id,tournament_id,team_id,currentLanguageCode])

   const TeamSquadKeys={
    goal_keeper:t(`Team.GoalKeepers`),
    defense:t(`Team.Defence`),
    midfield:t('Team.Midfield'),
    attack:t('Team.Attack'),
    manager:t(`Team.Manager`)
   }

    return (
        TeamSquadFailed !== null ?
        <ErrorMsg p={5} fs={'md'} msg={t(`Error.PlayersError`)}/>
        :
        isFetchingTeamSquad   ?
        <div>...loading</div>
        :
        <Container className='d-flex justify-content-center'>
        <Row>
             {
                 Object.keys(TeamSquad).map((key,i) => 
                 <div className='col-12 col-md-4 mt-3 '>
                 <div className='bg-secondary-color p-3 text-light'>{TeamSquadKeys[key]}</div>
                 <table className="table-borderless bg-primary-color table-striped table-dark secondary-color-light">
                 <thead>
                    <tr className="fs-xxs">
                        <th scope="col">{t(`Player.Player`)}</th>   
                        <th scope="col">{t(`Player.Nat`)}</th>
                        <th scope="col">{t(`Player.Age`)}</th>
                        <th scope="col">{t(`Player.Marketvalue`)}</th>
                    </tr>
                 </thead>
                 <tbody>
                     {
                        Object.values(TeamSquad)[i].length === 0?
                        <tr>
                            <td colspan={10} className='p-0'>
                                <ErrorMsg p={5} fs={'md'} msg={t(`Error.PlayersError`)}/>
                            </td>
                        </tr>
                        :
                        Object.values(TeamSquad)[i].map((player,i)=>  
                             <tr key={player.id} onClick={()=> handleRowClick(player.id)} className='table-clickable-row'>
                                <td scope='row'>
                                    <div className='d-flex justify-content-start'>
                                        <img src={process.env.REACT_APP_NEW_MEDIA_URL+player.image} width='30px'/>
                                        <div>
                                            <div className='fs-xs mx-1'>{player.name}</div>
                                            <div  className='fs-xxs mx-1'>{player.position}</div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                     <img src={process.env.REACT_APP_NEW_MEDIA_URL+player.national_logo} width='30px'/>
                                </td>
                                <td>
                                  {
                                      player.age
                                  }
                                </td>
                                <td>
                                {
                                    player.market_value
                                }
                                </td>
                             </tr>
                             )
                     }
         
                 </tbody>
                 </table>
                 </div>
                 )
             }
        </Row>     
        </Container>
    )
}

const mapStateToProps = state =>({
    TeamSquad:state.teamSquad.TeamSquad,
    isFetchingTeamSquad:state.teamSquad.isFetchingTeamSquad,
    TeamSquadFailed:state.teamSquad.TeamSquadFailed,
    currentLanguageCode:state.language.currentLanguageCode,
})

const mapDispatchToProps = dispatch =>({
    getTeamSquad:(sport_id,season_id,tournament_id,team_id,lang)=> dispatch(getTeamSquad(sport_id,season_id,tournament_id,team_id,lang))
})

export default connect(mapStateToProps,mapDispatchToProps)(TeamSquad)