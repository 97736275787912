import React from 'react'
import etisalteLogo from '../../assets/etisalat-logo.png'
import './fallback.css'


const FallBack = () =>{
    return (
        <div className='row'>
            <div className='col-3'></div>
            <div className='col-6 etisalte-fallback'>
                <img src={etisalteLogo} width="100%"></img>
            </div>
            <div className='col-3'></div>
        </div>
    )
}

export default FallBack