import React, { useState } from 'react'
import { Row,Col,Container } from 'react-bootstrap'

const PlayerPrizes = ({prizes})=>{
    return (
        <Container>
        <div className='row'>
            {
             prizes.map(prize=> 
            <div className='col-12 col-md-3 bg-primary-color p-3' height="200px" width="400px">
                <div className='text-light mb-3'>x{prize.count} {prize.title}</div>
                <div className='d-flex flex-row justify-content-around align-items-center'>
                    <img src={process.env.REACT_APP_NEW_MEDIA_URL+prize.logo} width="70px" height="70px"/>
                    <div className='secondary-color-light'>{prize.seasons}</div>
                </div>
            </div>)
            }
        </div>
        </Container>    
    )
}

export default PlayerPrizes