import React, { useEffect } from 'react'
import { Col, Modal,ProgressBar,Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import { closeH2Hmodal } from '../../redux/Standing/Standing.Actions'
import { useTranslation } from "react-i18next"
import './h2h.css'
import { getName } from '../../globalFn'
const H2HModal=({show ,  closeH2Hmodal , H2HTeams})=>{

    const { t } = useTranslation()
    const statisticsKeys={
        played:t(`StatsTable.Played`),
        win: t(`StatsTable.Wins`),
        lose: t(`StatsTable.Losses`),
        draw: t(`StatsTable.Draw`),
        goals_in: t(`StatsTable.GoalsAgainst`),
        goals_out: t(`StatsTable.GoalsFor`),
        goals_penalty: t(`StatsTable.GoalsPenalty`),
        clean_sheet: t(`StatsTable.CleanSheet`),
        yellow_cards: t(`StatsTable.YellowCards`),
        red_cards: t(`StatsTable.RedCards`),
        difference: t(`StatsTable.GoalsDifference`),
        point: t(`StatsTable.Points`),
        passes: t(`StatsTable.passes`),
        lost_balls: t(`StatsTable.LostBalls`),
        attempts: t(`StatsTable.Attempts`),
        chances: t(`StatsTable.Chances`),
        dribbles: t(`StatsTable.Dribbles`),
        fouls_in: t(`StatsTable.FoulsIn`),
        fouls_out: t(`StatsTable.FoulsOut`),
        ball_win: t(`StatsTable.BallWin`),
        ball_lose: t(`StatsTable.BallLose`),
    }


    const getAverage = (ArrObj,from,to) =>{
        const valuesArr=ArrObj.map((obj,i)=>  Object.values(obj).slice(from,to))
        
        const averagearr=[]
        for(let i=0 ; i< valuesArr.length; i++){
            for(let x=0 ; x< valuesArr[i].length ; x++){
                if(i===0){
                    averagearr.push(valuesArr[i][x])
                }
                else{
                    const item=averagearr[x]+valuesArr[i][x]
                    averagearr.splice(x, 1, item)
                }
                
            }
        }
        console.log(averagearr)
        return averagearr
    }
    
    const showedStats=['goals_out','goals_penalty','attempts','chances','dribbles','ball_win','ball_lose','fouls_out','fouls_in']

    return (
       <Modal show={show} onHide={closeH2Hmodal} closeButton>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
            <Row className='d-flex justify-content-around'>
            {
                H2HTeams.map(team=> 
                <Col className='H2H-modal-team mt-3'>
                    <div className='text-center'>
                    <img src={process.env.REACT_APP_NEW_MEDIA_URL+team.team_logo}/>
                    <div className='fs-md'>{getName(team.team_name,12)}</div>
                    </div>
                    {
                      Object.keys(team).slice(4,25).map((key,i)=> 
                        showedStats.includes(key)?
                        <div className='mt-3'>
                        <div className='d-flex justify-content-between'>   
                            <div className='fs-sm'>{statisticsKeys[key]}</div>
                            <div className='text-end fs-sm'>{getAverage(H2HTeams,4,25)[i]}</div>
                        </div>
                        <ProgressBar
                            now={Object.values(team).slice(4,25)[i]}
                            label={Object.values(team).slice(4,25)[i]}
                            max={getAverage(H2HTeams,4,25)[i]}
                        />
                        </div>
                        :
                        ''
                        )
                    }

                </Col>)
            }
            </Row>
        </Modal.Body>
      </Modal>
    )
}

const mapStateToProps = state =>({
    H2HTeams:state.standing.H2HTeams
})

const mapDispatchToProps = dispatch =>({
      closeH2Hmodal:()=> dispatch(closeH2Hmodal())
})

export default connect(mapStateToProps,mapDispatchToProps)(H2HModal)