import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useParams } from 'react-router';
import { Container } from 'reactstrap';
import { connect } from 'react-redux';
import { getTournamentMatches } from '../../redux/TournamentMatches/TournamentMatches.Actions';
import Select from 'react-select';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getName } from '../../globalFn'
import { convertFullDate } from '../../globalFn'
import allCompetitionsImg from '../../assets/allCompetions.png'
import { useTranslation } from "react-i18next"
import { useEffect,useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './TournamentMatches.css'
import MatchCard from '../MatchCard/MatchCard';
import ErrorMsg from '../ErrorPage/ErrorMsg';
import LoadingSpinnner from '../loadingSpinner/loadingSpinner';



// --bk-color-2



function TournamentMatches({ TournamentMatchesFailed, Fixtures , Results , getTournamentMatches , isFetchingTournamentMatches , currentLanguageCode , sport_id  ,tournament_id , team_id}) {

  //NOTE: the same component is used to get team matches just using team_id insted of tournament_id so recap if tournament_id used it get tournament matches if team_id is used it gets team matches
  const today=new Date()
  const todayDate=[today.getFullYear(),today.getMonth()+1,today.getDate()].join("-");
  useEffect(()=>{
      getTournamentMatches(sport_id  ,  tournament_id , team_id , todayDate , currentLanguageCode)
  },[ sport_id ,tournament_id , team_id ,currentLanguageCode])

  const [value, onChange] = useState(new Date());

  const { REACT_APP_NEW_MEDIA_URL  } = process.env


  const { t } = useTranslation()



function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }


    return (
        <Container>
        <div className='row d-flex mt-5 justify-content-center'>
        <div className="col-10 col-md-4 my-3">
            <Calendar
                onChange={(e)=> getTournamentMatches(sport_id  , tournament_id ,  team_id , convert(e) , currentLanguageCode)}
                value={value}
          
                className='bg-primary-color'
                defaultValue={new Date(
                    "Tue Mar 11 2021 00:00:00 GMT+0100 (Central European Standard Time)"
                  )}
                
            />
       </div>
        
            {
                TournamentMatchesFailed !== null ?
                <div className='col-12 mx-auto col-md-8'> 
                    <div className='mb-2'>
                        <div className='fs-md secondary-color-light mb-2'>{t(`MatchesCalendar.Fixtures`)}</div>
                        <ErrorMsg p={5} fs={'md'} msg={t(`Error.MatchesError`)}/>
                    </div>
                    <div>
                        <div className='fs-md secondary-color-light mb-2'>{t(`MatchesCalendar.Results`)}</div>
                        <ErrorMsg p={5} fs={'md'} msg={t(`Error.MatchesError`)}/>
                    </div>
                </div>
                :
                isFetchingTournamentMatches? 
                <div className='col-12 mx-auto col-md-8'> 
                    <div className='mb-2'>
                        <div className='fs-md secondary-color-light mb-2'>{t(`MatchesCalendar.Fixtures`)}</div>
                        <LoadingSpinnner p={3} fs={'md'}/>
                    </div>
                    <div>
                        <div className='fs-md secondary-color-light mb-2'>{t(`MatchesCalendar.Results`)}</div>
                        <LoadingSpinnner p={3} fs={'md'}/>
                    </div>
                </div>
                :

                <div className='col-12 mx-auto col-md-8'>
                <div className='fs-md secondary-color-light mb-3'>{t(`MatchesCalendar.Fixtures`)}</div>
                {
                Fixtures.length === 0 ? 
                <div className='my-2 secondary-color-light bg-secondary-color p-5 text-light text-center'>{t(`Error.MatchesError`)}</div>
                :

                 Fixtures.map(el => 
                    <MatchCard el={el} key={el.id}/>
                )
                }
                <div className='fs-md secondary-color-light my-3'>{t(`MatchesCalendar.Results`)}</div>
                {
                Results.length === 0 ? 
                <div className='my-2 secondary-color-light bg-secondary-color p-5 text-light text-center'>{t(`Error.MatchesError`)}</div>
                :
                Results.map(el => 
                    <MatchCard el={el} key={el.id}/>
                )
                }
               </div>
               
                     
            }
        </div>    
        </Container>
    )
}

const mapStateToProps = state =>({
    isFetchingTournamentMatches:state.tournamentMatches.isFetchingTournamentMatches,
    Fixtures:state.tournamentMatches.Fixtures,
    Results:state.tournamentMatches.Results,
    TournamentMatchesFailed:state.tournamentMatches.TournamentMatchesFailed,
    currentLanguageCode:state.language.currentLanguageCode
})
const mapDispatchToProps = dispatch =>({
     getTournamentMatches:(sport_id  , tournament_id ,  team_id, start_date ,currentLanguageCode)=> dispatch(getTournamentMatches(sport_id , tournament_id ,  team_id, start_date , currentLanguageCode))
})
export default connect(mapStateToProps,mapDispatchToProps)(TournamentMatches)