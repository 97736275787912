import  GeneralStatisticsTypes from "./GeneralStatistics.Types"; 
import axios from 'axios'

const { REACT_APP_NEW_BASE_URL , REACT_APP_NEW_GET_STANDING   } = process.env

const getGeneralStatisticsStart = () => ({
    type: GeneralStatisticsTypes.GET_GENERAL_STATISTICS_START
})

const getGeneralStatisticsSuccess = (info) => ({
  type: GeneralStatisticsTypes.GET_GENERAL_STATISTICS_SUCCESS,
  payload:info
})

const setCurrentlyDisplayedGeneralStanding = (info) => ({
  type:GeneralStatisticsTypes.SET_CURRENTLY_DISPLAYED_GENERAL_STANDING,
  payload:info
})
const getGeneralStatisticsFailed = (error) => ({
    type: GeneralStatisticsTypes.GET_GENERAL_STATISTICS_FAILED,
    payload:error
})


const getGeneralStatistics = (sport_id , season_id , priority , competition_type , lang) => (
    dispatch => {
        dispatch(getGeneralStatisticsStart())
        axios({
              method: "get",
              url: `${REACT_APP_NEW_BASE_URL}${REACT_APP_NEW_GET_STANDING}`,
              params: {sport_id , season_id ,priority,competition_type},
              headers: {
                "Content-Type": "application/json",
                "Accept-Language":`${lang}`
              },
            }).then(resp => {   
              if (resp.data.status===200)
              { 
                dispatch(getGeneralStatisticsSuccess(resp.data))
              }
              else {
                dispatch(getGeneralStatisticsFailed(resp.data.message))
              }}
              )
              .catch(error => dispatch(getGeneralStatisticsFailed(error)))
     
      }
)

export { getGeneralStatistics , setCurrentlyDisplayedGeneralStanding}