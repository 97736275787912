import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { getFullSearch , changeFullSearchInputVal, changeFullSearchState} from '../../redux/Search/Search.Actions'
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { abortController } from '../../redux/Search/Search.Actions'
import { Form } from 'react-bootstrap'
import './FullSearch.css'

const FullSearch = ({FullSearchQuery, FullSearch,  getFullSearch , abortController , changeFullSearchInputVal , changeFullSearchState , FullSearchsearching , isFetchingFullSearch, FullSearchFailed , currentLanguageCode}) => {

    // useEffect(()=> 
    // searching ? "" : getFullSearch(FullSearchQuery,'ar')
    // ,[searching])
    const { t } = useTranslation();
    const history = useHistory();

    const handleRowClick = (type,id) => {
      history.push(`/${type}/${id}`);
    };

    const creatSearchCat = (arr) => {
        const catObj = {
            players:[],
            teams:[],
            tournaments:[]
        }
        arr.map(el => {
            if(el.type=== 'player'){
                catObj.players.push(el)
            }
            else if(el.type === 'team'){
                catObj.teams.push(el)
            }
            else if(el.type === 'tournament'){
                catObj.tournaments.push(el)
            }
        }
            )
        return catObj
    }

    return (
    <div className="d-flex full-search">
        <input className="form-control me-2 full-search-input " type="search" placeholder={t(`Search.SearchHere`)} aria-label="Search" value={FullSearchQuery}
        onChange={(e)=> {
            changeFullSearchInputVal(e.target.value)
            if(!FullSearchsearching) {
                changeFullSearchState()
                {
                    setTimeout(()=> {
                    changeFullSearchState()
                    getFullSearch(e.target.value,currentLanguageCode)
                    }
                    ,1000)
                }
            }

            
            // getFullSearch(e.target.value,'ar',abortController.signal)
            // abortController.abort()
            
        }}
            />     
        <ul className={`${FullSearchQuery === "" ? 'd-none' : ''} full-search-menu text-light`}>
           {
               isFetchingFullSearch ?
               <div className='text-center mt-5'> 
                    <div class="spinner-border text-secondary fs-xs p-0" role="status">
                        <span class="sr-only">Loading...</span>
                    </div> 
               </div>
               :
               FullSearch.length === 0 ? 
               <li className='text-center p-5'>{t(`Search.NoResults`)}</li>
               :
              Object.keys(creatSearchCat(FullSearch)).map(key=> 
                <li>
                    <h5 className={`px-3 py-2 ${creatSearchCat(FullSearch)[key].length === 0 ? 'd-none' : ''} `}>{t(`Search.${key}`)}</h5>
                    {
                        creatSearchCat(FullSearch)[key].map(el => <li className='dropdown-item d-flex justify-content-between p-2 table-clickable-row text-light' key={el.id*Math.random()} onClick={()=> handleRowClick(el.type,el.id)} style={{cursor:'pointer'}}><div>{el.name}</div><img src={`${process.env.REACT_APP_NEW_MEDIA_URL}${el.image}`} width="30px" height="35px"></img></li>)
                    }
                </li>)
           }
        </ul>
        {/* <i className="fa fa-search fs-lg  text-light"></i> */}
    </div>
    )
}



const mapStateToProps= state => ({
    FullSearchQuery:state.search.FullSearchQuery,
    FullSearch:state.search.FullSearch,
    isFetchingFullSearch:state.search.isFetchingFullSearch,
    FullSearchFailed:state.search.FullSearchFailed,
    abortController:state.search.abortController,
    FullSearchsearching:state.search.FullSearchSearching,
    currentLanguageCode:state.language.currentLanguageCode,
})

const mapDispatchToProps = dispatch =>({
    getFullSearch:(query,lang) => dispatch(getFullSearch(query,lang)),
    changeFullSearchInputVal:(query) => dispatch(changeFullSearchInputVal(query)),
    changeFullSearchState:() => dispatch(changeFullSearchState())
})
export default connect(mapStateToProps,mapDispatchToProps)(FullSearch)