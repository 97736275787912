import AllTeamsTypes from "./AllTeams.Types"; 
import axios from 'axios'

const { REACT_APP_NEW_BASE_URL , REACT_APP_NEW_GET_SIMPLE_TEAMS } = process.env

const getAllTeamsStart = () => ({
    type:AllTeamsTypes.GET_ALL_TEAMS_START
})

const getAllTeamsSuccess = (info) => ({
    type:AllTeamsTypes.GET_ALL_TEAMS_SUCCESS,
    payload:info
})

const getAllTeamsFailed = () => ({
    type:AllTeamsTypes.GET_ALL_TEAMS_FAILED
})


const getAllTeams = () => (
    dispatch => {
        dispatch(getAllTeamsStart())
        axios({
              method: "get",
              url: `${REACT_APP_NEW_BASE_URL}${REACT_APP_NEW_GET_SIMPLE_TEAMS}`,
              params: { team_type:1 },
              headers: {
                "Content-Type": "application/json",
                "Accept-Language":"ar"
              },
            }).then(resp => {   
              if (resp.data.code===200)
              { 
                dispatch(getAllTeamsSuccess(resp.data.data))
              }
              else {
                dispatch(getAllTeamsFailed(resp.data.message))
              }}
              )
              .catch(error => dispatch(getAllTeamsFailed(error)))
     
      }
)

export { getAllTeams }

