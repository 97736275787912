
import React , { useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams } from "react-router-dom";
import { getStanding } from "../../redux/Standing/Standing.Actions";
import { getGeneralStatistics } from '../../redux/GeneralStatistics/GeneralStatistics.Actions'
import { setCurrentlyDisplayedGeneralStanding } from '../../redux/GeneralStatistics/GeneralStatistics.Actions'
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom";
import Language from '../../redux/language/language.Reducer'
import ErrorMsg from '../ErrorPage/ErrorMsg';
import LoadingSpinnner from '../loadingSpinner/loadingSpinner';


const TournamentOverviewStand = ({ Standing ,standingFailed,  isFetchingStanding , currentLanguageCode ,  getStanding , sport_id , season_id ,  tournament_id , team_id,tournamentDetails ,toLocalTab}) => {

 
    
    useEffect(()=> {
        if(team_id) getStanding(sport_id , season_id , tournament_id ,currentLanguageCode)
    },[sport_id , season_id , tournament_id ,currentLanguageCode])

    const history = useHistory();
    
    const handleRowClick = (id) => {
        history.push(`/team/${id}`);
      }  

    const { t } = useTranslation()

    const getTeamStand =(Arr,id)=>{
        const index=Arr.findIndex(el=> el.team_id === Number(id))
        const newIndex=index-2 < 0 ? 0 : index-2
        const newLastIndex=index-2 < 0 ? 4 : index+2
        const newStanding=Arr.map((el,i)=> ({...el,index:i})).slice(newIndex ,newLastIndex)
        return (
            newStanding.map(
                (team,i) =>          
                <tr key={team.id} style={{backgroundColor:`${team.team_id===Number(id) ? 'var(--active-color)':''}`}}>
                <th scope="row">{team.index+1}</th>
                <th>{team.team_name}</th>
                <td>{team.played}</td>
                <td>{team.difference}</td>
                <td>{team.point}</td>
                </tr>
        ))



    }

    return (
    <div className='bg-primary-color secondary-color-light fs-xs'>
        <div className='d-flex justify-content-between fs-md p-2'>
        <div>{t('TournamentDetails.Table')}</div>
        <div  style={{direction:'rtl'}}> 
            <button className={`fa fa-chevron-${currentLanguageCode === 'ar' ? 'left':'right'} btn secondary-color-light`} aria-hidden="true" onClick={()=> toLocalTab('standing')}></button>
        </div>
       </div>
        <table className="table-borderless bg-primary-color table-striped table-dark secondary-color-light">
        <thead>
            <tr>
            <th scope="col">{t(`StatsTable.Position`)}</th>   
            <th scope="col">{t(`StatsTable.Club`)}</th>
            <th scope="col" className="text-center">{t(`StatsTable.Played`)}</th>
            <th scope="col" className="text-center">{t(`StatsTable.Difference`)}</th>
            <th scope="col" className="text-center">{t(`StatsTable.Points`)}</th>
            </tr>
        </thead>
        <tbody>
            {   standingFailed !== null ?
                <tr>
                    <td colspan={7} className='p-0'>
                        <ErrorMsg msg={t('Error.StandingError')} p={4} fs={'md'} />
                    </td>
                </tr>
                :
                isFetchingStanding ?
                <tr>
                <td colspan={7} className='p-0'>
                    <LoadingSpinnner p={4} fs={'sm'}/>
                </td>
               </tr>
                :
                Standing.length === 0 ?
                <tr>
                <td colspan={7} className='p-0'>
                    <ErrorMsg msg={t('Error.StandingError')} p={4} fs={'md'} />
                </td>
               </tr>
                :
                team_id ?
                getTeamStand(Standing,team_id)
                :
                Standing.map(
                    (team,i) =>   i <= 3 ?        
                    <tr key={team.id} onClick={()=> handleRowClick(team.team_id)} className='table-clickable-row text-center'>
                    <td >{i+1}</td>
                    <td scope="row" style={{textAlign:'start'}}>{team.team_name}</td>
                    <td>{team.played}</td>
                    <td>{team.difference}</td>
                    <td>{team.point}</td>
                    </tr>
                    :
                    ''
                    )
            }

        </tbody>
        </table>
    </div>
    )
}

  
  const mapStateToProps = (state) => ({
    isFetchingStanding: state.standing.isFetchingStanding,
    standingFailed:state.standing.StandingFailed,
    Standing: state.standing.Standing,
    currentLanguageCode:state.language.currentLanguageCode,
    tournamentDetails: state.standing.TournamentDetails,
  });

  const mapDispatchToProps = (dispatch) => ({
    getStanding: (sport_id , season_id ,tournament_id ,lang) => dispatch(getStanding(sport_id , season_id  ,tournament_id ,lang)),
  });
  
  

export default connect(mapStateToProps,mapDispatchToProps)(TournamentOverviewStand)
