import TeamDetailsTypes from "./teamDetails.Types"; 
import axios from 'axios'

const { REACT_APP_NEW_BASE_URL , REACT_APP_NEW_GET_TEAMS } = process.env


const getTeamDetailsStart = () => ({
    type:TeamDetailsTypes.GET_TEAM_DETAILS_START
})

const getTeamDetailsSuccess = (info) => ({
    type:TeamDetailsTypes.GET_TEAM_DETAILS_SUCCESS,
    payload:info
})

const getTeamDetailsFailed = (info) => ({
    type:TeamDetailsTypes.GET_TEAM_DETAILS_FAILED,
    payload:info
})

const setActiveTab = (tabName) => ({
  type:TeamDetailsTypes.SET_TEAM_ACTIVE_TAB,
  payload:tabName
})

const getTeamDetails = (sport_id,team_id,lang) => (
    dispatch => {
        dispatch(getTeamDetailsStart())
        axios({
              method: "get",
              url: `${REACT_APP_NEW_BASE_URL}${REACT_APP_NEW_GET_TEAMS}/${team_id}`,
              params:{ sport_id },
              headers: {
                "Content-Type": "application/json",
                "Accept-Language":`${lang}`
              },
            }).then(resp => {   
              if (resp.data.status===200)
              { 
                dispatch(getTeamDetailsSuccess(resp.data.data))
              }
              else {
                dispatch(getTeamDetailsFailed(resp.data.message))
              }}
              )
              .catch(error => dispatch(getTeamDetailsFailed(error)))
     
      }
)

export { getTeamDetails , setActiveTab}
