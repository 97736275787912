import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import AllNews from "../../components/News/AllNews";
import LatestNews from "../../components/News/LatestNews";
import MostPopular from "../../components/News/MostPopular";
import NewsSearch from "../../components/News/NewsSearch";
import TopStories from "../../components/News/TopStories";
import SportsSectionHeader from "../../components/sportsSectionHeader/sportsSectionHeader.component";
import IphoneAdd from "../../assets/adds/iphone add.webp";
import pepsiAdd from "../../assets/adds/pepsi2 add.jpg";

const News = ({ NewsSearchQuery }) => {
  const { t } = useTranslation();

  return (
    <div className="container home-page">
      <div className="d-flex justify-content-center row">
        <div className="col-12">
          <img src={IphoneAdd} height={200} width={"100%"} />
        </div>
      </div>
      <SportsSectionHeader title={t(`News.topStories`)} />
      <TopStories
        sport_id={1}
        season_id={1}
        items_count={4}
        media_type={1}
        tournament_id={null}
        media_id={null}
        team_id={null}
        player_id={null}
        topStory={true}
        latest={null}
        popular={null}
      />

      <div className="d-flex justify-content-center mt-4">
        <img src={pepsiAdd} width={"100%"} height={200} />
      </div>
      <SportsSectionHeader title={t(`News.latestNews`)} />
      <NewsSearch />
      <div className={`${NewsSearchQuery.length > 0 ? "d-none" : ""} my-2`}>
        <LatestNews
          sport_id={1}
          season_id={1}
          items_count={9}
          media_type={1}
          latest={true}
        />
      </div>
      <hr></hr>
      <SportsSectionHeader title={t(`News.MostPopular`)} />
      <MostPopular
        sport_id={1}
        season_id={1}
        items_count={12}
        media_type={1}
        tournament_id={null}
        media_id={null}
        team_id={null}
        player_id={null}
        topStory={null}
        latest={null}
        popular={true}
      />
      <hr />
      <SportsSectionHeader title={t(`News.AllNews`)} />
      <AllNews
        sport_id={1}
        season_id={1}
        items_count={8}
        media_type={1}
        tournament_id={null}
        media_id={null}
        team_id={null}
        player_id={null}
        topStory={null}
        latest={null}
        popular={null}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  NewsSearchQuery: state.newsSearch.NewsSearchQuery,
});

export default connect(mapStateToProps)(News);
