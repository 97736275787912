import { useEffect, useState } from 'react'

import { getName } from '../../globalFn'
import { connect } from 'react-redux'
import { getMatchEvents } from '../../redux/Match/Actions.Match'
import { Row,Col } from 'react-bootstrap'
import { setPusherClient } from 'react-pusher';
import Pusher from 'pusher-js'
import './MatchEvents.css'

const MatchEvents= ({ getMatchEvents, matchEvents, isFetchingMatchEvents, teamScore,  isFetchingMatchStatus , matchId, matchEventsFailure }) =>{


    useEffect(()=> {
        getMatchEvents(matchId)
    },[])

   

//    const events= {
//        matchStart:1,
//        endFirstHalf:2,
//        startSecondHalf:3,
//        endSecondHalf:4,
//        startFirstExtraTime:5,
//        endFirstExtraTime:6,
//        startSecondExtraTime:7,
//        endSecondExtraTime:8,
//        var:9,
//        startPenalties:10,
//        matchEnded:11
//    }

    const eventsWithScore = [1,2,3,4,5,6,7,8,10,11]

    const getEvent = (event) => {
       if(eventsWithScore.includes(event.event_id)){
        return <div className='event-team secondary-color-light fs-xmd'>
                    <div>{event.event_name}</div>
                    {
                        isFetchingMatchStatus? 
                        ''
                        :
                        <div className='text-center'><span>{teamScore.team1.score} </span> - <span>{teamScore.team2.score}</span></div>
                    }
                </div>
       }
       else {
        return  <div className={`justify-content-between secondary-color-light bg-primary-color align-items-center my-2 p-2 ${event.is_home===1 ? 'event-team-first d-flex flex-row-reverse' : 'event-team-second d-flex flex-row'}`}>
                    <div className='fs-xmd'>
                        {event.event_time}'
                    </div>
                    
                    <div className={`text-center ${event.is_home===1 ? 'd-flex flex-row-reverse' : 'd-flex flex-row'}`}>
                        <img src={process.env.REACT_APP_NEW_MEDIA_URL+event.event_image}  className='mx-3' style={{height:"40px", width:"40px"}}/>
                        <div>
                           <div className='mx-2'> {getName(event.playerOne_name)} </div>
                           <div className='mx-2'> {getName(event.playerTwo_name)} </div>
                        </div>
                    </div>
                </div>
       }
    }


    return (
        matchEventsFailure != null || matchEvents.length===0 ? 
        <div className='text-center mt-3'>لايوجد احداث</div> 
        :
        <div className='container bg-primary-color'>
            <Row className='match-events et-shadow p-3 container mx-auto'>
                <Col xs={0} md={3}></Col>
                <Col xs={12} md={6} className=' d-flex flex-column-reverse align-items-center'>
                    <div className="vl"></div>
                     {
                        isFetchingMatchEvents ? <div>...يتم التحميل</div>
                        : 
                        matchEvents.map(event=> getEvent(event))
                     }
                </Col>
                <Col xs={0} md={3}></Col>
            </Row>
        </div> 
    )
}

const mapStateToProps = state => ({
    matchEvents:state.matchInfo.matchEvents,
    isFetchingMatchEvents:state.matchInfo.isFetchingMatchEvents,
    matchEventsFailure:state.matchInfo.matchEventsFailure,
    isFetchingMatchStatus:state.matchInfo.isFetchingMatchStatus,
    teamScore:state.matchInfo.matchStatus[0]
})
const mapDispatchToProps = dispatch => ({
    getMatchEvents: (matchId) => dispatch(getMatchEvents(matchId)),
})
export default connect(mapStateToProps,mapDispatchToProps)(MatchEvents)