import React, { useEffect } from "react";
import { Col, Row, Table, ProgressBar } from "react-bootstrap";
import { useTranslation } from "react-i18next"
import { connect } from 'react-redux';
import { getMatchStatistics } from "../../redux/Match/Actions.Match";


import "./MatchStatistics.css";

function MatchStatistics({  matchId ,  getMatchStatistics , matchStatistics , matchStatisticsFailure , team1Goals , team1Info , possession1,  corners1, offside1,  foults1, yellowCards1, redCards1, shots1,
  team2Goals, team2Info, possession2, offside2,  foults2, yellowCards2, redCards2, shots2, isFetchingMatchStatus , isFetchingMatchStatistics}) {
 
    useEffect(()=> 
    getMatchStatistics(matchId)
    ,[])
  

    const { t } = useTranslation()

  const { REACT_APP_BASE_URL ,REACT_APP_NEW_MEDIA_URL,   } = process.env 

  const statisticsKeys={
    shots: t(`Match.Shots`),
    target_shots: t(`Match.TargetShots`),
    pass: t(`Match.Passes`),
    pass_accuracy: t(`Match.PassAccuracy`),
    fouls: t(`Match.Fouls`),
    offsides: t(`Match.Offside`),
    corners: t(`Match.Corners`),
    red_cards: t(`StatsTable.RedCards`),
    yellow_cards: t(`StatsTable.YellowCards`),
    score: t(`StatsTable.Goals`),
    penalty_score: t(`StatsTable.GoalsPenalty`),
    Possession: t(`Match.Possesion`)
  }


  return (

    matchStatisticsFailure !== null ?
    <div>لا يوجد احصائيات</div> 
    :
    isFetchingMatchStatistics ? 
    <div>...loading</div>
    :
    <div className="container">
        <div className='et-shadow p-4 fs-md'>
        <div className='text-center pb-3'>إحصائيات المباراة</div>
        <Table className='table-borderless bg-primary-color table-striped table-dark secondary-color-light'>
          <tbody>
            <tr className='d-table-row align-items-center justify-content-center'>
              <td className='d-table-cell text-center'>
                  <img src={`${REACT_APP_NEW_MEDIA_URL}${team1Info.logo}`} style={{width:'35px'}}></img>
                  <span className='text-center mx-2'>{team1Info.score}</span>
              </td>
              <td className='text-center pt-3'>الأهداف</td>
              <td className='d-table-cell text-center'>
                  <span className='text-center mx-2'>{team2Info.score}</span>
                  <img src={`${REACT_APP_NEW_MEDIA_URL}${team2Info.logo}`} style={{width:'35px'}}></img>
              </td>
            </tr>
            {
              Object.keys(team1Info.statistics).map((el,i)=> 
                <tr className='align-items-center justify-content-between'>
                <td className='text-center'>
                    {Object.values(team1Info.statistics)[i]}
                </td>
                <td className='text-center pt-3'>{statisticsKeys[el]}</td>
                <td className='text-center'>
                    {Object.values(team2Info.statistics)[i]}
                </td>
              </tr>
              )
            }
 
          </tbody>
        </Table>
        </div>
    </div>
  );
}

const mapStateToProps = state =>({
    isFetchingMatchStatistics:state.matchInfo.isFetchingMatchStatistics,
    team1Info:state.matchInfo.matchStatistics.team1,
    team2Info:state.matchInfo.matchStatistics.team2,
    matchStatisticsFailure:state.matchInfo.matchStatisticsFailure
})

const mapDispatchToProps = dispatch => ({
  getMatchStatistics:(id)=> dispatch( getMatchStatistics(id))
})

export default connect(mapStateToProps,mapDispatchToProps)(MatchStatistics)