import moment from "moment";


export  function convertDate(date) {
    var dateObj = new Date(date);
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();
    
    return year+'-'+month+'-'+day;
} 

export function convertFullDate(date, lang) {
    const daysEn = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      monthsEn = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      monthsAr = [
        "يناير",
        "فبراير",
        "مارس",
        "إبريل",
        "مايو",
        "يونيو",
        "يوليو",
        "أغسطس",
        "سبتمبر",
        "أكتوبر",
        "نوفمبر",
        "ديسمبر",
      ],
      daysAr = [
        "اﻷحد",
        "اﻷثنين",
        "الثلاثاء",
        "اﻷربعاء",
        "الخميس",
        "الجمعة",
        "السبت",
      ],
      day = moment(date).format("DD"),
      year = moment(date).year();
    if (lang === "en") {
      return `${daysEn[moment(date).day()]}, ${day} ${
        monthsEn[moment(date).month()]
      } ${year} `;
    } else if (lang === "ar") {
      return `${daysAr[moment(date).day()]}, ${day} ${
        monthsAr[moment(date).month()]
      } ${year} `;
    }
  }

  export const getName = (title,wordLength=12) => {
    const titleArr=title.split(" ")
    const newTitle=[]
    let temp=0
    for( let i=0; i<titleArr.length ; i++){
        const newtemp=titleArr[i].length+temp
        if(newtemp < wordLength){
           newTitle.push(titleArr[i])
           temp=newtemp
        } 
    }
    return `${newTitle.join(' ')}`
  } 