import MatchesTypes from "./Matches.Types";



const INITIAL_STATE = {
    Matches:[''],
    isFetchingMatches:true,
    MatchesFailed:null
   };
   
   const Matches = (state = INITIAL_STATE, action) => {
     switch (action.type) {
      case MatchesTypes.GET_MATCHES_START:
        return {
          ...state,
          isFetchingMatches:true
        }
      case MatchesTypes.GET_MATCHES_SUCCESS:
        return {
          ...state,
          Matches:action.payload,
          isFetchingMatches:false,
          MatchesFailed:null
        }
      case MatchesTypes.GET_MATCHES_FAILED:
        return {
          ...state,
          MatchesFailed:action.payload,
          isFetchingMatches:false
        }
       default:
         return state;
     }
   };
 
   export default Matches