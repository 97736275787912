import React, { useEffect } from "react";
import { Container, Nav, Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import ErrorPage from "../../components/ErrorPage/ErrorPage";
import LatestNews from "../../components/News/LatestNews";
import LatestVids from "../../components/News/LatestVids";
import TeamInfo from "../../components/TeamInfo/TeamInfo";
import TeamOverview from "../../components/TeamOverview/TeamOverview";
import TeamSquad from "../../components/TeamSquad/TeamSquad";
import TeamStatsDetail from "../../components/TeamStatistics/TeamStatsDetail";
// import Matches from '../Matches/Matches';
import TournamentMatches from "../../components/TournamentMatches/TournamentMatches";
import {
  getTeamDetails,
  setActiveTab,
} from "../../redux/teamDetails/teamDetails.Actions";
import HeaderInfoComponentLoader from "./HeaderInfoComponentLoader";
import "./Team.css";

const Team = ({
  TeamDetails,
  TeamDetailsFailed,
  activeSeason,
  isFetchingTeamDetails,
  getTeamDetails,
  currentLanguageCode,
  setActiveTab,
  activeTab,
}) => {
  const { t } = useTranslation();

  const id = useParams().id;
  const tab = useParams().tab || "overview";

  const history = useHistory();

  const handelTabClick = (tab) => {
    setActiveTab(tab);
    history.push(`/team/${id}/${tab}`);
  };

  let { path, url } = useRouteMatch();

  useEffect(() => {
    getTeamDetails(1, id, currentLanguageCode);
  }, [id, currentLanguageCode]);

  return (
    <div>
      {TeamDetailsFailed !== null ? (
        <ErrorPage error={t(`Error.ClubError`)} />
      ) : isFetchingTeamDetails ? (
        <HeaderInfoComponentLoader />
      ) : (
        <div className="Team-details-header-container">
          <div className="overlay"></div>
          <div
            className="Team-details-header"
            style={{
              backgroundImage: `url(${
                process.env.REACT_APP_NEW_MEDIA_URL + TeamDetails.background
              })`,
            }}
          ></div>
          <div className="Team-details-header-desc text-center">
            <img src={process.env.REACT_APP_NEW_MEDIA_URL + TeamDetails.logo} />
            <div className="text-light text-center mt-1 fs-lg">
              <strong>{TeamDetails.name}</strong>
            </div>
          </div>
          <div
            className="d-flex justify-content-around align-items-center Team-details-header-champions p-3 "
            onClick={() => {
              handelTabClick("info");
            }}
            style={{ cursor: "pointer" }}
          >
            {TeamDetails.champions.map((el) => (
              <div style={{ position: "relative" }} className="mx-2">
                <img src={process.env.REACT_APP_NEW_MEDIA_URL + el.logo} />
                <div className="text-light champions-times text-center">
                  x{el.count}
                </div>
              </div>
            ))}
          </div>
          <div
            className="Team-details-header-market text-light p-3 p-3 text-center"
            onClick={() => {
              handelTabClick("players");
            }}
            style={{ cursor: "pointer" }}
          >
            <span>{TeamDetails.market_value}</span>
          </div>
        </div>
      )}
      <div className="container"></div>
      <Tab.Container
        id="left-tabs-example"
        // defaultActiveKey="Overview"
        defaultActiveKey={activeTab}
        activeKey={tab || activeTab}
        mountOnEnter={true}
        unmountOnExit={true}
      >
        <Nav variant="tabs" className="mb-3 flex-nowrap fs-sm">
          <div className="d-flex justify-content-center mx-auto">
            <Nav.Item>
              <Nav.Link
                eventKey="overview"
                onClick={() => handelTabClick("overview")}
              >
                {t(`TournamentDetails.Overview`)}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="matches"
                onClick={() => handelTabClick("matches")}
              >
                {t(`TournamentDetails.Matches`)}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="players"
                onClick={() => handelTabClick("players")}
              >
                {t(`TournamentDetails.Squad`)}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="news" onClick={() => handelTabClick("news")}>
                {t(`News.latestNews`)}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="videos"
                onClick={() => handelTabClick("videos")}
              >
                {t(`News.latestVids`)}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="standing"
                onClick={() => handelTabClick("standing")}
              >
                {t(`TournamentDetails.Stats`)}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="info" onClick={() => handelTabClick("info")}>
                {t(`Team.Info`)}
              </Nav.Link>
            </Nav.Item>
          </div>
        </Nav>
        {/* <Nav className='container'>
                <SeasonsDropdown />
            </Nav>     */}

        <Tab.Content>
          <Tab.Pane eventKey="overview">
            <TeamOverview
              toLocalTab={handelTabClick}
              season_id={activeSeason.id}
            />
          </Tab.Pane>
          <Tab.Pane eventKey="matches">
            <TournamentMatches sport_id={1} team_id={id} />
            {/* using tournamentMatches component with just team_id to get team matches */}
          </Tab.Pane>
          <Tab.Pane eventKey="players">
            <TeamSquad sport_id={1} team_id={id} />
          </Tab.Pane>
          <Tab.Pane eventKey="news">
            <Container>
              <LatestNews
                sport_id={1}
                season_id={activeSeason.id}
                tournament_id={null}
                team_id={id}
                items_count={16}
                mini={true}
                xs={6}
                md={3}
                media_type={1}
                latest={true}
              />
            </Container>
          </Tab.Pane>
          <Tab.Pane eventKey="videos">
            <Container>
              <LatestVids
                sport_id={1}
                season_id={activeSeason.id}
                tournament_id={null}
                team_id={id}
                items_count={16}
                mini={true}
                xs={6}
                md={3}
                media_type={2}
                latest={true}
              />
            </Container>
          </Tab.Pane>
          <Tab.Pane eventKey="standing">
            <TeamStatsDetail team_id={id} toLocalTab={setActiveTab} />
          </Tab.Pane>
          <Tab.Pane eventKey="info">
            <TeamInfo
              TeamDetails={TeamDetails}
              isFetchingTeamDetails={isFetchingTeamDetails}
              TeamDetailsFailed={TeamDetailsFailed}
            />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getTeamDetails: (sport_id, team_id, currentLanguageCode) =>
    dispatch(getTeamDetails(sport_id, team_id, currentLanguageCode)),
  setActiveTab: (tabName) => dispatch(setActiveTab(tabName)),
});

const mapStateToProps = (state) => ({
  TeamDetailsFailed: state.teamDetails.TeamDetailsFailed,
  TeamDetails: state.teamDetails.TeamDetails,
  isFetchingTeamDetails: state.teamDetails.isFetchingTeamDetails,
  activeTab: state.teamDetails.activeTab,
  activeSeason: state.seasons.activeSeason,
  currentLanguageCode: state.language.currentLanguageCode,
});

export default connect(mapStateToProps, mapDispatchToProps)(Team);
